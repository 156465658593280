import React, { useState, useEffect,useContext } from "react";
import "@fontsource/ibm-plex-sans-arabic";
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/theme.css';
import Cookies from 'universal-cookie';
import OneSignal from "react-onesignal";
import Header from "./components/layout/header";
import Footer from "./components/layout/footer";
import Routing from "./components/routing";
import { RouteContext } from "./contexts/route.context";

const App = () => {
  const cookies = new Cookies();
  let authToken = cookies.get('userTkn');
  const { getMe, me, } = useContext(RouteContext);
  let with_no_header_footer = ['login', 'verification', 'step1', 'step2', 'step3', 
    'login-lessor', 'verification-lessor', 'step1-lessor', 'step2-lessor', 'step3-lessor'];
  let _urlPath = window.location.pathname.replace("/", "");

  
  let userCookie = cookies.get('userTkn');
  let phone = cookies.get('phoneNumber');
  let code = cookies.get('code');
  const current = new Date();
  const nextYear = new Date();
  const [userMe, setUserMe] = useState();

  useEffect(() => {
    checkToken();
    // initialize one signal
    // OneSignal.init({ appId: '0e245802-32dd-4907-8cd8-b3bd26b1e192' });
    OneSignal.init({ appId: '01806e6c-8c04-40c5-a0a6-253e650f8ef7' });
  }, []);

  const checkGetMe = async() => {
    let x = await getMe();
    setUserMe(x);
    return x;
  }

  const checkToken = async () => {
    let x = await checkGetMe();
    if (userCookie && !x){
      cookies.remove('userTkn', { path: '/' });
      cookies.remove('code', { path: '/' });
      cookies.remove('phoneNumber', { path: '/' });
      window.location.reload(false);
    }
  }


  useEffect(()=>{
    if (userCookie && userMe){
      nextYear.setFullYear(current.getFullYear() + 10);
      // cookies.set('code', code, {
      //   path: '/',
      //   expires: nextYear,
      // });
      cookies.set('phoneNumber', phone, {
        path: '/',
        expires: nextYear,
      });
      cookies.set('userTkn', userCookie, {
        path: '/',
        expires: nextYear,
      });
    }

  }, [current])


  return (
    <div>
      {authToken ? (
        userMe ? (
          <>
            {!with_no_header_footer.includes(_urlPath) ? (
              <Header/>
            ) : null}
            

            <div className="content-div">
              <Routing/>
            </div>

            {!with_no_header_footer.includes(_urlPath) ? (
              <Footer/>
            ) : null}
          </>
        ) : (
          <div className="wrap">
            <div className="loading">
              <div className="bounceball"></div>
              <div className="text">NOW LOADING</div>
            </div>
          </div>
        )
      ) : (
        <>
          {!with_no_header_footer.includes(_urlPath) ? (
            <Header/>
          ) : null}
          

          <div className="content-div">
            <Routing/>
          </div>

          {!with_no_header_footer.includes(_urlPath) ? (
            <Footer/>
          ) : null}
        </>
      )}
    </div>
  );
}

export default App;
