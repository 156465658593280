import Cookies from 'universal-cookie';
import { format, parse } from 'date-fns'
import {FormattedMessage} from 'react-intl';
const cookies = new Cookies();

// focus next class once max length is obtain
const handleFocusNext = (e, maxLeng, nextSelector) => {
    if(e.target.value.length === maxLeng) {
      const nextSibling = document.querySelector("#" + nextSelector);
      nextSibling.focus();
    }
  }
  
  // focus prev class once remove/delete current field
  const onKeyUpRemoveOtp = (e, prevSelector) => {
    // KeyCodes:
    // Backspace = 8, Delete = 46
    var key = e.keyCode || e.charCode;
    console.log(key)
  
    if(key == 8 || key == 46 ) {
      const prevSibling = document.querySelector("#" + prevSelector);
      // prevSibling.value = "";
      prevSibling.focus();
    }
  }

  // add mask to a number/string
  function maskCharacter(str, mask, n = 1) {
 
    // Slice the string and replace with
    // mask then add remaining string
    return ('' + str).slice(0, -n)
        .replace(/./g, mask)
        + ('' + str).slice(-n);
  }


  // scroll to top function
  function scrollToTop(top) {
    window.scrollTo({
      top: top, 
      behavior: 'smooth'
    });
  }


  // get dates between 2 dates
  function getDatesInRange(startDate, endDate) {
    const date = new Date(startDate.toString().replace(/-/g, "/"));
    const dateEnd = new Date(endDate.toString().replace(/-/g, "/"));
    let dtFi = format(parse('', '', date), 'yyyy/MM/dd');
    let dtFiSplit = dtFi.split("/");
  
    date.setDate(dtFiSplit[2]);
  
    const dates = [];
  
    while (date <= dateEnd) {
      let startDt = format(parse('', '', date), 'yyyy/MM/dd');
      let startDtSplit = startDt.split("/");
      let finaleEndSplitMonth = startDtSplit[1].split("");
      let finaleEndSplitDate = startDtSplit[2].split("");
      let finaleEndFinalMonth = parseInt(finaleEndSplitMonth[0]) === 0 ? finaleEndSplitMonth[1] : startDtSplit[1];
      let finaleEndFinalDate = parseInt(finaleEndSplitDate[0]) === 0 ? finaleEndSplitDate[1] : startDtSplit[2];
      let finalDt = startDtSplit[0] + "-" + finaleEndFinalMonth + "-" + finaleEndFinalDate;

      // let dtFormat = date.getFullYear()+"-"+parseFloat(date.getMonth() + 1)+"-"+date.getDate();
      dates.push(finalDt);
      date.setDate(parseInt(startDtSplit[2]) + 1);
    }
  
    return dates;
  }

  // get differecnce between 2 dates
  function getDifferenceOnDate(startDate, endDate) {
    // const date1 = DateTime.fromJSDate(endDate)
    // const date2 = DateTime.fromJSDate(startDate)

    // const diff = date1.diff(date2, ["years", "months", "days", "hours"])

    // return diff;

    let msDay = 60*60*24*1000,
        a = new Date(startDate.toString().replace(/-/g, "/")),
        b = new Date(endDate.toString().replace(/-/g, "/"));

    return Math.floor((b - a) / msDay);
  }


  // add number of months from date declared
  function addMonths(date, months) {
    let newDate = new Date(date);
    let d = newDate.getDate();
    newDate.setMonth(newDate.getMonth() + +months);
    if (newDate.getDate() != d) {
      newDate.setDate(0);
    }

    let newDateFormat = newDate.getFullYear()+"-"+parseFloat(newDate.getMonth() + 1)+"-"+newDate.getDate();
    return newDateFormat;
  }


  // translate hourly, daily and monthly
  function translateTerm(term) {
    let language = cookies.get("lang") ? cookies.get("lang") : "en";
    if(language === "en") {
      if(term === "hourly") {
        return "Hourly";
      }
      else if(term === "daily") {
        return "Daily";
      }
      else if(term === "monthly") {
        return "Monthly";
      }
    }
    else {
      if(term === "hourly") {
        return "بالساعة";
      }
      else if(term === "daily") {
        return "يومي";
      }
      else if(term === "monthly") {
        return "شهري";
      }
    }
  }


  // determine AM/PM time
  function determineTime(time) {
    switch(time) {
      case "01:00":
        return (
          <div>
            01:00 {" "}
            <FormattedMessage 
              id="am.text"
              defaultMessage="AM"
              description="AM"
            />
          </div>
        )
      case "02:00":
        return (
          <div>
            02:00 {" "}
            <FormattedMessage 
              id="am.text"
              defaultMessage="AM"
              description="AM"
            />
          </div>
        )
      case "03:00":
        return (
          <div>
            03:00 {" "}
            <FormattedMessage 
              id="am.text"
              defaultMessage="AM"
              description="AM"
            />
          </div>
        )
      case "04:00":
        return (
          <div>
            04:00 {" "}
            <FormattedMessage 
              id="am.text"
              defaultMessage="AM"
              description="AM"
            />
          </div>
        )
      case "05:00":
        return (
          <div>
            05:00 {" "}
            <FormattedMessage 
              id="am.text"
              defaultMessage="AM"
              description="AM"
            />
          </div>
        )
      case "06:00":
        return (
          <div>
            06:00 {" "}
            <FormattedMessage 
              id="am.text"
              defaultMessage="AM"
              description="AM"
            />
          </div>
        )
      case "07:00":
        return (
          <div>
            07:00 {" "}
            <FormattedMessage 
              id="am.text"
              defaultMessage="AM"
              description="AM"
            />
          </div>
        )
      case "08:00":
        return (
          <div>
            08:00 {" "}
            <FormattedMessage 
              id="am.text"
              defaultMessage="AM"
              description="AM"
            />
          </div>
        )
      case "09:00":
        return (
          <div>
            09:00 {" "}
            <FormattedMessage 
              id="am.text"
              defaultMessage="AM"
              description="AM"
            />
          </div>
        )
      case "10:00":
        return (
          <div>
            10:00 {" "}
            <FormattedMessage 
              id="am.text"
              defaultMessage="AM"
              description="AM"
            />
          </div>
        )
      case "11:00":
        return (
          <div>
            11:00 {" "}
            <FormattedMessage 
              id="am.text"
              defaultMessage="AM"
              description="AM"
            />
          </div>
        )
      case "12:00":
        return (
          <div>
            12:00 {" "}
            <FormattedMessage 
              id="pm.text"
              defaultMessage="PM"
              description="PM"
            />
          </div>
        )
      case "13:00":
        return (
          <div>
            01:00 {" "}
            <FormattedMessage 
              id="pm.text"
              defaultMessage="PM"
              description="PM"
            />
          </div>
        )
      case "14:00":
        return (
          <div>
            02:00 {" "}
            <FormattedMessage 
              id="pm.text"
              defaultMessage="PM"
              description="PM"
            />
          </div>
        )
      case "15:00":
        return (
          <div>
            03:00 {" "}
            <FormattedMessage 
              id="pm.text"
              defaultMessage="PM"
              description="PM"
            />
          </div>
        )
      case "16:00":
        return (
          <div>
            04:00 {" "}
            <FormattedMessage 
              id="pm.text"
              defaultMessage="PM"
              description="PM"
            />
          </div>
        )
      case "17:00":
        return (
          <div>
            05:00 {" "}
            <FormattedMessage 
              id="pm.text"
              defaultMessage="PM"
              description="PM"
            />
          </div>
        )
      case "18:00":
        return (
          <div>
            06:00 {" "}
            <FormattedMessage 
              id="pm.text"
              defaultMessage="PM"
              description="PM"
            />
          </div>
        )
      case "19:00":
        return (
          <div>
            07:00 {" "}
            <FormattedMessage 
              id="pm.text"
              defaultMessage="PM"
              description="PM"
            />
          </div>
        )
      case "20:00":
        return (
          <div>
            08:00 {" "}
            <FormattedMessage 
              id="pm.text"
              defaultMessage="PM"
              description="PM"
            />
          </div>
        )
      case "21:00":
        return (
          <div>
            09:00 {" "}
            <FormattedMessage 
              id="pm.text"
              defaultMessage="PM"
              description="PM"
            />
          </div>
        )
      case "22:00":
        return (
          <div>
            10:00 {" "}
            <FormattedMessage 
              id="pm.text"
              defaultMessage="PM"
              description="PM"
            />
          </div>
        )
      case "23:00":
        return (
          <div>
            11:00 {" "}
            <FormattedMessage 
              id="pm.text"
              defaultMessage="PM"
              description="PM"
            />
          </div>
        )
      case "24:00":
        return (
          <div>
            12:00 {" "}
            <FormattedMessage 
              id="am.text"
              defaultMessage="AM"
              description="AM"
            />
          </div>
        )
      case "00:00":
        return (
          <div>
            12:00 {" "}
            <FormattedMessage 
              id="am.text"
              defaultMessage="AM"
              description="AM"
            />
          </div>
        )
      default:
        break;
    }
  }
  
  
  
  export {
    handleFocusNext,
    onKeyUpRemoveOtp,
    maskCharacter,
    scrollToTop,
    getDatesInRange,
    getDifferenceOnDate,
    addMonths,
    translateTerm,
    determineTime,
  }