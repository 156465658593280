import React, { createContext, useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Cookies from 'universal-cookie';
import { useHistory } from "react-router-dom";
import { FirebaseContext } from "./firebase.context";


export const RouteContext = createContext(null);

const RouteProvider = ({ children }) => {
  const { database, auth } = useContext(FirebaseContext);
  const [me, setMe] = useState();
  const [categories, setCategories] = useState();
  const [subCategories, setSubCategories] = useState();
  const [area, setArea] = useState();
  const [city, setCity] = useState();
  const [posts, setPosts] = useState();
  const [postsLoading, setPostsLoading] = useState(false);
  const [userPosts, setUserPosts] = useState();
  const [userPostsLoading, setUserPostsLoading] = useState(false);
  const [allSettings, setAllSettings] = useState();
  const [viewPost, setViewPost] = useState();
  const [postLeaseTermAvailability, setPostLeaseTermAvailability] = useState();
  const [postLeaseTermUnavailability, setPostLeaseTermUnavailability] = useState();
  const [postLeaseTermAvailabilityLoading, setPostLeaseTermAvailabilityLoading] = useState(false);
  const [discount, setDiscount] = useState();
  const [activeBookings, setActiveBookings] = useState();
  const [activeBookingLoading, setActiveBookingLoading] = useState();
  const [historyBookings, setHistoryBookings] = useState();
  const [historyBookingLoading, setHistoryBookingLoading] = useState();
  const [postReviews, setPostReviews] = useState();
  const [postReviewsLoading, setPostReviewsLoading] = useState();
  const [lesseeBookedPostHistory, setLesseeBookedPostHistory] = useState();
  const [lesseeBookedPostHistoryLoading, setLesseeBookedPostHistoryLoading] = useState();
  const [viewUser, setViewUser] = useState();
  const [invoiceActive, setInvoiceActive] = useState();
  const [invoiceActiveLoading, setInvoiceActiveLoading] = useState();
  const [invoiceHistory, setInvoiceHistory] = useState();
  const [invoiceHistoryLoading, setInvoiceHistoryLoading] = useState();
  const [notificationSettings, setNotificationSettings] = useState();
  const [totalEarnings, setTotalEarnings] = useState();
  const [totalEarningsperYear, setTotalEarningsperYear] = useState();
  const [yearChangeLoading, setYearChangeLoading] = useState(false);
  const [userNotifications, setUserNotifications] = useState();
  const [userNotificationsLoading, setUserNotificationsLoading] = useState();
  const [allQuestions, setAllQuestions] = useState();

  // firebase
  const [chatList, setChatList] = useState();
  const [userChatList, setUserChatList] = useState();
  const [chatUserDetails, setChatUserDetails] = useState();


  const cookies = new Cookies();
  let authToken = cookies.get('userTkn');

  const baseURL = `https://admin.renter.net/graphql`;
  const baseURLPayment = `https://admin.renter.net`;
  const apiHeaders = {
    "Content-Type": "application/json",
    "Authorization": "Bearer " + authToken,
  };

  // useEffect(()=>{
  //   getCity();
  // },[])


  // get me
  const getMe = async () => {
    try {
      let result = await axios({
        url: baseURL,
        headers: apiHeaders,
        method: "post",
        data: {
          query: `
            query{
              me{
                address
                agreement_verified_at
                approved_at
                avatar
                swift_code{
                  id
                  bank_name
                  bank_name_ar 
                  bic
                  image
                }
                bank_account_num
                bank_name
                iban_number
                bank_account_name
                created_at
                email
                gender
                id
                is_verified
                language
                latitude
                longitude
                name
                phone
                role {
                  ar
                  en
                  id
                }
                updated_at
                valid_id_picture
                verfied_at
              }
            }
          `
        }
      });

      if(result.data.data) {
        setMe(result.data.data.me);
        return result.data.data.me;
      }
      
    } catch (err) {
      console.log(err);
    }
  }


  // get categories
  const getCategories = async () => {
    try {
      let result = await axios({
        url: baseURL,
        method: "post",
        data: {
          query: `
            query{
              allCategories {
                ar
                en
                id
                image
              }
            }
          `
        }
      });

      if(result.data.data) {
        setCategories(result.data.data.allCategories);
      }
      
    } catch (err) {
      console.log(err);
    }
  }


  // get subcategory
  const getSubCategories = async (catId) => {
    try {
      let result = await axios({
        url: baseURL,
        method: "post",
        data: {
          query: `
            query{
              allSubcategories(category_id: ${catId}) {
                ar
                created_at
                deleted_at
                en
                id
                image
                updated_at
              }
            }
          `
        }
      });

      if(result.data.data) {
        setSubCategories(result.data.data.allSubcategories);
      }
    } catch (err) {
      console.log(err);
    }
  }


  // get area
  const getArea = async (cityId) => {
    try {
      if(cityId) {
        let result = await axios({
          url: baseURL,
          method: "post",
          data: {
            query: `
              query{
                allAreas(city_id: ${cityId}) {
                  ar
                  en
                  id
                }
              }
            `
          }
        });
  
        if(result.data.data) {
          setArea(result.data.data.allAreas);
        }
      }
      else {
        let result = await axios({
          url: baseURL,
          method: "post",
          data: {
            query: `
              query{
                allAreas {
                  ar
                  en
                  id
                }
              }
            `
          }
        });
  
        if(result.data.data) {
          setArea(result.data.data.allAreas);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }


  // get city
  const getCity = async () => {
    try {
      let result = await axios({
        url: baseURL,
        method: "post",
        data: {
          query: `
            query{
              allCities {
                en
                id
                ar
              }
            }
          `
        }
      });

      if(result.data.data) {
        setCity(result.data.data.allCities);
      }
      
    } catch (err) {
      console.log(err);
    }
  }
  


  // get all settings
  const getAllSettings = async () => {
    try {
      let result = await axios({
        url: baseURL,
        method: "post",
        data: {
          query: `
            query{
              allSettings {
                about_us
                about_us_ar
                account_name
                account_no
                admin_commission_rate
                bank_name
                cancellation_commission_rate
                cancellation_days_limit
                contact_us
                contact_us_ar
                feature_post_cost
                general_cancellation_policy_ar
                general_cancellation_policy_en
                provider_commission_rate
                tax
                terms_and_conditions
                terms_and_conditions_ar
                privacy_policy
                privacy_policy_ar
                deposit_rate
                social_link_1
                social_link_2
                social_link_3
                social_link_4
                social_name_1
                social_name_2
                social_name_3
                social_name_4
                contact_no
              }
            }
          `
        }
      });

      if(result.data.data) {
        setAllSettings(result.data.data.allSettings);
      }
      
    } catch (err) {
      console.log(err);
    }
  }
  

  const getAllPost = async (count, page, input) => {
    try{
      if(input === undefined) {
        let result = await axios({
          url: baseURL,
          method: "post",
          data:{
            query: 
            `query{
                filterPost(first: ${count}, page: ${page}){
                  data {
                  quantity
                  distance
                    area {
                      ar
                      en
                      id
                    }
                    category {
                      ar
                      en
                      id
                      image
                    }
                    city {
                      ar
                      en
                      id
                    }
                    created_at
                    daily_post_lease_terms {
                      duration
                      id
                      lease_term
                      overnight
                      price
                      deposit
                      time_in
                      time_out
                      type
                      post_availabilities {
                        date
                        id
                        post_lease_term_id
                        type
                        post_lease_term {
                          duration
                          id
                          lease_term
                          overnight
                          price
                          time_in
                          time_out
                          type
                        }
                      }
                    }
                    description
                    hourly_post_lease_terms {
                      duration
                      id
                      lease_term
                      overnight
                      price
                      deposit
                      time_in
                      time_out
                      type
                      post_availabilities {
                        date
                        id
                        post_lease_term_id
                        type
                        post_lease_term {
                          duration
                          id
                          lease_term
                          overnight
                          price
                          time_in
                          time_out
                          type
                        }
                      }
                    }
                    id
                    latitude
                    location
                    longitude
                    post_images {
                      id
                      image
                      post_id
                    }
                    monthly_post_lease_terms {
                      duration
                      id
                      lease_term
                      overnight
                      price
                      deposit
                      time_in
                      time_out
                      type
                      post_availabilities {
                        date
                        id
                        post_lease_term_id
                        type
                        post_lease_term {
                          duration
                          id
                          lease_term
                          overnight
                          price
                          time_in
                          time_out
                          type
                        }
                      }
                    }
                    post_lease_terms {
                      duration
                      id
                      lease_term
                      overnight
                      end_date
                      price
                      deposit
                      time_in
                      time_out
                      type
                      post_availabilities {
                        date
                        id
                        post_lease_term_id
                        type
                        post_lease_term {
                          duration
                          id
                          lease_term
                          overnight
                          price
                          time_in
                          time_out
                          type
                        }
                      }
                    }
                    review_average
                    review_count
                    title
                    updated_at
                    user {
                      address
                      avatar
                      email
                      gender
                      id
                      language
                      longitude
                      latitude
                      name
                      phone
                      role {
                        ar
                        en
                        id
                      }
                    }
                  }
                  paginatorInfo {
                    count
                    currentPage
                    firstItem
                    hasMorePages
                    lastItem
                    lastPage
                    perPage
                    total
                  }
                }
              }
            `
          }
        })
        if(result.data.data) {
          setPostsLoading(false);
          setPosts(result.data.data.filterPost);
          return result.data.data.filterPost;
        }
      }
      else {
        let result = await axios({
          url: baseURL,
          method: "post",
          data: {
            query: `
              query{
                filterPost(first: ${count}, page: ${page}, input: ${input}){
                  data {
                  quantity
                  distance
                    area {
                      ar
                      en
                      id
                    }
                    category {
                      ar
                      en
                      id
                      image
                    }
                    city {
                      ar
                      en
                      id
                    }
                    created_at
                    daily_post_lease_terms {
                      duration
                      id
                      lease_term
                      overnight
                      price
                      deposit
                      time_in
                      time_out
                      type
                      post_availabilities {
                        date
                        id
                        post_lease_term_id
                        type
                        post_lease_term {
                          duration
                          id
                          lease_term
                          overnight
                          price
                          time_in
                          time_out
                          type
                        }
                      }
                    }
                    description
                    hourly_post_lease_terms {
                      duration
                      id
                      lease_term
                      overnight
                      price
                      deposit
                      time_in
                      time_out
                      type
                      post_availabilities {
                        date
                        id
                        post_lease_term_id
                        type
                        post_lease_term {
                          duration
                          id
                          lease_term
                          overnight
                          price
                          time_in
                          time_out
                          type
                        }
                      }
                    }
                    id
                    latitude
                    location
                    longitude
                    post_images {
                      id
                      image
                      post_id
                    }
                    monthly_post_lease_terms {
                      duration
                      id
                      lease_term
                      overnight
                      price
                      deposit
                      time_in
                      time_out
                      type
                      post_availabilities {
                        date
                        id
                        post_lease_term_id
                        type
                        post_lease_term {
                          duration
                          id
                          lease_term
                          overnight
                          price
                          time_in
                          time_out
                          type
                        }
                      }
                    }
                    post_lease_terms {
                      duration
                      id
                      lease_term
                      overnight
                      price
                      deposit
                      time_in
                      time_out
                      type
                      post_availabilities {
                        date
                        id
                        post_lease_term_id
                        type
                        post_lease_term {
                          duration
                          id
                          lease_term
                          overnight
                          price
                          time_in
                          time_out
                          type
                        }
                      }
                    }
                    review_average
                    review_count
                    title
                    updated_at
                    user {
                      address
                      avatar
                      email
                      gender
                      id
                      language
                      longitude
                      latitude
                      name
                      phone
                      role {
                        ar
                        en
                        id
                      }
                    }
                  }
                  paginatorInfo {
                    count
                    currentPage
                    firstItem
                    hasMorePages
                    lastItem
                    lastPage
                    perPage
                    total
                  }
                }
              }
            `
          }
        });
        if(result.data.data) {
          setPostsLoading(false);
          setPosts(result.data.data.filterPost);
          return result.data.data.filterPost;
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  // list of leases/post on Leases page
  const getPosts = async (count, page, input) => {
    try {
      if(input === undefined) {
        let result = await axios({
          url: baseURL,
          method: "post",
          data: {
            query: `
              query{
                filterPost(first: ${count}, page: ${page}){
                  data {
                    area {
                      ar
                      en
                      id
                    }
                    category {
                      ar
                      en
                      id
                      image
                    }
                    city {
                      ar
                      en
                      id
                    }
                    created_at
                    daily_post_lease_terms {
                      duration
                      id
                      lease_term
                      overnight
                      price
                      time_in
                      time_out
                      type
                      post_availabilities {
                        date
                        id
                        post_lease_term_id
                        type
                        post_lease_term {
                          duration
                          id
                          lease_term
                          overnight
                          price
                          time_in
                          time_out
                          type
                        }
                      }
                    }
                    description
                    hourly_post_lease_terms {
                      duration
                      id
                      lease_term
                      overnight
                      price
                      time_in
                      time_out
                      type
                      post_availabilities {
                        date
                        id
                        post_lease_term_id
                        type
                        post_lease_term {
                          duration
                          id
                          lease_term
                          overnight
                          price
                          time_in
                          time_out
                          type
                        }
                      }
                    }
                    id
                    latitude
                    location
                    longitude
                    post_images {
                      id
                      image
                      post_id
                    }
                    monthly_post_lease_terms {
                      duration
                      id
                      lease_term
                      overnight
                      price
                      time_in
                      time_out
                      type
                      post_availabilities {
                        date
                        id
                        post_lease_term_id
                        type
                        post_lease_term {
                          duration
                          id
                          lease_term
                          overnight
                          price
                          time_in
                          time_out
                          type
                        }
                      }
                    }
                    post_lease_terms {
                      duration
                      id
                      lease_term
                      overnight
                      end_date
                      price
                      time_in
                      time_out
                      type
                      post_availabilities {
                        date
                        id
                        post_lease_term_id
                        type
                        post_lease_term {
                          duration
                          id
                          lease_term
                          overnight
                          price
                          time_in
                          time_out
                          type
                        }
                      }
                    }
                    review_average
                    review_count
                    title
                    updated_at
                    user {
                      address
                      avatar
                      email
                      gender
                      id
                      language
                      longitude
                      latitude
                      name
                      phone
                      role {
                        ar
                        en
                        id
                      }
                    }
                  }
                  paginatorInfo {
                    count
                    currentPage
                    firstItem
                    hasMorePages
                    lastItem
                    lastPage
                    perPage
                    total
                  }
                }
              }
            `
          }
        });
  
        // console.log(result)
        //--------------------------------------------------------------------------------------------------------------------------------
        if(result.data.data) {
          setPostsLoading(false);
          setPosts(result.data.data.filterPost);
        }
      }
      else {
        let result = await axios({
          url: baseURL,
          method: "post",
          data: {
            query: `
              query{
                filterPost(first: ${count}, page: ${page}, input: ${input}){
                  data {
                    area {
                      ar
                      en
                      id
                    }
                    category {
                      ar
                      en
                      id
                      image
                    }
                    city {
                      ar
                      en
                      id
                    }
                    created_at
                    daily_post_lease_terms {
                      duration
                      id
                      lease_term
                      overnight
                      price
                      time_in
                      time_out
                      type
                      post_availabilities {
                        date
                        id
                        post_lease_term_id
                        type
                        post_lease_term {
                          duration
                          id
                          lease_term
                          overnight
                          price
                          time_in
                          time_out
                          type
                        }
                      }
                    }
                    description
                    hourly_post_lease_terms {
                      duration
                      id
                      lease_term
                      overnight
                      price
                      time_in
                      time_out
                      type
                      post_availabilities {
                        date
                        id
                        post_lease_term_id
                        type
                        post_lease_term {
                          duration
                          id
                          lease_term
                          overnight
                          price
                          time_in
                          time_out
                          type
                        }
                      }
                    }
                    id
                    latitude
                    location
                    longitude
                    post_images {
                      id
                      image
                      post_id
                    }
                    monthly_post_lease_terms {
                      duration
                      id
                      lease_term
                      overnight
                      price
                      time_in
                      time_out
                      type
                      post_availabilities {
                        date
                        id
                        post_lease_term_id
                        type
                        post_lease_term {
                          duration
                          id
                          lease_term
                          overnight
                          price
                          time_in
                          time_out
                          type
                        }
                      }
                    }
                    post_lease_terms {
                      duration
                      id
                      lease_term
                      overnight
                      price
                      time_in
                      time_out
                      type
                      post_availabilities {
                        date
                        id
                        post_lease_term_id
                        type
                        post_lease_term {
                          duration
                          id
                          lease_term
                          overnight
                          price
                          time_in
                          time_out
                          type
                        }
                      }
                    }
                    review_average
                    review_count
                    title
                    updated_at
                    user {
                      address
                      avatar
                      email
                      gender
                      id
                      language
                      longitude
                      latitude
                      name
                      phone
                      role {
                        ar
                        en
                        id
                      }
                    }
                  }
                  paginatorInfo {
                    count
                    currentPage
                    firstItem
                    hasMorePages
                    lastItem
                    lastPage
                    perPage
                    total
                  }
                }
              }
            `
          }
        });
  
        // console.log(result)
        if(result.data.data) {
          setPostsLoading(false);
          setPosts(result.data.data.filterPost);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }


  // get user posts My Lessor Profile page
  const getUserPosts = async (count, page, input) => {
    try {
      // user_posts not working will use filterPost instead
      // let result = await axios({
      //   url: baseURL,
      //   method: "post",
      //   headers: apiHeaders,
      //   data: {
      //     query: `
      //       query{
      //         user_posts {
      //           posts(first: ${count}, page: ${page}){
      //             data {
      //               title
      //               id
      //               area {
      //                 ar
      //                 en
      //                 id
      //               }
      //               category {
      //                 ar
      //                 en
      //                 id
      //                 image
      //               }
      //               city {
      //                 ar
      //                 en
      //                 id
      //               }
      //               created_at
      //               description
      //               latitude
      //               location
      //               longitude
      //               post_images {
      //                 id
      //                 image
      //                 post_id
      //               }
      //               review_average
      //               review_count
      //               updated_at
      //               user {
      //                 address
      //                 avatar
      //                 gender
      //                 email
      //                 id
      //                 latitude
      //                 longitude
      //                 name
      //                 phone
      //                 role {
      //                   ar
      //                   en
      //                   id
      //                 }
      //               }
      //               daily_post_lease_terms {
      //                 created_at
      //                 duration
      //                 id
      //                 lease_term
      //                 overnight
      //                 post_availabilities {
      //                   date
      //                   id
      //                   post_lease_term_id
      //                   type
      //                   post_lease_term {
      //                     duration
      //                     id
      //                     lease_term
      //                     overnight
      //                     price
      //                     time_in
      //                     time_out
      //                     type
      //                   }
      //                 }
      //                 price
      //                 time_in
      //                 time_out
      //                 type
      //               }
      //               hourly_post_lease_terms {
      //                 created_at
      //                 duration
      //                 id
      //                 lease_term
      //                 overnight
      //                 price
      //                 time_in
      //                 time_out
      //                 type
      //                 post_availabilities {
      //                   date
      //                   id
      //                   post_lease_term_id
      //                   type
      //                   post_lease_term {
      //                     created_at
      //                     duration
      //                     id
      //                     lease_term
      //                     overnight
      //                     price
      //                     time_in
      //                     time_out
      //                     type
      //                   }
      //                 }
      //               }
      //               monthly_post_lease_terms {
      //                 created_at
      //                 duration
      //                 id
      //                 lease_term
      //                 overnight
      //                 price
      //                 time_in
      //                 time_out
      //                 type
      //                 post_availabilities {
      //                   date
      //                   id
      //                   post_lease_term_id
      //                   type
      //                   post_lease_term {
      //                     created_at
      //                     duration
      //                     id
      //                     lease_term
      //                     overnight
      //                     price
      //                     time_in
      //                     time_out
      //                     type
      //                   }
      //                 }
      //               }
      //               post_lease_terms {
      //                 created_at
      //                 duration
      //                 id
      //                 lease_term
      //                 overnight
      //                 end_date
      //                 price
      //                 time_in
      //                 time_out
      //                 type
      //                 updated_at
      //                 post_availabilities {
      //                   date
      //                   id
      //                   post_lease_term_id
      //                   type
      //                   post_lease_term {
      //                     duration
      //                     created_at
      //                     id
      //                     lease_term
      //                     overnight
      //                     price
      //                     time_in
      //                     time_out
      //                     type
      //                   }
      //                 }
      //               }
      //             }
      //             paginatorInfo {
      //               count
      //               currentPage
      //               firstItem
      //               hasMorePages
      //               lastItem
      //               lastPage
      //               perPage
      //               total
      //             }
      //           }
      //         }
      //       }
      //     `
      //   }
      // });


      let result = await axios({
        url: baseURL,
        method: "post",
        headers: apiHeaders,
        data: {
          query: `
            query{
              filterPost(first: ${count}, page: ${page}, input: ${input}) {
                data {
                  title
                  id
                  area {
                    ar
                    en
                    id
                  }
                  category {
                    ar
                    en
                    id
                    image
                  }
                  city {
                    ar
                    en
                    id
                  }
                  created_at
                  description
                  latitude
                  location
                  longitude
                  post_images {
                    id
                    image
                    post_id
                  }
                  review_average
                  review_count
                  updated_at
                  user {
                    address
                    avatar
                    gender
                    email
                    id
                    latitude
                    longitude
                    name
                    phone
                    role {
                      ar
                      en
                      id
                    }
                  }
                  daily_post_lease_terms {
                    created_at
                    duration
                    id
                    lease_term
                    overnight
                    post_availabilities {
                      date
                      id
                      post_lease_term_id
                      type
                      post_lease_term {
                        duration
                        id
                        lease_term
                        overnight
                        price
                        time_in
                        time_out
                        type
                      }
                    }
                    price
                    time_in
                    time_out
                    type
                  }
                  hourly_post_lease_terms {
                    created_at
                    duration
                    id
                    lease_term
                    overnight
                    price
                    time_in
                    time_out
                    type
                    post_availabilities {
                      date
                      id
                      post_lease_term_id
                      type
                      post_lease_term {
                        created_at
                        duration
                        id
                        lease_term
                        overnight
                        price
                        time_in
                        time_out
                        type
                      }
                    }
                  }
                  monthly_post_lease_terms {
                    created_at
                    duration
                    id
                    lease_term
                    overnight
                    price
                    time_in
                    time_out
                    type
                    post_availabilities {
                      date
                      id
                      post_lease_term_id
                      type
                      post_lease_term {
                        created_at
                        duration
                        id
                        lease_term
                        overnight
                        price
                        time_in
                        time_out
                        type
                      }
                    }
                  }
                  post_lease_terms {
                    created_at
                    duration
                    id
                    lease_term
                    overnight
                    end_date
                    price
                    time_in
                    time_out
                    type
                    updated_at
                    post_availabilities {
                      date
                      id
                      post_lease_term_id
                      type
                      post_lease_term {
                        duration
                        created_at
                        id
                        lease_term
                        overnight
                        price
                        time_in
                        time_out
                        type
                      }
                    }
                  }
                }
                paginatorInfo {
                  count
                  currentPage
                  firstItem
                  hasMorePages
                  lastItem
                  lastPage
                  perPage
                  total
                }
              }
            }
          `
        }
      });

      // console.log(result)
      if(result.data.data) {
        setUserPostsLoading(false);
        setUserPosts(result.data.data.filterPost);
        return result.data.data.filterPost
      }
    } catch (err) {
      console.log(err);
    }
  }



  // get specific post
  const getViewPost = async (id) => {
    try {
      let result = await axios({
        url: baseURL,
        method: "post",
        headers: apiHeaders,
        data: {
          query: `
            query{
              viewPost(id:${id}) {
              quantity
                area {
                  ar
                  en
                  id
                }
                category {
                  ar
                  en
                  id
                  image
                  subcategories {
                    ar
                    en
                    id
                    image
                  }
                }
                subcategory{
                id
                en
                ar
                image
                }
                city {
                  ar
                  en
                  id
                }
                created_at
                description
                id
                latitude
                location
                longitude
                post_images {
                  id
                  image
                  post_id
                }
                review_average
                review_count
                title
                user {
                  address
                  avatar
                  id
                  latitude
                  longitude
                  name
                  role {
                    ar
                    en
                    id
                  }
                }
                post_lease_terms {
                  created_at
                  duration
                  end_date
                  id
                  lease_term
                  overnight
                  post_availabilities {
                    date
                    id
                    post_lease_term_id
                    type
                    post_lease_term {
                      lease_term
                      id
                    }
                  }
                  deposit
                  price
                  time_in
                  time_out
                  type
                }
                invoice {
                  admin_claimed_date
                  admin_commission
                  booking {
                    booking_date
                    created_at
                    deleted_at
                    end_date
                    id
                    lease_term
                    lessor {
                      avatar
                      name
                    }
                    review {
                      comment
                      created_at
                      id
                      reply
                      star
                      status
                    }
                  }
                }
              }
            }
          `
        }
      });

      // console.log(result)
      if(result.data.data) {
        setViewPost(result.data.data.viewPost);
        return(result.data.data.viewPost);
      }
    } catch (err) {
      console.log(err);
    }
  }


  // get post lease term availabilities (for booking) [daily and hourly]
  const getLeaseTermAvailability = async (post_id, lease_term, post_lease_term_id) => {
    try {
      let result = await axios({
        url: baseURL,
        method: "post",
        headers: apiHeaders,
        data: {
          query: `
            query{
              postLeaseTermAvailability(
                input: {post_id: "${post_id}" ,lease_term: ${lease_term}, post_lease_term_id: "${post_lease_term_id}"}, 
                first: 1000
                orderBy: {field: "date", order: ASC}
              ) {
                data {
                  date
                  id
                  post_lease_term_id
                  type
                  post_lease_term {
                    created_at
                    duration
                    end_date
                    id
                    lease_term
                    overnight
                    price
                    time_in
                    time_out
                    type
                    updated_at
                  }
                }
              }
            }
          `
        }
      });

      // console.log(result)
      if(result.data.data) {
        setPostLeaseTermAvailabilityLoading(false);
        setPostLeaseTermAvailability(result.data.data.postLeaseTermAvailability);
        return(result.data.data.postLeaseTermAvailability)
      }
    } catch (err) {
      console.log(err);
    }
  }

  //check available quantity (on booking)
  const getCheckRemainingQuantity = async (input) => {
    try{
      let result = await axios({
        url: baseURL,
        method: "post",
        headers: apiHeaders,
        data: {
          query: `
            query{
              checkRemainingQuantity(input: ${input}) 
              {
                  status
                  id
                  quantity
              }
            }
          `
        }
      });

      if(result.data.data){
        return result.data.data;
      }
    }
    catch (err){
      console.log(err);
    }
  }



  // get post lease term unavailability (for booking) [monthly]
  const getLeaseTermUnavailability = async (post_id, lease_term) => {
    try {
      let result = await axios({
        url: baseURL,
        method: "post",
        headers: apiHeaders,
        data: {
          query: `
            query{
              bookingUnavailability(
                input: {post_id: "${post_id}", lease_term: ${lease_term}}, 
                first: 1000
              ) {
                data {
                  booking_date
                  end_date
                  id
                  lease_term
                  time_in
                  time_out
                }
              }
            }
          `
        }
      });

      // console.log(result)
      if(result.data.data) {
        setPostLeaseTermAvailabilityLoading(false);
        setPostLeaseTermUnavailability(result.data.data.bookingUnavailability);
      }
    } catch (err) {
      console.log(err);
    }
  }


  // get discount
  const getDiscount = async (code) => {
    try {
      let result = await axios({
        url: baseURL,
        method: "post",
        headers: apiHeaders,
        data: {
          query: `
            query{
              findDiscountCode(code: "${code}") {
                capacity
                code
                expiration_date
                id
                invoice_count
                rate
                title
                type
              }
            }
          `
        }
      });

      // console.log(result)
      if(result) {
        setDiscount(result);
      }
    } catch (err) {
      console.log(err);
    }
  }



  // list of active bookings
  const getActiveBookings = async (count, page, input) => {
    try {
      if(input === undefined) {
        let result = await axios({
          url: baseURL,
          method: "post",
          headers: apiHeaders,
          data: {
            query: `
              query{
                activeBooking(first: ${count}, page: ${page}){
                  data {
                  post_lease_term {
                      lease_term
                      price
                      deposit
                    }
                    booking_date
                    cancel_status
                    deleted_at
                    end_date
                    is_cancellable
                    cancellable_before_at
                    id
                    lease_term
                    time_in
                    time_out
                    item_returned_at
                    post {
                      location
                      area {
                        ar
                        en
                        id
                      }
                      category {
                        ar
                        en
                        image
                      }
                      city {
                        ar
                        en
                        id
                      }
                      description
                      id
                      latitude
                      longitude
                      post_images {
                        id
                        image
                      }
                      review_average
                      review_count
                      title
                      post_lease_terms {
                        lease_term
                        price
                        deposit
                      }
                    }
                    user {
                      name
                      id
                      avatar
                      role {
                        ar
                        en
                      }
                      address
                    }
                    lessor {
                      name
                      id
                      avatar
                      address
                    }
                    invoice {
                      admin_claimed_date
                      admin_commission
                      app_used
                      id
                      lessor_claimed_date
                      lessor_commission
                      payment_date
                      payment_for
                      payment_method
                      price
                      response
                      status
                      subtotal
                      total
                      transaction
                      vat_price
                      vat_rate
                      discount_amount
                    }
                    refund {
                      admin_claimed_date
                      admin_commission
                      app_used
                      id
                      lessor_claimed_date
                      lessor_commission
                      payment_date
                      payment_for
                      payment_method
                      price
                      response
                      status
                      subtotal
                      total
                      transaction
                      vat_price
                      vat_rate
                    }
                  }
                  paginatorInfo {
                    count
                    currentPage
                    firstItem
                    hasMorePages
                    lastItem
                    lastPage
                    perPage
                    total
                  }
                }
              }
            `
          }
        });
  
        // console.log(result)
        if(result.data.data) {
          setActiveBookingLoading(false);
          setActiveBookings(result.data.data.activeBooking);
          return result.data.data.activeBooking;
        }
      }
      else {
        let result = await axios({
          url: baseURL,
          method: "post",
          headers: apiHeaders,
          data: {
            query: `
              query{
                activeBooking(first: ${count}, page: ${page}, input: ${input}){
                  data {
                    post_lease_term {
                      lease_term
                      price
                      deposit
                    }
                    is_active
                    is_complete
                    booking_date
                    cancel_status
                    cancellable_before_at
                    deleted_at
                    end_date
                    is_cancellable
                    id
                    lease_term
                    time_in
                    time_out
                    item_returned_at
                    post {
                      location
                      area {
                        ar
                        en
                        id
                      }
                      category {
                        ar
                        en
                        image
                      }
                      city {
                        ar
                        en
                        id
                      }
                      description
                      id
                      latitude
                      longitude
                      post_images {
                        id
                        image
                      }
                      review_average
                      review_count
                      title
                    }
                    user {
                      name
                      id
                      avatar
                      role {
                        ar
                        en
                      }
                      address
                    }
                    lessor {
                      name
                      id
                      avatar
                      address
                    }
                    invoice {
                      admin_claimed_date
                      admin_commission
                      app_used
                      id
                      lessor_claimed_date
                      lessor_commission
                      payment_date
                      payment_for
                      payment_method
                      price
                      response
                      status
                      subtotal
                      total
                      transaction
                      vat_price
                      vat_rate
                      discount_amount
                    }
                    refund {
                      admin_claimed_date
                      admin_commission
                      app_used
                      id
                      lessor_claimed_date
                      lessor_commission
                      payment_date
                      payment_for
                      payment_method
                      price
                      response
                      status
                      subtotal
                      total
                      transaction
                      vat_price
                      vat_rate
                    }
                  }
                  paginatorInfo {
                    count
                    currentPage
                    firstItem
                    hasMorePages
                    lastItem
                    lastPage
                    perPage
                    total
                  }
                }
              }
            `
          }
        });
        if(result.data.data) {
          setActiveBookingLoading(false);
          setActiveBookings(result.data.data.activeBooking);
          return result.data.data.activeBooking;
        }
      }
    } catch (err) {
      console.log(err);
    }
  }


  // list of history bookings
  const getHistoryBookings = async (count, page, input) => {
    try {
      if(input === undefined) {
        let result = await axios({
          url: baseURL,
          method: "post",
          headers: apiHeaders,
          data: {
            query: `
              query{
                historyBooking(first: ${count}, page: ${page}){
                  data {
                    post_lease_term {
                      deposit
                    }
                    is_complete
                    is_active
                    booking_date
                    cancel_status
                    deleted_at
                    end_date
                    id
                    lease_term
                    time_in
                    time_out
                    item_returned_at
                    post {
                      location
                      area {
                        ar
                        en
                        id
                      }
                      category {
                        ar
                        en
                        image
                      }
                      city {
                        ar
                        en
                        id
                      }
                      description
                      id
                      latitude
                      longitude
                      post_images {
                        id
                        image
                      }
                      review_average
                      review_count
                      title
                      post_lease_terms {
                        lease_term
                        price
                      }
                    }
                    user {
                      name
                      id
                      avatar
                      role {
                        ar
                        en
                      }
                      address
                    }
                    lessor {
                      name
                      id
                      avatar
                      address
                    }
                    invoice {
                      admin_claimed_date
                      admin_commission
                      app_used
                      id
                      lessor_claimed_date
                      lessor_commission
                      payment_date
                      payment_for
                      payment_method
                      price
                      response
                      status
                      subtotal
                      total
                      transaction
                      vat_price
                      vat_rate
                      discount_amount
                    }
                    refund {
                      admin_claimed_date
                      admin_commission
                      app_used
                      id
                      lessor_claimed_date
                      lessor_commission
                      payment_date
                      payment_for
                      payment_method
                      price
                      response
                      status
                      subtotal
                      total
                      transaction
                      vat_price
                      vat_rate
                    }
                  }
                  paginatorInfo {
                    count
                    currentPage
                    firstItem
                    hasMorePages
                    lastItem
                    lastPage
                    perPage
                    total
                  }
                }
              }
            `
          }
        });
  
        // console.log(result)
        if(result.data.data) {
          setHistoryBookingLoading(false);
          setHistoryBookings(result.data.data.historyBooking);
          return(result.data.data.historyBooking);
        }
      }
      else {
        let result = await axios({
          url: baseURL,
          method: "post",
          headers: apiHeaders,
          data: {
            query: `
              query{
                historyBooking(first: ${count}, page: ${page}, input: ${input}){
                  data {
                  post_lease_term {
                      deposit
                      price
                    }
                    is_complete
                    is_active
                    booking_date
                    cancel_status
                    deleted_at
                    end_date
                    id
                    lease_term
                    time_in
                    time_out
                    item_returned_at
                    post {
                      location
                      area {
                        ar
                        en
                        id
                      }
                      category {
                        ar
                        en
                        image
                      }
                      city {
                        ar
                        en
                        id
                      }
                      description
                      id
                      latitude
                      longitude
                      post_images {
                        id
                        image
                      }
                      review_average
                      review_count
                      title
                      post_lease_terms {
                        lease_term
                        price
                      }
                    }
                    user {
                      name
                      id
                      avatar
                      role {
                        ar
                        en
                      }
                      address
                    }
                    lessor {
                      name
                      id
                      avatar
                      address
                    }
                    invoice {
                      admin_claimed_date
                      admin_commission
                      app_used
                      id
                      lessor_claimed_date
                      lessor_commission
                      payment_date
                      payment_for
                      payment_method
                      price
                      response
                      status
                      subtotal
                      total
                      transaction
                      vat_price
                      vat_rate
                      discount_amount
                    }
                    refund {
                      admin_claimed_date
                      admin_commission
                      app_used
                      id
                      lessor_claimed_date
                      lessor_commission
                      payment_date
                      payment_for
                      payment_method
                      price
                      response
                      status
                      subtotal
                      total
                      transaction
                      vat_price
                      vat_rate
                    }
                  }
                  paginatorInfo {
                    count
                    currentPage
                    firstItem
                    hasMorePages
                    lastItem
                    lastPage
                    perPage
                    total
                  }
                }
              }
            `
          }
        });
  
        // console.log(result)
        if(result.data.data) {
          setHistoryBookingLoading(false);
          setHistoryBookings(result.data.data.historyBooking);
          return(result.data.data.historyBooking);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }


  // list of post reviews
  const getPostReviews = async (count, page, lessor_id) => {
    try {
      if(lessor_id.user_id === undefined) {
        let result = await axios({
          url: baseURL,
          method: "post",
          headers: apiHeaders,
          data: {
            query: `
              query{
                postReviews(first: ${count}, page: ${page}){
                  data {
                    reply
                    id
                    star
                    status
                    user {
                      id
                      name
                      avatar
                    }
                    created_at
                    comment
                    post {
                      id
                      post_images {
                        id
                        image
                      }
                      title
                    }
                  }
                  paginatorInfo {
                    count
                    currentPage
                    firstItem
                    hasMorePages
                    lastItem
                    lastPage
                    perPage
                    total
                  }
                }
              }
            `
          }
        });
  
        // console.log(result)
        if(result.data.data) {
          setPostReviewsLoading(false);
          setPostReviews(result.data.data.postReviews);
          return(result.data.data.postReviews);
        }
      }
      else {
        let result = await axios({
          url: baseURL,
          method: "post",
          headers: apiHeaders,
          data: {
            query: `
              query{
                postReviews(first: ${count}, page: ${page}, lessor_id: ${lessor_id?.user_id}){
                  data {
                    reply
                    id
                    star
                    status
                    user {
                      id
                      name
                      avatar
                    }
                    created_at
                    comment
                    post {
                      id
                      post_images {
                        id
                        image
                      }
                      title
                    }
                  }
                  paginatorInfo {
                    count
                    currentPage
                    firstItem
                    hasMorePages
                    lastItem
                    lastPage
                    perPage
                    total
                  }
                }
              }
            `
          }
        });
        if(result.data.data) {
          setPostReviewsLoading(false);
          setPostReviews(result.data.data.postReviews);
          return(result.data.data.postReviews);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }



  // list of lessee booked post history (lessor-profile page of lessee)
  const getLesseeBookedPostHistory = async (count, page, input) => {
    try {
      if(input === undefined) {
        let result = await axios({
          url: baseURL,
          method: "post",
          headers: apiHeaders,
          data: {
            query: `
              query{
                lesseeBookedPostHistory(first: ${count}, page: ${page}){
                  data {
                    title
                    area {
                      ar
                      en
                      id
                    }
                    category {
                      ar
                      en
                      image
                      id
                    }
                    city {
                      ar
                      en
                      id
                    }
                    created_at
                    description
                    id
                    latitude
                    location
                    longitude
                    post_images {
                      id
                      image
                      post_id
                    }
                    review_average
                    review_count
                    user {
                      id
                      name
                      role {
                        ar
                        en
                        id
                      }
                      avatar
                    }
                    invoice {
                      admin_claimed_date
                      admin_commission
                      app_used
                      created_at
                      discount_amount
                      id
                      lessor_claimed_date
                      lessor_commission
                      payment_date
                      payment_for
                      payment_method
                      price
                      refund {
                        id
                        time_in
                        time_out
                        lease_term
                      }
                    }
                    post_lease_terms {
                      lease_term
                      price
                      time_in
                      time_out
                      type
                      end_date
                      duration
                      id
                    }
                  }
                  paginatorInfo {
                    count
                    currentPage
                    firstItem
                    hasMorePages
                    lastItem
                    lastPage
                    perPage
                    total
                  }
                }
              }
            `
          }
        });
  
        // console.log(result)
        if(result.data.data) {
          setLesseeBookedPostHistoryLoading(false);
          setLesseeBookedPostHistory(result.data.data.lesseeBookedPostHistory);
        }
      }
      else {
        let result = await axios({
          url: baseURL,
          method: "post",
          headers: apiHeaders,
          data: {
            query: `
              query{
                lesseeBookedPostHistory(first: ${count}, page: ${page}, input: ${input}){
                  data {
                    title
                    area {
                      ar
                      en
                      id
                    }
                    category {
                      ar
                      en
                      image
                      id
                    }
                    city {
                      ar
                      en
                      id
                    }
                    created_at
                    description
                    id
                    latitude
                    location
                    longitude
                    post_images {
                      id
                      image
                      post_id
                    }
                    review_average
                    review_count
                    user {
                      id
                      name
                      role {
                        ar
                        en
                        id
                      }
                      avatar
                    }
                    invoice {
                      admin_claimed_date
                      admin_commission
                      app_used
                      created_at
                      discount_amount
                      id
                      lessor_claimed_date
                      lessor_commission
                      payment_date
                      payment_for
                      payment_method
                      price
                      refund {
                        id
                        time_in
                        time_out
                        lease_term
                      }
                    }
                    post_lease_terms {
                      lease_term
                      price
                      time_in
                      time_out
                      type
                      end_date
                      duration
                      id
                    }
                  }
                  paginatorInfo {
                    count
                    currentPage
                    firstItem
                    hasMorePages
                    lastItem
                    lastPage
                    perPage
                    total
                  }
                }
              }
            `
          }
        });
  
        // console.log(result)
        if(result.data.data) {
          setLesseeBookedPostHistoryLoading(false);
          setLesseeBookedPostHistory(result.data.data.lesseeBookedPostHistory);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  
  // get specific user
  const getViewUser = async (id) => {
    try {
      let result = await axios({
        url: baseURL,
        headers: apiHeaders,
        method: "post",
        data: {
          query: `
            query{
              viewUser(id: ${id}){
                address
                agreement_verified_at
                approved_at
                avatar
                bank_account_num
                bank_name
                created_at
                email
                gender
                id
                is_verified
                language
                latitude
                longitude
                name
                phone
                role {
                  ar
                  en
                  id
                }
                valid_id_picture
                verfied_at
              }
            }
          `
        }
      });

      if(result.data.data) {
        setViewUser(result.data.data.viewUser);
      }
      
    } catch (err) {
      console.log(err);
    }
  }


  // get invoice active
  const getInvoiceActive = async (count, page, role) => {
    try {
      let result = await axios({
        url: baseURL,
        headers: apiHeaders,
        method: "post",
        data: {
          query: `
            query{
              invoiceActive(first: ${count}, page: ${page}, input: {user_role: ${role}}){
                data {
                  admin_claimed_date
                  admin_commission
                  app_used
                  created_at
                  discount_amount
                  id
                  lessor_claimed_date
                  lessor_commission
                  payment_date
                  payment_for
                  payment_method
                  price
                  response
                  status
                  subtotal
                  total
                  transaction
                  vat_price
                  vat_rate
                  booking {
                    booking_date
                    created_at
                    end_date
                    id
                    lease_term
                    post {
                      title
                    }
                  }
                }
                paginatorInfo {
                  count
                  currentPage
                  firstItem
                  hasMorePages
                  lastItem
                  lastPage
                  perPage
                  total
                }
              }
            }
          `
        }
      });

      if(result.data.data) {
        setInvoiceActiveLoading(false);
        setInvoiceActive(result.data.data.invoiceActive);
        return result.data.data.invoiceActive;
      }
      
    } catch (err) {
      console.log(err);
    }
  }


  // get invoice history
  const getInvoiceHistory = async (count, page, role) => {
    try {
      let result = await axios({
        url: baseURL,
        headers: apiHeaders,
        method: "post",
        data: {
          query: `
            query{
              invoiceHistory(first: ${count}, page: ${page}, input: {user_role: ${role}}){
                data {
                  admin_claimed_date
                  admin_commission
                  app_used
                  created_at
                  discount_amount
                  id
                  lessor_claimed_date
                  lessor_commission
                  payment_date
                  payment_for
                  payment_method
                  price
                  response
                  status
                  subtotal
                  total
                  transaction
                  vat_price
                  vat_rate
                  booking {
                    cancel_status
                    booking_date
                    created_at
                    deleted_at
                    end_date
                    id
                    lease_term
                    post {
                      title
                    }
                    refund {
                      admin_claimed_date
                      admin_commission
                      app_used
                      id
                      lessor_claimed_date
                      lessor_commission
                      payment_date
                      payment_for
                      payment_method
                      price
                      response
                      status
                      subtotal
                      total
                      transaction
                      vat_price
                      vat_rate
                    }
                  }
                }
                paginatorInfo {
                  count
                  currentPage
                  firstItem
                  hasMorePages
                  lastItem
                  lastPage
                  perPage
                  total
                }
              }
            }
          `
        }
      });

      if(result.data.data) {
        setInvoiceHistoryLoading(false);
        setInvoiceHistory(result.data.data.invoiceHistory);
        return result.data.data.invoiceHistory
      }
      
    } catch (err) {
      console.log(err);
    }
  }


  // get notification settings
  const getNotificationSettings = async () => {
    try {
      let result = await axios({
        url: baseURL,
        headers: apiHeaders,
        method: "post",
        data: {
          query: `
            query{
              notification_setting{
                appoinment_notification
                in_app_sound
                new_message_notification
                notification
                notification_sound
              }
            }
          `
        }
      });

      if(result.data.data) {
        setNotificationSettings(result.data.data.notification_setting);
      }
      
    } catch (err) {
      console.log(err);
    }
  }


  // get total earnings
  const getTotalEarnings = async () => {
    try {
      let result = await axios({
        url: baseURL,
        headers: apiHeaders,
        method: "post",
        data: {
          query: `
            query{
              totalEarnings(user_role: lessor){
                count
                date_end
                date_start
                total
              }
            }
          `
        }
      });

      if(result.data.data) {
        setTotalEarnings(result.data.data.totalEarnings);
      }
      
    } catch (err) {
      console.log(err);
    }
  }


  // get total earnings per year
  const getTotalEarningsperYear = async (yr) => {
    try {
      let result = await axios({
        url: baseURL,
        headers: apiHeaders,
        method: "post",
        data: {
          query: `
            query{
              totalEarningsperYear(year: "${yr}"){
                count
                month
                post_reports {
                  count
                  title
                  total
                }
                total
              }
            }
          `
        }
      });

      if(result.data.data) {
        setYearChangeLoading(false);
        setTotalEarningsperYear(result.data.data.totalEarningsperYear);
      }
      
    } catch (err) {
      console.log(err);
    }
  }


  // get user notifications
  const getUserNotifications = async (count, page) => {
    try {
      let result = await axios({
        url: baseURL,
        headers: apiHeaders,
        method: "post",
        data: {
          query: `
            query{
              user_notifications
                (first: ${count}, page: ${page})
                {
                data{
                    id
                    image
                    message
                    message_ar
                    status
                    title
                    title_ar
                    user_id
                    created_at
                    updated_at
                  }
                  paginatorInfo{
                    count
                    currentPage
                    firstItem
                    hasMorePages
                    lastItem
                    lastPage
                    perPage
                    total
                  }
                }
              }
          `
        }
      });

      if(result.data.data) {
        setUserNotificationsLoading(false);
        setUserNotifications(result.data.data.user_notifications);
        return result.data.data.user_notifications;
      }
      
    } catch (err) {
      console.log(err);
    }
  }


  // get all questions
  const getAllQuestions = async () => {
    try {
      let result = await axios({
        url: baseURL,
        method: "post",
        data: {
          query: `
            query{
              allQuestions{
                answer
                answer_ar
                question
                question_ar
                created_at
                updated_at
              }
            }
          `
        }
      });

      if(result.data.data) {
        setAllQuestions(result.data.data.allQuestions);
      }
      
    } catch (err) {
      console.log(err);
    }
  }

  //swiftcode
  const getAllSwiftCodes = async () => {
    try {
      let result = await axios({
        url: baseURL,
        headers: apiHeaders,
        method: "post",
        data: {
          query: `
          query{
            allSwiftCodes{
                id
                bank_name
                bank_name_ar
                bic
                image
            }
          }
          `
        }
      });

      if(result) {
        return result.data.data;
      }
      
    } catch (err) {
      console.log(err);
    }
  }


  // get user details from chat users list
  const getChatUserDetails = async (arrayIds) => {
    try {
      let result = await axios({
        url: baseURL,
        headers: apiHeaders,
        method: "post",
        data: {
          query: `
          query{
            viewUsers(first: 1000, page: 1, id: [${arrayIds}]){
              data {
                name
                avatar
                id
              }
            }
          }
          `
        }
      });

      if(result.data.data) {
        setChatUserDetails(result.data.data.viewUsers);
      }
      
    } catch (err) {
      console.log(err);
    }
  }



  //****************************/ firebase functions

  // get list on conversation
  const getChatList = async (lesseeId, lessorId) => {

    let refChat = "room/fb_user" + lesseeId + "_fb_user" + lessorId;

    database
      .ref(refChat)
      .once("value", (snapshot) => {
        const chatListObject = (snapshot && snapshot.val()) || {};

        const chatListArray =
          (chatListObject &&
            Object.keys(chatListObject) &&
            Object.keys(chatListObject).length &&
            Object.keys(chatListObject).map((key) => {
              chatListObject[key].key = key;
              return chatListObject[key];
            })) ||
          [];

          setChatList(chatListArray.reverse());
      });
  }


  // get chat users list
  const getUserChatList = async (id) => {

    let refUserChat = "users/" + id;

    database
      .ref(refUserChat)
      .on("value", (snapshot) => {
        const userChatListObject = (snapshot && snapshot.val()) || {};

        const userChatListArray =
          (userChatListObject &&
            Object.keys(userChatListObject) &&
            Object.keys(userChatListObject).length &&
            Object.keys(userChatListObject).map((key) => {
              userChatListObject[key].key = key;
              return userChatListObject[key];
            })) ||
          [];

          setUserChatList(userChatListArray);
      });
  }

  


  // logout user
  const LogOutUser = () => {
    window.location.href="/";

    cookies.remove('userTkn', { path: '/' });
    cookies.remove('code', { path: '/' });
    cookies.remove('phoneNumber', { path: '/' });
    localStorage.removeItem("deposit");
    localStorage.removeItem("propertyTitle");
    localStorage.removeItem("depositToggle0");
    localStorage.removeItem("depositToggle1");
    localStorage.removeItem("depositToggle2");
    localStorage.removeItem("price");
    localStorage.removeItem("postDesc");
    localStorage.removeItem("quantity");
  }

  const payload = {
    baseURL,
    baseURLPayment,
    apiHeaders,
    getMe,
    me,
    getCategories,
    categories,
    getSubCategories,
    subCategories,
    getArea,
    area,
    getCity,
    city,
    getAllSettings,
    allSettings,
    getAllPost,
    getPosts,
    posts,
    postsLoading,
    setPostsLoading,
    getUserPosts,
    userPosts,
    userPostsLoading,
    setUserPostsLoading,
    getViewPost,
    viewPost,
    getLeaseTermAvailability,
    postLeaseTermAvailability,
    getCheckRemainingQuantity,
    getLeaseTermUnavailability,
    postLeaseTermUnavailability,
    setPostLeaseTermAvailabilityLoading,
    postLeaseTermAvailabilityLoading,
    getDiscount,
    discount,
    getActiveBookings,
    activeBookings,
    activeBookingLoading,
    setActiveBookingLoading,
    getHistoryBookings,
    historyBookings,
    historyBookingLoading,
    setHistoryBookingLoading,
    getPostReviews,
    postReviews,
    postReviewsLoading,
    setPostReviewsLoading,
    getLesseeBookedPostHistory,
    lesseeBookedPostHistory,
    lesseeBookedPostHistoryLoading,
    setLesseeBookedPostHistoryLoading,
    getViewUser,
    viewUser,
    getInvoiceActive,
    invoiceActive,
    invoiceActiveLoading,
    setInvoiceActiveLoading,
    getInvoiceHistory,
    invoiceHistory,
    invoiceHistoryLoading,
    setInvoiceHistoryLoading,
    getNotificationSettings,
    notificationSettings,
    getUserNotifications,
    userNotifications,
    userNotificationsLoading,
    setUserNotificationsLoading,
    getTotalEarnings,
    totalEarnings,
    getTotalEarningsperYear,
    totalEarningsperYear,
    yearChangeLoading,
    setYearChangeLoading,
    getAllQuestions,
    allQuestions,
    
    getChatList,
    chatList,
    getUserChatList,
    userChatList,
    getChatUserDetails,
    chatUserDetails,
    getAllSwiftCodes,

    LogOutUser,
  };


  return <RouteContext.Provider value={payload}>{children}</RouteContext.Provider>
};

RouteProvider.defaultProps = {};

RouteProvider.propTypes = {
  children: PropTypes.object.isRequired,
};

export default RouteProvider;