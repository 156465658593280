import React, { useState, useEffect, useContext, useRef } from "react";
import { Tab, Tabs, Dropdown  } from 'react-bootstrap';
import {FormattedMessage, useIntl} from 'react-intl';
import Pagination from "../pagination";
import { RouteContext } from "../../../contexts/route.context";
import { translateTerm } from "../../../util/helper";
import { useIntersection } from "../../../util/useIntersection";


const LesseeInvoices = () => {
  const intl = useIntl();
  const { getInvoiceActive, invoiceActive, invoiceActiveLoading, setInvoiceActiveLoading, 
    getInvoiceHistory, invoiceHistory, invoiceHistoryLoading, setInvoiceHistoryLoading, } = useContext(RouteContext);
  const [key, setKey] = useState('active');
  const [openDetails, setOpenDetails] = useState({
    set0 : false,
    set1 : false,
    set2 : false,
    set3 : false,
    set4 : false,
    set5 : false,
    set6 : false,
    set7 : false,
    set8 : false,
  });
  const [openDetailsActive, setOpenDetailsActive] = useState();
  const [openDetailsHistory, setOpenDetailsHistory] = useState();
  const [numInv, setNumInv] = useState(21);
  const [pageInv, setpageInv] = useState(1);
  const [lessorRole, setLessorRole] = useState(`lessor`);

  const elementRef = useRef(null);
  const isVisible = useIntersection(elementRef, "0px 0px 40% 0px");
  const [moreLoading, setMoreLoading] = useState(false);
  const [noMorePosts, setNoMorePosts] = useState(false);

  const [myInvoiceActive, setMyInvoiceActive] = useState();
  const [myInvoiceHistory, setMyInvoiceHistory] = useState();


  useEffect(() => {
    setInvoiceActiveLoading(true);
    fetchInvoiceActive();
    fetchInvoiceHistory();
  }, []);

  useEffect(() => {
    if (isVisible) {
    // Trigger a function when the div is visible on view port
    triggerFunc();
    }
  }, [isVisible]);


  useEffect(() => {
    if(myInvoiceHistory && myInvoiceHistory.data.length !== 0) {
      let openIvObj = new Object();
      for (let index = 0; index < myInvoiceHistory.data.length ; index++) {
        let setObj = "set" + index;
        openIvObj[setObj] = false;
      }
      setOpenDetailsActive(openIvObj);
    }
  }, [invoiceActive]);

  useEffect(() => {
    if(invoiceHistory && invoiceHistory.data.length !== 0) {
      let openIvObj = new Object();
      for (let index = 0; index < invoiceHistory.data.length ; index++) {
        let setObj = "set" + index;
        openIvObj[setObj] = false;
      }
      setOpenDetailsHistory(openIvObj);
    }
  }, [invoiceHistory]);

  const triggerFunc = () => {
    if(key === 'active'){
      console.log("active")
      if(myInvoiceActive){
        if(myInvoiceActive.paginatorInfo.hasMorePages){
          scrollPostsActive();
        }
        else{
          setNoMorePosts(true);
        }
      }
    }
    else{
      console.log("History")
      if(myInvoiceHistory){
        if(myInvoiceHistory.paginatorInfo.hasMorePages){
          scrollPostsHistory();
        }
        else{
          setNoMorePosts(true);
        }
      }
    }
  }

  const scrollPostsActive = async () => {
    if(moreLoading == false){
      setMoreLoading(true);
      let result = await getInvoiceActive(numInv, pageInv, lessorRole);
      if(myInvoiceActive.paginatorInfo.hasMorePages){
          result.data.map((dat)=>{
            myInvoiceActive.data.push(dat);
          })
          myInvoiceActive.paginatorInfo = result.paginatorInfo;
      }
      setMoreLoading(false);
    }
  }

  const scrollPostsHistory = async () => {
    if(moreLoading == false){
      setMoreLoading(true);
      let result = await getInvoiceHistory(numInv, pageInv, lessorRole);
      if(myInvoiceHistory.paginatorInfo.hasMorePages){
          result.data.map((dat)=>{
            myInvoiceHistory.data.push(dat);
          })
          myInvoiceHistory.paginatorInfo = result.paginatorInfo;
      }
      setMoreLoading(false);
    }
  }

  const fetchInvoiceActive = async () => {
    let result = await getInvoiceActive(numInv, pageInv, lessorRole);
    setMyInvoiceActive(result);
  }

  const fetchInvoiceHistory = async () => {
    let result = await getInvoiceHistory(numInv, pageInv, lessorRole);
    setMyInvoiceHistory(result);
  }


  const onClickOpenDetailsActive = (position, positionFinal) => {
    setInvoiceActiveLoading(true);
    if(positionFinal) {
      openDetailsActive[position] = false;
      setOpenDetailsActive(openDetailsActive);

      setTimeout(() => setInvoiceActiveLoading(false), 200);
    }
    else {
      openDetailsActive[position] = true;
      setOpenDetailsActive(openDetailsActive);
      
      setTimeout(() => setInvoiceActiveLoading(false), 200);
    }
  }


  const onClickOpenDetailsHistory = (position, positionFinal) => {
    setInvoiceHistoryLoading(true);
    if(positionFinal) {
      openDetailsHistory[position] = false;
      setOpenDetailsHistory(openDetailsHistory);

      setTimeout(() => setInvoiceHistoryLoading(false), 200);
    }
    else {
      openDetailsHistory[position] = true;
      setOpenDetailsHistory(openDetailsHistory);
      
      setTimeout(() => setInvoiceHistoryLoading(false), 200);
    }
  }

  // console.log("invoiceHistory", invoiceHistory)


  return (
    <div>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-4 mt-0 product-categories justify-content-start"
      >
        <Tab eventKey="active" title={intl.formatMessage({
          id: 'active.text',
          defaultMessage: 'Active',
          description: 'Active',
        })}>
          <div className="my-profile-div">
            <div className="row">
              <div className="col-12">
                <p className="fw700 cblue fs20 mb-2">
                  <FormattedMessage 
                    id="activeinvoices.text"
                    defaultMessage="Active Invoices"
                    description="Active Invoices"
                  />
                </p>
                {/* <p className="cblack fs14 mb-3">
                  <FormattedMessage 
                    id="activeindesc.text"
                    defaultMessage="Active Invoices desc"
                    description="Active Invoices desc"
                  />
                </p> */}

                <div className="div-2px mb-4" />

              </div>


              {!invoiceActiveLoading && myInvoiceActive ? (
                <>
                  {myInvoiceActive.data.length !== 0 && myInvoiceActive.data.map((inv, idx) => {
                    let positionFinal, position;
                    if(openDetailsActive) {
                      position = "set"+idx;
                      positionFinal = openDetailsActive[position];
                    }

                    return (
                      <div key={idx} className="col-12 col-lg-4">
                        <div className="invoice-per-div">
                          <p className="mb-3 fs12 cgray d-flex justify-content-between align-items-center">
                            {inv.payment_date}
                            <span className="span-tag active">
                              <FormattedMessage 
                                id="active.text"
                                defaultMessage="Active"
                                description="Active"
                              />
                            </span>
                          </p>
                          <p className="fs14 cblack fw600 mb-0 d-flex justify-content-between align-items-center">
                            <FormattedMessage 
                              id="leasepayment.text"
                              defaultMessage="Lease Payment"
                              description="Lease Payment"
                            />

                            {positionFinal && (
                              <span className="fw700 ">
                                {/* SAR {inv.lessor_commission} */}
                                SAR {inv.total}
                              </span>
                            )}
                            
                          </p>
                          <p className="fw400 fs12 cgray mb-0">INV-{inv.id}</p>


                          {positionFinal && (
                            <div className="invoice-detail-div">
                              <p className="fw600 fs12 cblack mb-1">
                                <FormattedMessage 
                                  id="leaseterm.text"
                                  defaultMessage="Lease Term"
                                  description="Lease Term"
                                />: 
                                <span className="fw400"> {inv.booking && translateTerm(inv.booking.lease_term)}</span>
                              </p>
                              <p className="fw600 fs12 cblack mb-1">
                                <FormattedMessage 
                                  id="startdate.text"
                                  defaultMessage="Start Date"
                                  description="Start Date"
                                />: 
                                <span className="fw400"> {inv.booking && inv.booking.booking_date}</span>
                              </p>
                              <p className="fw600 fs12 cblack mb-1">
                                <FormattedMessage 
                                  id="enddate.text"
                                  defaultMessage="End Date"
                                  description="End Date"
                                />: 
                                <span className="fw400"> {inv.booking && inv.booking.end_date}</span>
                              </p>
                            </div>
                          )}


                          <p className="d-flex fs12 cred mb-0 cur-pointer align-items-center justify-content-end mt-2"
                            onClick={() => onClickOpenDetailsActive(position, positionFinal)}
                          >
                            {positionFinal ? 
                              intl.locale === "en" ? (
                                "Hide lease details"
                              ) : (
                                "إخفاء تفاصيل عقد الإيجا"
                              )
                             : intl.locale === "en" ? (
                                "View lease details"
                             ) : (
                              "عرض تفاصيل عقد الإيجار"
                            )}

                            {positionFinal ? (
                              <svg width="12" height="7" style={{marginLeft:"8px"}} viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.75 6.25L6 1L11.25 6.25" stroke="#FF5833" strokeLinecap="round" strokeLinejoin="round"/>
                              </svg>
                            ) : (
                              <svg width="12" height="7" style={{marginLeft:"8px"}} viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.25 0.75L6 6L0.75 0.75" stroke="#FF5833" strokeLinecap="round" strokeLinejoin="round"/>
                              </svg>
                            )}
                          </p>
                        </div>
                      </div>
                    )
                    })}
                  {/* <Pagination useFunction="getInvoiceActive" posts={invoiceActive} setUserPostsLoading={setInvoiceActiveLoading} numLease={numInv} /> */}
                </>
              )
              : (
                <div className="loader"></div>
              )}
            </div>
          </div>

          

        </Tab>
        <Tab eventKey="history" title={intl.formatMessage({
          id: 'history.text',
          defaultMessage: 'History',
          description: 'History',
        })}>
          
          <div className="my-profile-div">
            <div className="row">
              <div className="col-12">
                <p className="fw700 cblue fs20 mb-2">
                  <FormattedMessage 
                    id="historyinvoices.text"
                    defaultMessage="History Invoices"
                    description="History Invoices"
                  />
                </p>
                {/* <p className="cblack fs14 mb-3">
                  <FormattedMessage 
                    id="activeindesc.text"
                    defaultMessage="Active Invoices desc"
                    description="Active Invoices desc"
                  />
                </p> */}

                <div className="div-2px mb-4" />

              </div>

              {!invoiceHistoryLoading && myInvoiceHistory ? (
                <>
                  {myInvoiceHistory.data.length !== 0 && myInvoiceHistory.data.map((inv, idx) => {
                    let positionFinal, position;
                    if(openDetailsHistory) {
                      position = "set"+idx;
                      positionFinal = openDetailsHistory[position];
                    }

                    return (
                      <div key={idx} className="col-12 col-lg-4">
                        <div className="invoice-per-div">
                          <p className="mb-3 fs12 cgray d-flex justify-content-between align-items-center">
                            {inv.payment_date}
                            {inv.booking?.refund ? (
                              <span className="span-tag cancel">
                                <FormattedMessage 
                                  id="cancelled.text"
                                  defaultMessage="Cancelled"
                                  description="Cancelled"
                                />
                              </span>
                            ) : (
                              <span className="span-tag complete">
                                <FormattedMessage 
                                  id="complete.text"
                                  defaultMessage="Complete"
                                  description="Complete"
                                />
                              </span>
                            )}
                          </p>
                          <p className="fs14 cblack fw600 mb-0 d-flex justify-content-between align-items-center">
                            <FormattedMessage 
                              id="leasepayment.text"
                              defaultMessage="Lease Payment"
                              description="Lease Payment"
                            />

                            {positionFinal && (
                              <span className="fw700 ">
                                {/* SAR {inv.lessor_commission} */}
                                SAR {inv.total}
                              </span>
                            )}
                            
                          </p>
                          <p className="fw400 fs12 cgray mb-0">INV-{inv.id}</p>


                          {positionFinal && (
                            <div className="invoice-detail-div">
                              <p className="fw600 fs12 cblack mb-1">
                                <FormattedMessage 
                                  id="leaseterm.text"
                                  defaultMessage="Lease Term"
                                  description="Lease Term"
                                />: 
                                <span className="fw400"> {inv.booking && translateTerm(inv.booking.lease_term)}</span>
                              </p>
                              <p className="fw600 fs12 cblack mb-1">
                                <FormattedMessage 
                                  id="startdate.text"
                                  defaultMessage="Start Date"
                                  description="Start Date"
                                />: 
                                <span className="fw400"> {inv.booking && inv.booking.booking_date}</span>
                              </p>
                              <p className="fw600 fs12 cblack mb-1">
                                <FormattedMessage 
                                  id="enddate.text"
                                  defaultMessage="End Date"
                                  description="End Date"
                                />: 
                                <span className="fw400"> {inv.booking && inv.booking.end_date}</span>
                              </p>
                            </div>
                          )}


                          <p className="d-flex fs12 cred mb-0 cur-pointer align-items-center justify-content-end mt-2"
                            onClick={() => onClickOpenDetailsHistory(position, positionFinal)}
                          >
                            {positionFinal ? 
                              intl.locale === "en" ? (
                                "Hide lease details"
                              ) : (
                                "إخفاء تفاصيل عقد الإيجا"
                              )
                             : intl.locale === "en" ? (
                                "View lease details"
                             ) : (
                              "عرض تفاصيل عقد الإيجار"
                            )}

                            {positionFinal ? (
                              <svg width="12" height="7" style={{marginLeft:"8px"}} viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.75 6.25L6 1L11.25 6.25" stroke="#FF5833" strokeLinecap="round" strokeLinejoin="round"/>
                              </svg>
                            ) : (
                              <svg width="12" height="7" style={{marginLeft:"8px"}} viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.25 0.75L6 6L0.75 0.75" stroke="#FF5833" strokeLinecap="round" strokeLinejoin="round"/>
                              </svg>
                            )}
                          </p>
                        </div>
                      </div>
                    )
                    })}
                  {/* <Pagination useFunction="getInvoiceHistory" posts={invoiceHistory} setUserPostsLoading={setInvoiceHistoryLoading} numLease={numInv} /> */}
                </>
              ) : (
                <div className="loader"></div>
              )}
              
            </div>
          </div>

        </Tab>
      </Tabs>
      <div ref={elementRef}></div>
    </div>
  )
}

export default LesseeInvoices;
