import React, { useState, useEffect, useContext, useRef } from "react";
import { generatePath, useHistory } from "react-router-dom";
import { Tab, Tabs, Dropdown  } from 'react-bootstrap';
import { RouteContext } from "../../contexts/route.context";
import {FormattedMessage, useIntl} from 'react-intl';
import Pagination from "./pagination";
import LessorLeaseSingle from "./lessorleasesingle";
import { scrollToTop, translateTerm } from "../../util/helper";
import { useIntersection } from "../../util/useIntersection";

const LessorLeases = () => {
  const intl = useIntl();
  const history = useHistory();
  const { getActiveBookings, activeBookingLoading, setActiveBookingLoading,
    getHistoryBookings, historyBookings, historyBookingLoading, setHistoryBookingLoading, } = useContext(RouteContext);
  const [key, setKey] = useState('active');
  const [numLease, setNumLease] = useState(12);
  const [pageLease, setpageLease] = useState(1);
  const [chosenPost, setChosenPost] = useState();
  const [userProducts, setUserProducts] = useState();
  const [userHistoryProducts, setUserHistoryProducts] = useState();
  const [moreLoading, setMoreLoading] = useState(false);
  const [noMorePosts, setNoMorePosts] = useState(false);

  const elementRef = useRef(null);
  const isVisible = useIntersection(elementRef, "0px 0px 70% 0px");


  useEffect(() => {
    setActiveBookingLoading(true);
    let lessorBookings = `{user_role: lessor}`;
    getBooking(lessorBookings);
    setHistoryBookingLoading(true);
    getHistory(lessorBookings)
  }, []);

  const getHistory = async (lessorBookings) => {
    let result = await getHistoryBookings(numLease, pageLease, lessorBookings)
    setUserHistoryProducts(result)
  }

  const getBooking = async (lessorBookings) =>{
    let result = await getActiveBookings(numLease, pageLease, lessorBookings);
    setUserProducts(result);
  }

  useEffect(() => {
    if (isVisible) {
    // Trigger a function when the div is visible on view port
    triggerFunc();
    }
  }, [isVisible]);

  const triggerFunc = () => {
    let lessorBookings = `{user_role: lessor}`;
    if(key === 'active'){
      if(userProducts){
        if(userProducts.paginatorInfo.hasMorePages){
          scrollPosts(lessorBookings);
        }
        else{
            setNoMorePosts(true);
        }
      }
    }
    else{
      if(userHistoryProducts){
        if(userHistoryProducts.paginatorInfo.hasMorePages){
          scrollPosts(lessorBookings);
        }
        else{
            setNoMorePosts(true);
        }
      }
    }
    
  }

  const scrollPosts = async (lessorBookings) => {
    if(key === 'active'){
      if(moreLoading == false){
        setMoreLoading(true);
        let result = await getActiveBookings(numLease, userProducts.paginatorInfo.currentPage+1, lessorBookings);
        if(userProducts.paginatorInfo.hasMorePages){
            result.data.map((dat)=>{
              userProducts.data.push(dat);
            })
            userProducts.paginatorInfo = result.paginatorInfo;
        }
        setMoreLoading(false);
      }
    }
    else{
      if(moreLoading == false){
        setMoreLoading(true);
        let result = await getHistoryBookings(numLease, userProducts.paginatorInfo.currentPage+1, lessorBookings);
        if(userHistoryProducts.paginatorInfo.hasMorePages){
            result.data.map((dat)=>{
              userHistoryProducts.data.push(dat);
            })
            userHistoryProducts.paginatorInfo = result.paginatorInfo;
        }
        setMoreLoading(false);
      }
    }
    
  }


  const onClickPost = (pt) => {
    // setChosenPost(pt);
    // console.log("HEHEHE", pt)
    // scrollToTop(0);
    let id = pt.id
    if(key === "active"){
      history.push(generatePath("/lessor-lease-single/active/:id", { id }));
    }
    else{
      history.push(generatePath("/lessor-lease-single/history/:id", { id }));
    }
  }

  // console.log(activeBookings)
  // console.log(historyBookings)

  return (
  <>
    {chosenPost ? (
      <LessorLeaseSingle post={chosenPost} />
    ) : (
      <div>
        {/* <div className="container-fluid leases-div">
          <div className="container main-wrap">
            <div className="row">
              <div className="col-12">
                <h1 className="fw800 cwhite mb-4">Renting The Den</h1>
                <a className="btn btn-orang-def">Rent Now</a>
              </div>
            </div>
          </div>
        </div> */}


        <div className="container main-wrap product-list-div">
          <div className="row">
            <div className="col-12">
              
              {/* <div className="filter-div mt-4">
                <div className="sort-div">
                  <p className="mb-0 cblack fs16">Sort by:</p>
                  <Dropdown className="ms-4">
                    <Dropdown.Toggle variant="success" className="sort-dropdown">
                      Category
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                      <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown className="ms-4">
                    <Dropdown.Toggle variant="success" className="sort-dropdown">
                      Lease Term
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                      <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown className="ms-4">
                    <Dropdown.Toggle variant="success" className="sort-dropdown">
                      Location
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                      <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="search-div">
                  <input type="text" className="form-control" />
                  <svg width="18" height="18" style={{position:"absolute", zIndex:"9", top:"11px", right:"14px"}} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M9 3.00115C7.4087 3.00115 5.88258 3.63329 4.75736 4.75851C3.63214 5.88373 3 7.40985 3 9.00115C3 10.5924 3.63214 12.1186 4.75736 13.2438C5.88258 14.369 7.4087 15.0012 9 15.0012C10.5913 15.0012 12.1174 14.369 13.2426 13.2438C14.3679 12.1186 15 10.5924 15 9.00115C15 7.40985 14.3679 5.88373 13.2426 4.75851C12.1174 3.63329 10.5913 3.00115 9 3.00115ZM1.70192e-07 9.00115C-0.000180585 7.58471 0.333964 6.18822 0.975257 4.92526C1.61655 3.66231 2.54688 2.56855 3.69059 1.73294C4.8343 0.897329 6.15909 0.343468 7.55722 0.116399C8.95534 -0.11067 10.3873 -0.00453374 11.7367 0.426175C13.0861 0.856883 14.3147 1.6 15.3227 2.59509C16.3308 3.59018 17.0897 4.80915 17.5378 6.15285C17.9859 7.49655 18.1105 8.92704 17.9015 10.328C17.6925 11.7289 17.1558 13.0607 16.335 14.2151L23.5605 21.4406C23.8337 21.7236 23.9849 22.1025 23.9815 22.4958C23.9781 22.889 23.8203 23.2653 23.5422 23.5434C23.2641 23.8215 22.8879 23.9792 22.4946 23.9827C22.1013 23.9861 21.7224 23.8349 21.4395 23.5617L14.2155 16.3377C12.869 17.2951 11.285 17.8634 9.63701 17.9803C7.98901 18.0973 6.34061 17.7583 4.87245 17.0006C3.40429 16.2429 2.17303 15.0957 1.31359 13.6847C0.45414 12.2737 -0.000321076 10.6533 1.70192e-07 9.00115Z" fill="#242424"/>
                  </svg>
                </div>
              </div> */}


              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-5 product-categories justify-content-start"
              >
                <Tab eventKey="active" title={intl.formatMessage({
                  id: 'active.text',
                  defaultMessage: 'Active',
                  description: 'Active',
                })}>

                  {!activeBookingLoading && userProducts ? 
                    userProducts.data.length === 0 ? (
                      // when no active lease
                      <div className="row mt-5">
                        <div className="col-12">
                          <div className="text-center">
                            <img src="assets/images/no-lease-img.png" className="img-fluid pt-5 mb-4" />
                            <p className="fs30 fw700 cgray">
                              <FormattedMessage 
                                id="havenoactivelease.text"
                                defaultMessage="You have no active lease"
                                description="You have no active lease"
                              />
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="row">
                        {userProducts.data.map((post, index) => {
                        
                        let pricePerTerm = post.post_lease_term.price;

                        return (
                          <div key={index} className="col-12 col-lg-6 cur-pointer" onClick={() => onClickPost(post)}>
                            <div className="lessor-lease-div">
                              <div className="col1">
                                <div className="lesle-img-div">
                                  <img src={post.user.avatar ? post.user.avatar : "assets/images/profpic.svg"} className="imgenclose" />
                                </div>
                              </div>
                              <div className="col2">
                                <p className="mt-3 fw700 fs16 cblack mb-2">{post.user.name}</p>
                                <p className="fs14 cgray mb-4">
                                  <svg width="15" height="18" style={{marginRight:"8px", marginTop:"-2px"}} viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M3.06022 2.85984C4.6356 1.28446 6.77228 0.399414 9.00021 0.399414C11.2281 0.399414 13.3648 1.28446 14.9402 2.85984C16.5156 4.43523 17.4006 6.57191 17.4006 8.79984C17.4006 11.0278 16.5156 13.1645 14.9402 14.7398L9.00021 20.6798L3.06022 14.7398C2.28011 13.9598 1.6613 13.0338 1.2391 12.0146C0.81691 10.9954 0.599609 9.90302 0.599609 8.79984C0.599609 7.69667 0.81691 6.6043 1.2391 5.5851C1.6613 4.56591 2.28011 3.63987 3.06022 2.85984ZM9.00021 11.1998C9.63673 11.1998 10.2472 10.947 10.6973 10.4969C11.1474 10.0468 11.4002 9.43636 11.4002 8.79984C11.4002 8.16332 11.1474 7.55287 10.6973 7.10279C10.2472 6.6527 9.63673 6.39984 9.00021 6.39984C8.3637 6.39984 7.75325 6.6527 7.30316 7.10279C6.85307 7.55287 6.60021 8.16332 6.60021 8.79984C6.60021 9.43636 6.85307 10.0468 7.30316 10.4969C7.75325 10.947 8.3637 11.1998 9.00021 11.1998Z" fill="#AAAAAA"/>
                                  </svg>
                                  {post.user.address}
                                </p>

                                <p className="fs14 cgray mb-2">
                                  <FormattedMessage 
                                    id="property.text"
                                    defaultMessage="Property"
                                    description="Property"
                                  />: <span className="cblack">{post.post.title}</span>
                                </p>
                                <p className="fs14 cgray mb-2">
                                  <FormattedMessage 
                                    id="leaseterm.text"
                                    defaultMessage="Lease Term"
                                    description="Lease Term"
                                  />: 
                                  <span className="cblack">{translateTerm(post.lease_term)} - SAR {pricePerTerm[0]?.price}</span>
                                </p>
                                <p className="fs14 cgray mb-2">
                                  <FormattedMessage 
                                    id="startdate.text"
                                    defaultMessage="Start Date"
                                    description="Start Date"
                                  />: <span className="cblack">{post.booking_date}</span>
                                </p>
                                <p className="fs14 cgray mb-2">
                                  <FormattedMessage 
                                    id="enddate.text"
                                    defaultMessage="End Date"
                                    description="End Date"
                                  />: <span className="cblack">{post.end_date}</span>
                                </p>
                              </div>
                              <div className="col3">
                                <span className="span-tag d-inline-block mt-3 active">
                                  <FormattedMessage 
                                    id="active.text"
                                    defaultMessage="Active"
                                    description="Active"
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        )})}

                  {moreLoading ? 
                    <div className="loader"></div>
                    :
                      null
                    }
                    {noMorePosts && userProducts && 
                    <div className="nomoreposts d-flex justify-content-center">
                        <span className="cgray fs12">
                            <FormattedMessage 
                                id="nomoreposts.text"
                                defaultMessage="No more available posts"
                                description="No more available posts"
                            />
                        </span>
                    </div>
                    }

                        {/* <Pagination useFunction="getActiveBookings" posts={activeBookings} setUserPostsLoading={setActiveBookingLoading} numLease={numLease} /> */}
                      </div>
                    )
                  : (
                    <div className="loader"></div>
                  )}


                </Tab>
                <Tab eventKey="history" title={intl.formatMessage({
                  id: 'history.text',
                  defaultMessage: 'History',
                  description: 'History',
                })}>
                  
                  {!historyBookingLoading && historyBookings ? 
                    historyBookings.data.length === 0 ? (
                      // when no active lease
                      <div className="row mt-5">
                        <div className="col-12">
                          <div className="text-center">
                            <img src="assets/images/no-lease-img.png" className="img-fluid pt-5 mb-4" />
                            <p className="fs30 fw700 cgray">
                              <FormattedMessage 
                                id="havenoactivelease.text"
                                defaultMessage="You have no active lease"
                                description="You have no active lease"
                              />
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="row">
                        {userHistoryProducts?.data.map((post, index) => {
                        
                        let pricePerTerm = post.post.post_lease_terms.filter((ls) => ls.lease_term === post.lease_term);
                        let dtToday = new Date();
                        let endDate = new Date(post.end_date);

                        return (
                          <div key={index} className="col-12 col-lg-6 cur-pointer" onClick={() => onClickPost(post)}>
                            <div className="lessor-lease-div">
                              <div className="col1">
                                <div className="lesle-img-div">
                                  <img src={post.user.avatar ? post.user.avatar : "assets/images/profpic.svg"} className="imgenclose" />
                                </div>
                              </div>
                              <div className="col2">
                                <p className="mt-3 fw700 fs16 cblack mb-2">{post.user.name}</p>
                                <p className="fs14 cgray mb-4">
                                  <svg width="15" height="18" style={{marginRight:"8px", marginTop:"-2px"}} viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M3.06022 2.85984C4.6356 1.28446 6.77228 0.399414 9.00021 0.399414C11.2281 0.399414 13.3648 1.28446 14.9402 2.85984C16.5156 4.43523 17.4006 6.57191 17.4006 8.79984C17.4006 11.0278 16.5156 13.1645 14.9402 14.7398L9.00021 20.6798L3.06022 14.7398C2.28011 13.9598 1.6613 13.0338 1.2391 12.0146C0.81691 10.9954 0.599609 9.90302 0.599609 8.79984C0.599609 7.69667 0.81691 6.6043 1.2391 5.5851C1.6613 4.56591 2.28011 3.63987 3.06022 2.85984ZM9.00021 11.1998C9.63673 11.1998 10.2472 10.947 10.6973 10.4969C11.1474 10.0468 11.4002 9.43636 11.4002 8.79984C11.4002 8.16332 11.1474 7.55287 10.6973 7.10279C10.2472 6.6527 9.63673 6.39984 9.00021 6.39984C8.3637 6.39984 7.75325 6.6527 7.30316 7.10279C6.85307 7.55287 6.60021 8.16332 6.60021 8.79984C6.60021 9.43636 6.85307 10.0468 7.30316 10.4969C7.75325 10.947 8.3637 11.1998 9.00021 11.1998Z" fill="#AAAAAA"/>
                                  </svg>
                                  {post.user.address}
                                </p>

                                <p className="fs14 cgray mb-2">
                                  <FormattedMessage 
                                    id="property.text"
                                    defaultMessage="Property"
                                    description="Property"
                                  />: <span className="cblack">{post.post.title}</span>
                                </p>
                                <p className="fs14 cgray mb-2">
                                  <FormattedMessage 
                                    id="leaseterm.text"
                                    defaultMessage="Lease Term"
                                    description="Lease Term"
                                  />: <span className="cblack">{translateTerm(post.lease_term)} - SAR {pricePerTerm[0]?.price}</span>
                                </p>
                                <p className="fs14 cgray mb-2">
                                  <FormattedMessage 
                                    id="startdate.text"
                                    defaultMessage="Start Date"
                                    description="Start Date"
                                  />: <span className="cblack">{post.booking_date}</span>
                                </p>
                                <p className="fs14 cgray mb-2">
                                  <FormattedMessage 
                                    id="enddate.text"
                                    defaultMessage="End Date"
                                    description="End Date"
                                  />: <span className="cblack">{post.end_date}</span>
                                </p>
                              </div>
                              <div className="col3">
                                {dtToday > endDate ? 
                                  post.cancel_status === null && post.deleted_at ? (
                                    <span className="span-tag d-inline-block mt-3 pending">
                                      <FormattedMessage 
                                        id="pendingcancel.text"
                                        defaultMessage="Pending cancel"
                                        description="Pending cancel"
                                      />
                                    </span>
                                  ) : post.cancel_status === "1" && post.deleted_at ? (
                                    <span className="span-tag d-inline-block mt-3 cancel">
                                      <FormattedMessage 
                                        id="cancelled.text"
                                        defaultMessage="Cancelled"
                                        description="Cancelled"
                                      />
                                    </span>
                                  ) : (
                                    <span className="span-tag d-inline-block mt-3 complete">
                                      <FormattedMessage 
                                        id="complete.text"
                                        defaultMessage="Complete"
                                        description="Complete"
                                      />
                                    </span>
                                  )
                                :
                                  post.cancel_status === null && post.deleted_at ? (
                                    <span className="span-tag d-inline-block mt-3 pending">
                                      <FormattedMessage 
                                        id="pendingcancel.text"
                                        defaultMessage="Pending cancel"
                                        description="Pending cancel"
                                      />
                                    </span>
                                  ) : post.cancel_status === "1" && post.deleted_at ? (
                                    <span className="span-tag d-inline-block mt-3 cancel">
                                      <FormattedMessage 
                                        id="cancelled.text"
                                        defaultMessage="Cancelled"
                                        description="Cancelled"
                                      />
                                    </span>
                                  ) : (
                                    <span className="span-tag d-inline-block mt-3 active">
                                      <FormattedMessage 
                                        id="active.text"
                                        defaultMessage="Active"
                                        description="Active"
                                      />
                                    </span>
                                  )
                                }
                                
                              </div>
                            </div>
                          </div>
                        )})}

                    {moreLoading ? 
                    <div className="loader"></div>
                    :
                      null
                    }
                    {noMorePosts && userHistoryProducts && 
                    <div className="nomoreposts d-flex justify-content-center">
                        <span className="cgray fs12">
                            <FormattedMessage 
                                id="nomoreposts.text"
                                defaultMessage="No more available posts"
                                description="No more available posts"
                            />
                        </span>
                    </div>
                    }


                        {/* <Pagination useFunction="getHistoryBookings" posts={historyBookings} setUserPostsLoading={setHistoryBookingLoading} numLease={numLease} /> */}
                      </div>
                    )
                  : (
                    <div className="loader"></div>
                  )}


                </Tab>
              </Tabs>


            </div>
          </div>
        </div>
      </div>
    )}
    <div ref={elementRef}></div>
  </>
  )
}

export default LessorLeases;
