import React, { useState, useEffect, useContext, useRef } from "react";
import Geocode from "react-geocode";
import { Modal, Button} from 'react-bootstrap';
import axios from "axios";
import Swal from 'sweetalert2';
import {FormattedMessage, useIntl} from 'react-intl';
import MapContainer from "./mapcontainer";
import { RouteContext } from "../../../contexts/route.context";


const LessorProfile = () => {
  const intl = useIntl();
  Geocode.setApiKey("AIzaSyDJCS46EQMFDDPG0MFD34yO651MzTCtpZ4");
  const { baseURL, apiHeaders, getMe, me, getAllSwiftCodes } = useContext(RouteContext);
  const [photoUrl, setPhotoUrl] = useState();
  const [mapShow, setMapShow] = useState(false);
  const [addressLatLng, setAddressLatLng] = useState();
  const [ibanNum, setIbanNum] = useState();
  const [addressName, setAddressName] = useState();
  
  const [swiftCodes, setSwiftCodes] = useState();
  const [userSwiftBank, setUserSwiftBank] = useState();
  const [showSwiftModal, setShowSwiftModal] = useState(false);

  const getSwift = async () => {
    setSwiftCodes(await getAllSwiftCodes());
  }

  useEffect(() => {
    getMe();
    getSwift();
  }, []);

  useEffect(() => {
    if(me) {
      if(me?.swift_code?.image){
        setUserSwiftBank(me?.swift_code)
      }
      setPhotoUrl(me.avatar);
      setAddressName(me.address);
      let ibanNumStr = me.iban_number ? me.iban_number.substring(2, 24) : "";
      let ibanFi = ibanNumStr.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
      setIbanNum(ibanFi);
    }
  }, [me]);

  

  useEffect(() => {

    if(addressLatLng) {
      Geocode.fromLatLng(addressLatLng.latVal, addressLatLng.lngVal).then(
        (response) => {
          const address = response.results[0].formatted_address;
          setAddressName(address);
        },
        (error) => {
          console.error(error);
        }
      );
    }

  }, [addressLatLng]);

  const selectNewSwiftCode = (val) => {
    // swiftCodes?.allSwiftCodes.map((data) => {
    //   if(data.id === val.value){
    //     setUserSwiftBank(data);
    //   }
    // })
    setUserSwiftBank(val);
    setShowSwiftModal(false);
  }

  const changeSwiftBankLogo = () => {
    setShowSwiftModal(true);
    // swiftCodes.allSwiftCodes.map((data) => {
    //   if(data.id === val.value){
    //     setUserSwiftBank(data.image);
    //   }
    // })
  }

  // profile photo
  const onChangeAvatar = (e) => {
    let reader = new FileReader();
    let url = reader.readAsDataURL(e.target.files[0])
    reader.onloadend = function (e) {
      setPhotoUrl([reader.result]);
    }.bind();
  }


  const onChangeIban = (e) => {
    e.target.value = e.target.value.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
    setIbanNum(e.target.value);
  }

  // map pin location
  const onPinMyLocation = () => {
    navigator.geolocation.getCurrentPosition(function(position) {
      setAddressLatLng({
        latVal: position.coords.latitude,
        lngVal: position.coords.longitude,
      })
    });
    setMapShow(false);
  }

  const onSubmitProfile = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    let formdataObj = Object.fromEntries(formData.entries());
    const query = `
      mutation($input: UpdateUserInput!) {
        updateUser(input:$input){
          name
          phone
          role {
            ar
            en
          }
          email
          gender
        }
      }
    `;

    const ibanN = ibanNum.replaceAll(' ', '');
    let ibanNumFinal = "SA" + ibanN;

    let input = {
      avatar: null,
      name: formdataObj.name,
      email: formdataObj.email,
      address: formdataObj.address,
      gender: formdataObj.gender === "female" ? `female` : `male`,
      bank_name: formdataObj.bank_name,
      // bank_account_num: formdataObj.bank_account_num,
      // bank_account_name: formdataObj.bank_account_name,
      iban_number: ibanNumFinal,
    };
    if(addressLatLng) {
      input.latitude = addressLatLng.latVal;
      input.longitude = addressLatLng.lngVal;
    }

    if(userSwiftBank.id > 0) {
      input.swift_code_id = userSwiftBank.id;
    }

    const operations = JSON.stringify({ query, variables: { input } });
    formData.append("operations", operations);

    const map = {
        "0": ["variables.input.avatar"],
    };
    formData.append("map", JSON.stringify(map));

    const file = formdataObj.avatar ? (formdataObj.avatar.name !== "" ? formdataObj.avatar : null) : null;
    formData.append("0", file);


    let result = await axios({
        url: baseURL,
        headers: apiHeaders,
        method: "post",
        data: formData
    });


    console.log(result)
    if(result.data.data) {
      Swal.fire({
        title: 'Updated Profile Complete',
        text: "",
        icon: 'success',
        confirmButtonColor: '#FF5833',
      }).then((e) => {
        window.location.reload();
      });
    }
    else {

      if(result.data.errors[0].extensions.category === "validation") {
        let errorMsg = result.data.errors[0].extensions.validation;
        let errorMsgArray = new Array();
        Object.keys(errorMsg).map((key) => {
          errorMsgArray.push(errorMsg[key][0]);
        });


        Swal.fire({
          title: 'Updated Profile Failed',
          text: errorMsgArray.toString(),
          icon: 'warning',
          confirmButtonColor: '#FF5833',
        }).then((e) => {
          window.location.reload();
        });
      }
      else {
        Swal.fire({
          title: 'Updated Profile Failed',
          text: result.data.errors[0].message,
          icon: 'warning',
          confirmButtonColor: '#FF5833',
        }).then((e) => {
          window.location.reload();
        });
      }

    }
  }


  return (
    <div>
      {
        swiftCodes && me ?
      <form className="my-profile-div" onSubmit={onSubmitProfile}>
        <div className="d-flex justify-content-between mb-3">
          <div className="position-relative">
            <div className="myprof-img-div">
              {photoUrl && (
                <img alt="" src={photoUrl} className="imgenclose"/>
              )}
            </div>
            
            <label htmlFor="photo-btn" className="btn btn-change-photo">
              <svg width="18" height="18" viewBox="0 0 20 20" fill="none" style={{position:"relative", top:"-2px"}} xmlns="http://www.w3.org/2000/svg">
                <path d="M2.5 7.50016C2.5 7.05814 2.67559 6.63421 2.98816 6.32165C3.30072 6.00909 3.72464 5.8335 4.16667 5.8335H4.94167C5.21599 5.83354 5.48608 5.76587 5.72797 5.63649C5.96987 5.50711 6.17609 5.32002 6.32833 5.09183L7.005 4.07516C7.15725 3.84697 7.36347 3.65988 7.60536 3.5305C7.84726 3.40113 8.11735 3.33346 8.39167 3.3335H11.6083C11.8827 3.33346 12.1527 3.40113 12.3946 3.5305C12.6365 3.65988 12.8428 3.84697 12.995 4.07516L13.6717 5.09183C13.8239 5.32002 14.0301 5.50711 14.272 5.63649C14.5139 5.76587 14.784 5.83354 15.0583 5.8335H15.8333C16.2754 5.8335 16.6993 6.00909 17.0118 6.32165C17.3244 6.63421 17.5 7.05814 17.5 7.50016V15.0002C17.5 15.4422 17.3244 15.8661 17.0118 16.1787C16.6993 16.4912 16.2754 16.6668 15.8333 16.6668H4.16667C3.72464 16.6668 3.30072 16.4912 2.98816 16.1787C2.67559 15.8661 2.5 15.4422 2.5 15.0002V7.50016Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M12.5 10.8335C12.5 11.4965 12.2366 12.1324 11.7678 12.6013C11.2989 13.0701 10.663 13.3335 10 13.3335C9.33696 13.3335 8.70107 13.0701 8.23223 12.6013C7.76339 12.1324 7.5 11.4965 7.5 10.8335C7.5 10.1705 7.76339 9.53457 8.23223 9.06573C8.70107 8.59689 9.33696 8.3335 10 8.3335C10.663 8.3335 11.2989 8.59689 11.7678 9.06573C12.2366 9.53457 12.5 10.1705 12.5 10.8335V10.8335Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </label>
            <input type="file" name="avatar" accept="image/*" id="photo-btn" onChange={onChangeAvatar} hidden/>
          </div>
          <button type="submit" className="btn btn-save-changes">
            <FormattedMessage 
              id="savechanges.text"
              defaultMessage="Save Changes"
              description="Save Changes"
            />
          </button>
        </div>

        <p className="mb-2 fw700 fs18 cblue">
          <FormattedMessage 
            id="myprofile.text"
            defaultMessage="My Profile"
            description="My Profile"
          />
        </p>
        <p className="mb-3 fs14 cblack">
          <FormattedMessage 
            id="manproacc.text"
            defaultMessage="Manage and protect your account"
            description="Manage and protect your account"
          />
        </p>
        <div className="div-2px mb-4" />


        <div className="profile-form-div">
          <div className="prof-per-div">
            <label className="cgray fs14 left">
              <FormattedMessage 
                id="username.text"
                defaultMessage="Username"
                description="Username"
              />
            </label>
            <label className="cblack fs14 right">{me && me.name}</label>
          </div>

          <div className="prof-per-div">
            <label className="cgray fs14 left">
              <FormattedMessage 
                id="name.text"
                defaultMessage="Name"
                description="Name"
              />
            </label>
            <div className="right">
              <input type="text" className="form-control" name="name" defaultValue={me && me.name} />
              {/* <span className="setnow">Set Now</span> */}
            </div>
          </div>

          <div className="prof-per-div">
            <label className="cgray fs14 left">
              <FormattedMessage 
                id="mobilenumber.text"
                defaultMessage="Mobile Number"
                description="Mobile Number"
              />
            </label>
            <div className="right">
              <input type="text" className="form-control" defaultValue={me && me.phone} disabled/>
              {/* <span className="setnow">Set Now</span> */}
            </div>
          </div>

          <div className="prof-per-div">
            <label className="cgray fs14 left">
              <FormattedMessage 
                id="emailadoptional.text"
                defaultMessage="Email address"
                description="Email address"
              />
            </label>
            <div className="right">
              <input type="email" className="form-control" name="email" defaultValue={me && me.email} required/>
              {/* <span className="setnow">Set Now</span> */}
            </div>
          </div>

          <div className="prof-per-div">
            <label className="cgray fs14 left">
              <FormattedMessage 
                id="address.text"
                defaultMessage="Address"
                description="Address"
              />
            </label>
            <div className="right">
              <textarea className="form-control" name="address" defaultValue={addressName ? addressName : ""} rows={2}></textarea>
              {/* <input type="text" className="form-control" name="address" defaultValue={me && me.address} /> */}
              <span className="update-pin" onClick={() => setMapShow(true)}>Update Pin Location</span>
            </div>
          </div>

          <div className="prof-per-div">
            <label className="cgray fs14 left">
              <FormattedMessage 
                id="gender.text"
                defaultMessage="Gender"
                description="Gender"
              />
            </label>
            <div className="right">
              <select className="form-select" name="gender" defaultValue={me && me.gender}>
                <option value="">
                  {intl.formatMessage({
                    id: 'selectgender.text',
                    defaultMessage: 'Select a gender',
                    description: 'Select a gender',
                  })}
                </option>
                <option value="male">
                  {intl.formatMessage({
                    id: 'male.text',
                    defaultMessage: 'Male',
                    description: 'Male',
                  })}
                </option>
                <option value="female">
                  {intl.formatMessage({
                    id: 'female.text',
                    defaultMessage: 'Female',
                    description: 'Female',
                  })}
                </option>
              </select>
              {/* <span className="setnow">Set Now</span> */}
            </div>
          </div>

          {/* BANK DETAILS */}
          {/* <div className="prof-per-div">
            <label className="cgray fs14 left">
              <FormattedMessage 
                id="bankname.text"
                defaultMessage="Bank Name"
                description="Bank Name"
              />
            </label>
            <div className="right">
              <input type="text" name="bank_name" className="form-control" defaultValue={me && me.bank_name} placeholder={intl.formatMessage({
                id: 'enterbankname.text',
                defaultMessage: "Enter bank name",
                description: "Enter bank name",
              })} required/>
            </div>
          </div> */}

          {/* <div className="prof-per-div">
            <label className="cgray fs14 left">
              <FormattedMessage 
                id="interaccnum.text"
                defaultMessage="International Bank Account Number"
                description="International Bank Account Number"
              />
            </label>
            <div className="right">
              <input type="number" name="bank_account_num" className="form-control" defaultValue={me && me.bank_account_num} placeholder={intl.formatMessage({
                id: 'interaccnum.text',
                defaultMessage: "Account number",
                description: "Account number",
              })} required/>
            </div>
          </div> */}

          {/* <div className="prof-per-div">
            <label className="cgray fs14 left">
              <FormattedMessage 
                id="bankaccname.text"
                defaultMessage="Bank Account Name"
                description="Bank Account Name"
              />
            </label>
            <div className="right">
              <input type="text" name="bank_account_name" className="form-control" defaultValue={me && me.bank_account_name} placeholder={intl.formatMessage({
                id: 'bankaccname.text',
                defaultMessage: "Enter bank account name",
                description: "Enter bank account name",
              })} required/>
            </div>
          </div> */}

          <div className="prof-per-div">
          <label className="cgray fs14 left">
              <FormattedMessage 
                id="banknamebic.text"
                defaultMessage="Bank Name (BIC)"
                description="Bank Name (BIC)"
              />
            </label>
            {
              userSwiftBank && 
              <img className="swiftbanklogo" alt="bank-logo" src={userSwiftBank.image}/>
            }
            <div className="right swiftselect">
              <input type="text" className="form-control" name="swiftcode" placeholder={intl.formatMessage({
                     id: 'selectswiftcode.text',
                     defaultMessage: 'Enter Bank Name (BIC)',
                     description: 'Enter Bank Name (BIC)',
                  })} defaultValue={me && intl.locale === "en" ? userSwiftBank?.bank_name : userSwiftBank?.bank_name_ar} onClick={()=>changeSwiftBankLogo()} readOnly/>
            {/* <select className="form-select" name="swiftcode" defaultValue={me && me?.swift_code?.id} onChange={(e)=>changeSwiftBankLogo(e.target)}>
                <option value={""} disabled={me?.swift_code?.id ? true : false}>
                  {intl.formatMessage({
                    id: 'selectswiftcode.text',
                    defaultMessage: 'Enter Bank Name (BIC)',
                    description: 'Enter Bank Name (BIC)',
                  })}
                </option>
                {
                  me?.swift_code?.id && 
                <option value={me?.swift_code?.id}>
                  
                  {intl.locale === "en" ? me?.swift_code?.bank_name?.toUpperCase() : me?.swift_code?.bank_name_ar?.toUpperCase()}
                </option>
                }
                {
                  swiftCodes?.allSwiftCodes?.map((swift, index)=>{
                    return(
                      <option key={index} value={swift.id}>
                        {intl.locale === "en" ? swift?.bank_name?.toUpperCase() : swift?.bank_name_ar?.toUpperCase()}
                      </option>
                    )
                  })
                }
              </select> */}
              {/* <input type="text" name="bank_account_name" className="form-control" defaultValue={me && me.bank_account_name} placeholder={intl.formatMessage({
                id: 'swiftcode.text',
                defaultMessage: "Swift Code",
                description: "Swift Code",
              })} /> */}
            </div>
          </div>

          <div className="prof-per-div">
            <label className="cgray fs14 left">
              <FormattedMessage 
                id="ibannum.text"
                defaultMessage="IBAN Number"
                description="IBAN Number"
              />
            </label>
            <div className="right">
              <div className="input-group iban-grp mb-3">
                <span className="input-group-text" id="basic-addon1">SA</span>
                <input type="text" maxLength={27} className="form-control iban-grp-input" name="iban_number" placeholder={intl.formatMessage({
                  id: 'ibannum.text',
                  defaultMessage: "Enter IBAN number",
                  description: "Enter IBAN number",
                })} defaultValue={ibanNum} onChange={onChangeIban} required/>
              </div>
            </div>
          </div>
        </div>
      </form>
      :
      (<div className="loader"></div>)
      }

      <Modal className="modalSwift" show={showSwiftModal} onHide={() => setShowSwiftModal(false)}>
        <Modal.Header className="flex-row-reverse justify-end">
          {/* <Modal.Title className="m-auto">
          <svg xmlns="http://www.w3.org/2000/svg" className={`m-2`} width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12.01" y2="8"></line></svg>
            <FormattedMessage 
                id="information.text"
                defaultMessage="Information"
                description="Information"
              />  
          </Modal.Title> */}
          <Button className="justify-self-end" variant="secondary" onClick={()=>setShowSwiftModal(false)}>Close</Button>
        </Modal.Header>
        <Modal.Body>
          <div className={"swiftContainer"}>
            {
              swiftCodes?.allSwiftCodes?.map((swift, index)=>{
                return(
                    <div className="swiftlist" key={index} value={swift.id} onClick={()=>selectNewSwiftCode(swift)}>
                      <img className="swiftbanklogoselect" alt="bank-logo" src={swift.image}/>
                      <p className="mytext ">{intl.locale === "en" ? swift?.bank_name?.toUpperCase() : swift?.bank_name_ar?.toUpperCase()}</p>
                    </div>
                )
              })
            }
          </div>
        </Modal.Body>
      </Modal>


      {/* map modal */}
      <Modal className="modal-map-wrapper" show={mapShow} onHide={() => setMapShow(false)}>
        <Modal.Body className="modal-postshipment-div modal-map">
          
          <div className="map-modal-div">
            <MapContainer setAddressLatLng={setAddressLatLng}/>
          </div>

          <div style={{marginTop:"30px"}}>
            <button className="btn btn-sm btn-secondary" onClick={onPinMyLocation}>Pin My Location</button>
          </div>

          <div style={{marginTop:"50px", marginBottom:"50px"}}>
            <p>
              Drag pin to your desired location
            </p>
            <button className="btn btn-primary" onClick={() => setMapShow(false)}>Pick Location</button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default LessorProfile;
