import React, { Component, useState } from 'react';
import { Map, GoogleApiWrapper, InfoWindow, Marker, AdvancedMarkerElement } from 'google-maps-react';

const LeaseMap = (props) => {
  let post = props.post;
  let fromPage = props.fromPage;
  let propStyle = props.style;
  
  let post_lat = post.latitude !== "" ? (fromPage === "myleases" ? post.post.latitude : post.latitude) : 24.6945876;
  let post_lng = post.longitude !== "" ? (fromPage === "myleases" ? post.post.longitude : post.longitude) : 46.4332919;

  const mapStyles = {
    width: '100%',
    height: '100%',
  };

  const containerStyle = props.containerStyle;
  
  
  return (
      <Map
        onClick={props.onMapClicked}
        google={props.google}
        zoom={15}
        containerStyle={containerStyle}
        style={propStyle ? propStyle : mapStyles}
        initialCenter={{ lat: post_lat, lng: post_lng}}
      >
        <Marker 
          onClick={props.onMapClicked}
          google={props.google}
          draggable={false}
        />
      </Map>
  );

}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDJCS46EQMFDDPG0MFD34yO651MzTCtpZ4'
})(LeaseMap);