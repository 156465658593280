import React, { useState, useEffect, useContext } from "react";
import { Navbar, Container, Nav, NavDropdown, Dropdown, Modal } from 'react-bootstrap';
import {FormattedMessage, useIntl} from 'react-intl';
import Cookies from 'universal-cookie';
import { RouteContext } from "../../contexts/route.context";
import logo from '../../assets/images/logo.svg';
import defaultAvatar from '../../assets/images/profpic.svg';

const Header = () => {
  const cookies = new Cookies();
  const intl = useIntl();
  let language = cookies.get("lang") ? cookies.get("lang") : "en";
  const { getMe, me, LogOutUser, } = useContext(RouteContext);
  const [showModalLogin, setShowModalLogin] = useState(false);
  const [showModalRegister, setShowModalRegister] = useState(false);
  let _urlPath = window.location.pathname.replace("/", "");

  useEffect(() => {
    getMe();
  }, []);

  // const logOutUser = () => {
    
  // }

  const handleCloseModalLogin = () => setShowModalLogin(false);
  const handleCloseModalRegister = () => setShowModalRegister(false);


  const onClickNotification = (e) => {
    window.location.href = "/notification";
  }

  const onClickChat = (e) => {
    if(me.role.id === "4") {
      window.location.href = "/lease-chat";
    }
    else {
      window.location.href = "/lessor-chat";
    }
    
  }

  const onClickSettings = () => {
    if(me.role.en === "Lessor") {
      window.location.href = "/lessor-settings";
    }
    else if(me.role.en === "Lessee") {
      window.location.href = "/lessee-settings";
    }
  }

  const onClickMyPost = () => {
    window.location.href = "/my-lessor-profile";
  }

  const onClickBookings = () => {
    window.location.href = "/my-leases";
  }

  const onChangeLang = (e, lang) => {
    e.preventDefault();
    cookies.set('lang', lang);
    window.location.reload();
  }

  const onClickLoginModal = () => {
    setShowModalLogin(true);
    localStorage.removeItem('login_booked_id');
  }

  // console.log(me)


  return (
  <div className="header-div">
    <div className="header-up">
      <div className="container main-wrap">
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic" className="lang-dropdown">
            {language === "en" ? (
            <>
              <svg width="20" height="20" style={{marginRight: "10px", marginLeft:"10px", marginTop:"-3px"}} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.5 4H24L23.5 4.9L24 5.8V7.6L23.5 8.5L24 9.4V11.2L23.5 12.1L24 13V14.8L23.5 15.7L24 16.6V18.4L23.5 19.3L24 20.2H0L0.5 19.3L0 18.4V16.6L0.5 15.7L0 14.8V13L0.5 12.1L0 11.2L10.5 4Z" fill="#D6062F"/>
                <path d="M0 18.3999H24V20.1999H0V18.3999Z" fill="white"/>
                <path d="M0 14.8003H24V16.6003H0V14.8003Z" fill="white"/>
                <path d="M0 11.2002H24V13.0002H0V11.2002Z" fill="white"/>
                <path d="M10.5 7.59961H24V9.39961H10.5V7.59961Z" fill="white"/>
                <path d="M10.5 4H24V5.8H10.5V4Z" fill="white"/>
                <path d="M0 4H10.5V11.2H0V4Z" fill="#2956B0"/>
                <path d="M1.75844 4.42188L1.91719 4.86161H2.43094L2.01529 5.13341L2.17409 5.57315L1.75844 5.30135L1.34279 5.57315L1.50159 5.13341L1.08594 4.86161H1.59969L1.75844 4.42188Z" fill="white"/>
                <path d="M4.08559 4.42188L4.24434 4.86161H4.75808L4.34243 5.13341L4.50123 5.57315L4.08559 5.30135L3.66999 5.57315L3.82874 5.13341L3.41309 4.86161H3.92684L4.08559 4.42188Z" fill="white"/>
                <path d="M6.41371 4.42188L6.57246 4.86161H7.08621L6.67061 5.13341L6.82936 5.57315L6.41371 5.30135L5.99811 5.57315L6.15686 5.13341L5.74121 4.86161H6.25496L6.41371 4.42188Z" fill="white"/>
                <path d="M8.74085 4.42188L8.8996 4.86161H9.41335L8.99775 5.13341L9.1565 5.57315L8.74085 5.30135L8.32526 5.57315L8.48401 5.13341L8.06836 4.86161H8.5821L8.74085 4.42188Z" fill="white"/>
                <path d="M1.75844 6.15723L1.91719 6.59697H2.43094L2.01529 6.86872L2.17409 7.30846L1.75844 7.03671L1.34279 7.30846L1.50159 6.86872L1.08594 6.59697H1.59969L1.75844 6.15723Z" fill="white"/>
                <path d="M4.08559 6.15723L4.24434 6.59697H4.75809L4.34244 6.86872L4.50124 7.30846L4.08559 7.03671L3.66999 7.30846L3.82874 6.86872L3.41309 6.59697H3.92684L4.08559 6.15723Z" fill="white"/>
                <path d="M6.41371 6.15723L6.57246 6.59697H7.08621L6.67061 6.86872L6.82936 7.30846L6.41371 7.03671L5.99811 7.30846L6.15686 6.86872L5.74121 6.59697H6.25496L6.41371 6.15723Z" fill="white"/>
                <path d="M8.74085 6.15723L8.8996 6.59697H9.41335L8.99775 6.86872L9.1565 7.30846L8.74085 7.03671L8.32526 7.30846L8.48401 6.86872L8.06836 6.59697H8.5821L8.74085 6.15723Z" fill="white"/>
                <path d="M1.75844 7.89209L1.91719 8.33183H2.43094L2.01529 8.60358L2.17409 9.04332L1.75844 8.77157L1.34279 9.04332L1.50159 8.60358L1.08594 8.33183H1.59969L1.75844 7.89209Z" fill="white"/>
                <path d="M4.08559 7.89258L4.24434 8.33232H4.75809L4.34244 8.60407L4.50124 9.04381L4.08559 8.77206L3.66999 9.04381L3.82874 8.60407L3.41309 8.33232H3.92684L4.08559 7.89258Z" fill="white"/>
                <path d="M6.41371 7.89209L6.57246 8.33183H7.08621L6.67061 8.60358L6.82936 9.04332L6.41371 8.77157L5.99811 9.04332L6.15686 8.60358L5.74121 8.33183H6.25496L6.41371 7.89209Z" fill="white"/>
                <path d="M8.74085 7.89258L8.8996 8.33232H9.41335L8.99775 8.60407L9.1565 9.04381L8.74085 8.77206L8.32526 9.04381L8.48401 8.60407L8.06836 8.33232H8.5821L8.74085 7.89258Z" fill="white"/>
                <path d="M1.75844 9.62695L1.91719 10.0667H2.43094L2.01529 10.3385L2.17409 10.7782L1.75844 10.5064L1.34279 10.7782L1.50159 10.3385L1.08594 10.0667H1.59969L1.75844 9.62695Z" fill="white"/>
                <path d="M4.08559 9.62695L4.24434 10.0667H4.75809L4.34244 10.3385L4.50124 10.7782L4.08559 10.5064L3.66999 10.7782L3.82874 10.3385L3.41309 10.0667H3.92684L4.08559 9.62695Z" fill="white"/>
                <path d="M6.41371 9.62695L6.57246 10.0667H7.08621L6.67061 10.3385L6.82936 10.7782L6.41371 10.5064L5.99811 10.7782L6.15686 10.3385L5.74121 10.0667H6.25496L6.41371 9.62695Z" fill="white"/>
                <path d="M8.74085 9.62695L8.8996 10.0667H9.41335L8.99775 10.3385L9.1565 10.7782L8.74085 10.5064L8.32526 10.7782L8.48401 10.3385L8.06836 10.0667H8.5821L8.74085 9.62695Z" fill="white"/>
              </svg>
              English
            </>
            ) : (
            <>
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" style={{marginRight:"10px", marginLeft:"10px", marginTop:"-3px"}} id="flag-icons-sa" viewBox="0 0 640 480">
                <defs>
                  <clipPath id="sa-a">
                    <path fillOpacity=".7" d="M-85.3 0h682.6v512H-85.3z"/>
                  </clipPath>
                </defs>
                <g fillRule="evenodd" clipPath="url(#sa-a)" transform="translate(80) scale(.9375)">
                  <path fill="#199d00" d="M-128 0h768v512h-768z"/>
                  <path fill="#fff" d="M65.5 145.1c-.8 12-2 33 8.3 35.2 12.3 1.2 5.5-20.8 10-24.8.8-2 2.3-2 2.4.5v18.7c0 6 4 7.8 7 9 3.2-.2 5.4 0 6.6 3l1.6 32.3s7.4 2.2 7.8-18.1c.3-12-2.4-21.9-.8-24.2 0-2.3 3-2.4 5-1.3 3.2 2.2 4.6 5 9.6 4 7.6-2.2 12.2-5.9 12.3-11.7a47 47 0 0 0-3.5-16.6c.4-1-1.4-3.7-1-4.7 1.3 2.2 3.4 2 3.8 0-1.3-4.2-3.3-8.3-6.5-10-2.7-2.4-6.7-2-8 3-.8 5.7 2 12.4 6.1 18 .9 2.1 2.1 5.7 1.6 8.9-2.2 1.3-4.4.7-6.3-1.2 0 0-6-4.5-6-5.6 1.6-10.2.3-11.4-.6-14.3-.6-3.9-2.5-5.2-4-7.8-1.5-1.6-3.5-1.6-4.5 0-2.7 4.6-1.4 14.5.5 19 1.4 4.1 3.5 6.7 2.5 6.7-.8 2.3-2.5 1.7-3.8-1a66.6 66.6 0 0 1-2.1-17.4c-.5-4.6-1.1-14.4-4.2-17-1.8-2.4-4.5-1.2-5.5 1a82.4 82.4 0 0 0 .3 13.4c2 7.4 2.7 14 3.7 21.5.3 10.1-5.8 4.4-5.5-.7a45 45 0 0 0-.3-19.4c-1-2.6-2.1-3.2-4.6-2.8-1.9 0-6.8 5.3-8.2 14.3 0 0-1.2 4.6-1.7 8.7-.7 4.6-3.7 8-5.9-.6-1.8-6.3-3-21.6-6-18z"/>
                  <path fill="#fff" d="m99 194.2-32 15.4c.3-7.3 15.1-20.4 25.3-20.5 6.5.1 4.9 2.5 6.6 5.1z"/>
                  <path fill="#fff" d="M93.3 204.2c-16.8 43.5 39.5 49.6 45.8 1.8.6-2 3-3.9 3.4-.7-1.3 43.3-43.6 46.2-50.8 32.6a41.9 41.9 0 0 1-2.5-14.6c-1-8.5-5.5-5.2-6.2 3.2-.7 4.7-.5 6-.5 10.5 2.2 34.2 56.7 19.5 65.6-8.7 4.7-15.6-.8-27.1 1.7-27.1 5.4 5.8 13 .8 14.7-1.2.7-1 2.5-1.7 3.7-.4 4.2 3 11.6 1.6 13.2-3.7.9-5.3 1.6-10.7 1.8-16.2-3.5 1-6 1.7-6.3 3.2l-.7 4.6c-.3 1.5-3.2 1.5-3.4-.4-1.3-6-6.7-6.7-10 2.5-2.1 1.8-6.1 2.2-6.5-.5.5-6.2-2-7-7-4.1l-4.8-36.2c2 0 4 1.5 5.9-.9-2-6.5-6.5-19.7-9-20.7-1.1-1.4-2.1-.5-3.7-.1-2.6.8-5 3-4.2 7.4 3 18.8 5 33.1 8.1 52 .5 2.1-1.3 5-3.7 4.7-4-2.7-5-8.2-12-8-5 0-10.6 5.5-11.3 10.7-.9 4.2-1.2 8.7 0 12.3 3.5 4.2 7.7 3.8 11.4 2.9 3-1.3 5.5-4.3 6.6-3.6.7.9.1 10.9-14.3 18.5-8.7 4-15.7 4.8-19.4-2.3-2.3-4.5.2-21.4-5.6-17.5z"/>
                  <path fill="#fff" d="M165 160c3.3-1.2 19.3-19.6 19.3-19.6l-2.4-2c-.9-.7-.8-1.5 0-2.2 4-2.4 2.7-7.4.7-9.8a9.7 9.7 0 0 0-8.7.1c-2.8 2.7-3.4 7-1.2 9.6 2.1 1 4.2 3.2 2.8 4.4-6.6 7-24.5 19.1-22.4 19.5.4.6 11.5.6 11.8 0zm-97 65c-6 9.6-6.5 23.9-3.2 28.2 1.8 2 4.7 2.9 6.8 2.2 3.8-1.6 5.5-9.3 4.6-12-1.3-2-2.3-2.3-3.6-.7-2.6 5.4-3.7 1.7-4-1.3a70 70 0 0 1 .8-15.2c.7-4.2 0-3-1.4-1.2zm257.1-15.3c-5.8-12.6-13.9-25-16.4-29.7a557.6 557.6 0 0 0-24.8-36c-6.2-7.4 10.2 3.1-2-11.7l-8.9-7.5c-2-1.4-6.8-4-7.6.2-.4 3.8-.2 5.8.4 8.9.5 2 3.5 5.5 5 7.5a565 565 0 0 1 53.8 86.5c2.6-1.3 2-16.1.5-18.2z"/>
                  <path fill="#fff" d="M299.6 251.5c-1.2 1.3 2.8 6.8 8 6.8 8.6-1 16.2-5.8 23.2-18.6a33 33 0 0 0 5.3-14.2 317 317 0 0 0-5.8-72.4c-.3-2 0-4.4.2-5 .6-.7 2.5 0 3.5-1.7 1.5-1.5-4-14-7-18.7-1-2.2-1.5-3.6-3.3.2a27 27 0 0 0-3 13.6c4.1 28.5 5.4 53.4 8 81.9.3 2.8-.1 6.8-2 8.4a80.2 80.2 0 0 1-27.1 19.7zm116.5-.1c-6.2 3.6-6.2 7.7-1.2 7.8 8.6-1 18.8-1.7 25.8-12.3a41 41 0 0 0 4.2-16 303 303 0 0 0-4.7-71.4c-.2-2-1.1-6.7-.8-7.3.6-1.4 3.4.1 4.4-1.5 1.4-1.5-7.3-12.7-10.4-17.5-1-2.2-1.4-3.6-3.3.2a22.3 22.3 0 0 0-1.8 13.6c4.6 31 8 54.2 8.7 81.6-.4 2.6-.5 4-1.7 7.3-2.7 3.4-5.7 7.8-8.5 9.9-2.8 2-8.8 4-10.7 5.6z"/>
                  <path fill="#fff" d="M420.7 223.7c0-7.3.1-13.5-.1-19a34 34 0 0 0-3-13.5c-1.8-4.1-.7-7.4-1.6-11.8-.8-4.4-.6-11-1.8-16.1-.4-2-1.4-8.5-1.1-9.2.5-1.4 2.4 0 3.4-1.6 1.4-1.5-5-18-8.2-22.7-1.1-2.1-3.3-1.4-5.8 2-2.5 2.3-1.6 7.4-.6 12.3 6.1 32.3 10.8 61.6 9.8 92.3-.4 2.6 9-7.8 9-12.7zm-45.7-40c-3.9-.2-12-7.7-14.4-12a8 8 0 0 1 .4-6.5c1.5-1 3.7-2 5.4-1 0 0 1.7 2.4 1.4 2.7 2 1 3 .5 3.2-.4.1-1.5-.6-2.4-.6-4 .9-4.6 6-5.3 8-2.4 1.4 1.8 2 5.5 2.1 8 0 1.3-2-.2-3.3 0-1.1.4-1.4 1.8-1.5 3-.2 3.3-.6 8.6-.7 12.5zm-71.8 48c1-9.8-.4-27.3-.5-33.1A477 477 0 0 0 299 154c-1.2-8.4 3.4.9 2.8-4-1.5-8.3-6.1-14-11.6-21.5-1.7-2.5-1.7-3-4.4.6-3 6.7-.4 11.4.4 16.7 3.9 17.2 6.2 33 7.3 48.7a393.4 393.4 0 0 1 .4 49c3 .1 7.6-4.7 9.3-11.8z"/>
                  <path fill="#fff" d="M434 216c-6.9-11.6-17.2-24-20-28.7a658 658 0 0 0-29.2-37.8c-8.5-9 4-1.5-1.6-8.5-4.7-5.1-6-6.8-10.1-9.9-2-1.3-3.2-3.8-4 .5a83 83 0 0 0-.2 11.2c0 1.7 1.8 5 3.4 7 20.7 25.5 43.4 51.5 61.6 84.2 2.6-1.3 1.7-16 0-18z"/>
                  <path fill="#1ba400" d="M122.6 194.7c-.5.9-1.6 2-1.2 3.1.7 1 1.4 1.3 2.6 1.3 1.1 0 2.7.3 3-.3.6-.7 1-2 .6-3.3-1.2-3-4.4-1.8-5-.8z"/>
                  <path fill="#fff" d="M354.2 362.5c9.2.4 15.2.5 23.3 1.4l9.6-1c10.6-1 11 15.1 11 15.1 0 9.5-3.7 10-8.4 11-2.7.4-4-1.6-5.5-3.6a14 14 0 0 1-7 .4c-3.9-.2-7.7-.2-11.5-.5-4-.3-6.2.5-10.3.1-.8 1.3-2 3.1-4.4 2.6-2-.3-4.5-6-3.8-10.5 1.5-3.2 1-2.1 1-3.5-37.6-1-75.5-2.7-112.3-2.2-28.8.1-57.2 1.3-85.7 2.5-15.2-.2-26.8-2.6-34.8-14.3.8 0 38.8 2.1 49.9 1.4 20.5-.2 39.3-1.9 60.2-2.5 41.2.7 82.1.7 123.3 3.6-4-2.7-4-9 2-10.6.5-.4.8 3.1 1.7 3 4.9-.3 2.7 6.3 1.7 7.6zM188.6 135.3c-6.2 17.8 3.6 37.4 10.4 35.5 5 2 8-7.4 10-17.6 1.5-2.9 2.5-3.2 3.2-1.7-.2 13.6 1 16.7 4.5 20.8 7.8 6 14.3.8 14.8.3l6-6.1c1.4-1.5 3.2-1.5 5.1-.3 1.9 1.7 1.6 4.6 5.6 6.6 3.4 1.4 10.5.4 12.2-2.5 2.2-3.9 2.8-5.2 3.8-6.6 1.6-2.1 4.3-1.2 4.3-.5-.3 1.2-1.9 2.3-.8 4.5 2 1.4 2.4.5 3.5.2 4-2 7-10.6 7-10.6.1-3.2-1.7-3-2.9-2.2l-3.1 2.1c-2 .3-5.7 1.6-7.6-1.3-1.9-3.4-1.9-8.3-3.3-11.8 0-.2-2.6-5.5-.2-5.8 1.2.2 3.7.9 4.1-1.2 1.2-2.1-2.6-8-5.3-11-2.3-2.5-5.5-2.8-8.6-.2-2.2 2-1.9 4.2-2.3 6.3a9.8 9.8 0 0 0 2 8.7c2.2 4.2 6.1 9.7 4.8 17.5 0 0-2.3 3.6-6.3 3.1-1.7-.3-4.4-1-5.8-11.8-1.1-8 .2-19.4-3.2-24.7-1.3-3.3-2.2-6.4-5.2-.9-.8 2.2-4.3 5.5-1.8 12.2a36 36 0 0 1 2 19c-1.5 2.2-1.8 2.9-3.7 5-2.6 3-5.5 2.2-7.7 1.1-2-1.3-3.6-2-4.6-6.5.2-7 .6-18.5-.7-20.9-1.9-3.8-5-2.4-6.3-1.2a47.7 47.7 0 0 0-11.5 23.5c-1.8 5.8-3.7 4.1-5 1.8-3.2-3-3.5-26.7-7.4-22.8z"/>
                  <path fill="#fff" d="M207.4 174.1c2.9-2 1.6-3.4 5.8.8a72 72 0 0 1 9.2 31.3c-.2 2.6 1.6 4.2 2.5 3.6.4-6 15.1-14.4 28.6-15.6 2-.5 1-4.4 1.3-6.4-.8-7.5 4.2-14.3 11.2-14.8 9.6 1.4 12.8 6.5 13 14.2-1.1 15-16.7 17.5-25.4 18.7-1.3.5-1.9 1.1 0 1.8l36.6.2 1.9 1c.2 1-.6.2-2 2.6a29.5 29.5 0 0 0-3.7 11.5c-10.9 3.6-22.2 5-33.6 6.5-4 2-6 4.7-5.2 7.7 1.4 3.3 10.2 6.7 10.2 6.8 1.7 1 3.6 3.5-.5 8.6-17.8-.8-31.7-8.4-36.5-19.1-1.4-1.1-3 0-4 1.4-7 9-13.8 17-25.7 21.4-7 1.8-14.3-1.1-17.7-5.7-2.3-2.7-2.2-5.6-3-6.2-3.9 1.7-36.9 15.7-32.7 9.1 8-8.5 22-14.9 34.2-23.3.9-2.9 2.5-12.5 7.3-15.6.3 0-.7 5.6-.6 8 0 2-.2 2.7.2 2.2.9-.5 15.7-12.2 17-15.8 1.4-2 .3-7.2.3-7.4-2.8-7.2-6.7-7.8-8.1-11.4-1.3-4.7-.7-10.1 2-11.7 2.4-2.1 5.2-1.9 7.9.5 3 2.7 5.6 8 6.4 11.9-.5 1.5-4-1-5-.3a16 16 0 0 1 3.7 7.8c2 8.2 1.4 11.4-.6 16.7-6.6 13.9-15 18-22.4 23.2-.2 0-.3 3.5 2.4 5.4 1 1 4.9 1.5 9.4 0a54.5 54.5 0 0 0 22.3-23.3 51 51 0 0 0-2.4-22.2c-2.9-6.7-6.3-16.2-6.3-16.4-.1-4.2.2-5.6 2-7.7zm-95.8-38.6c4.2 2 12.2 1.1 11.8-5.7l-.2-3.1c-.8-2-3.2-1.5-3.7.5-.2.7.3 1.8-.3 2.1-.4.4-1.7.2-1.7-1.7 0-.6-.4-1.2-.7-1.6-.2-.2-.4-.2-.9-.2-.6 0-.6.1-.9.6-.1.5-.3 1-.3 1.6 0 .7-.4.9-.8 1-.6 0-.5 0-1-.2-.2-.3-.5-.4-.5-1l-.3-1.6c-.2-.3-.6-.5-1-.6-2.3 0-2.5 2.7-2.3 3.7-.2.2-.3 4.9 2.8 6.2z"/>
                  <path fill="#fff" d="M235.1 187.7c4.2 2 14.3.9 11.8-5.6l-.2-3.2c-.9-2-3.2-1.5-3.7.6-.2.6.3 1.7-.4 2-.3.4-1.7.2-1.6-1.6 0-.6-.4-1.3-.7-1.7-.3-.1-.4-.2-1-.2-.5 0-.5.2-.8.7-.2.5-.3 1-.3 1.6-.1.6-.4.8-.9 1-.5 0-.4 0-.8-.3-.3-.3-.6-.4-.6-.9l-.3-1.6c-.2-.3-.6-.5-1-.6-2.3 0-2.5 2.6-2.4 3.6-.1.2-.2 5 3 6.2zm72-21.6c4.2 2 12.1 1.1 11.8-5.6l-.2-3.2c-.9-2-3.2-1.5-3.7.5-.2.7.3 1.8-.4 2.2-.3.3-1.7.1-1.6-1.8 0-.6-.4-1.2-.7-1.6-.3-.2-.4-.2-1-.2-.5 0-.5.2-.8.7l-.3 1.5c-.1.7-.4 1-.9 1s-.4 0-.8-.2c-.3-.3-.6-.4-.6-.9s-.1-1.3-.3-1.7c-.2-.3-.6-.4-1-.5-2.3 0-2.5 2.6-2.4 3.6-.1.2-.2 4.9 3 6.2zm37.3 54.3c-7.3 8.3-4.1 22-2.4 25 2.4 4.8 4.3 7.9 9 10.3 4.3 3.1 7.7 1.2 9.5-1 4.3-4.5 4.4-16 6.4-18.2 1.4-4.2 5-3.5 6.7-1.6a16.5 16.5 0 0 0 6.2 5.3c4 3.5 8.8 4.2 13.6 1 3.2-1.9 5.3-4.2 7.2-8.9 2-5.6 1-31.6.5-47l-4.2-21.5c0-.2-.5-10.2-1-12.5 0-1-.3-1.3.7-1.2 1.1 1 1.2 1 2 1.3 1 .2 2-1.7 1.3-3.3l-10-18.6c-.8-.8-1.9-1.6-3.2.2a7.3 7.3 0 0 0-2.4 5.5c.3 4.4 1 8.9 1.3 13.3l4 22.6c1.3 16 1.6 29.2 2.9 45.3-.2 6.8-2.3 12.7-4.3 13.6 0 0-3 1.7-5-.2-1.5-.6-7.4-9.9-7.4-9.9-3-2.7-5-2-7.1 0-6 5.8-8.6 16.4-12.7 23.8-1 1.7-4 3-7.2-.1-8.2-11.3-3.4-27.3-4.4-23.2zM309 126.7c3.8 1.5 6.4 9.2 5.6 13-.8 4.5-2.8 9.5-4.2 8.9-1.6-.6 1-4.6-.5-8.8-.8-2.8-6-7.8-5.4-9.2-1-3.1 2.2-4.5 4.5-4z"/>
                  <path fill="#fff" d="M356.6 225c.7-9.2-.6-14.8-.8-20.2s-6.1-46.6-7.3-50.6c-1.5-7.8 5.7-1 4.9-5.6-2.5-5.6-8.6-13.9-10.5-18.8-1.2-2-.7-4-3.3-.5a42.3 42.3 0 0 0-2.3 19.2c6.2 32.3 12.5 59.1 11.5 89.8 3 0 6.3-6.7 7.8-13.3zm64.4-85.3c3.5 1.7 5.5 11.3 5.1 14-.7 5-2.5 10.4-3.8 9.7-1.5-.6.3-7.4-.4-9.5-.8-3-5.5-8.4-5-10-1-3.4 2-4.8 4.1-4.2zm-255.7 67.9c3.3 1.3 5.3 8.3 5 10.3-.8 3.7-2.5 7.7-3.8 7.1-1.3-.4.3-5.4-.3-7-.3-3.7-4.9-5.7-4.8-7.3-.8-3 2-3.5 4-3.1z"/>
                  <path fill="#1b9d00" d="M244.9 218.2c4.2.2 6.3 3.6 2.4 5-4 1.3-7.7 2.4-7.8 8 1.5 8-2 5.2-4 4.2-2.4-1.8-9.2-6-10.2-15-.1-2.1 1.6-4 4.3-4 4 1.1 10 1.2 15.3 1.8z"/>
                  <path fill="#fff" d="M77.4 124.4c4.8 1.4 5.1 8.6 4.8 10.7-.7 3.8-2.4 7.9-3.6 7.4-1.4-.5 0-5.7-.7-7.3-.7-2.2-4.8-6.4-4.4-7.6-.9-2.5 2-3.7 3.9-3.2zm95.9 33.6c-3.8 2-5.2 8-2.9 11.6 2.2 3 5.6 1.9 6 1.9 3.7.4 5.9-6.9 5.9-6.9s.1-2-4.2 1.9c-1.9.3-2-.4-2.5-1.4a9 9 0 0 1 .5-5.7c.7-1.8-.7-2.6-2.8-1.4zm28-36.4c-2 1.3-5.7 5.2-5.8 9.6-.1 2.5-.6 2.5 1 4 1.3 1.8 2.4 1.7 4.8.4a5.1 5.1 0 0 0 2.3-3.4c.6-2.8-3 1.4-3.4-1.8-.8-3 1.5-4.2 3.7-7 0-2 0-3.3-2.7-1.8zm22.4 4a59.5 59.5 0 0 0-1.6 11.1c-.6 2.8 3 4 4.5.4 2.4-6.5 2.4-9.3 2.6-12-.7-4.3-3.6-4.2-5.5.5zm142 72.3c.4-.5 20-14.4 20-14.4 2-.7 1.5 7.2.6 7.1a77.8 77.8 0 0 1-20.7 14.3c-1 .7-1.9-5.3 0-7zm17.7-.2c3.5 1.7 4.9 11.8 4.5 14.5 0 5.4-3.3 9.6-4.7 9-1.4-.7.2-6.7-.5-8.8-.8-3-3.7-8.5-3.2-10.1-1-3.4 1.8-5.2 4-4.6zm-116 43.4a26 26 0 0 1 5.6-4.9c2-1 3.8.8 3.7.7.3 2-1.2 3.7-.7 6.3.4 1 .7 2.2 2.6 1.8 3.1-2.5 6-2.7 9-2.8 2.5.1 2.6 4.2 1 4.2-5.7 1.2-8.2 2.8-12.3 4.3-2 1.2-3.6-.3-3.6-.4s-1.1-1.1-.4-3.7c.2-2-.6-3.2-2.4-3-1.2.8-2.4 1.2-3-.3-.3-1-.4-1.6.5-2.2zm136.6 5.4c.8 1 1.4 2-.1 3.8l-3.7 3.2c-.6 1-1 2.8 1 3.3 3.6 1 12-4.5 12-4.6 1.4-1 1-3 .8-3-.8-.9-2.6-.3-3.8-.5-.6 0-2.5-.2-1.6-2a11.4 11.4 0 0 0 1.6-2.9c.5-1.2 0-2-2-2.7-2.1-.4-3-.2-5.3 0-1.2.2-1.6.8-1.9 2.3.1 2.3 1.5 2.2 3 3z"/>
                  <path fill="#259f00" d="M268.1 189.7c-.5 1-2.3 1-4 0s-2.7-2.6-2.1-3.5 2.3-.9 4 0 2.6 2.6 2.1 3.5zm-89-53.6c-1 .3-2.4-.6-3-2s-.3-2.6.7-2.9 2.3.7 3 2 .3 2.7-.8 3z"/>
                  <path fill="#209000" d="M355.2 375c9.4.4 18.2 0 27.5.5 1.7 1.5.5 5-.6 4.8l-7.8-.3c-.1-3-7.7-2.5-7.5.1-4.1.5-7.8-.1-12-.3-1.2-1.5-1-4.2.4-4.8z"/>
                </g>
              </svg>
              Arabic
            </>
            )}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item href="#/action-1" onClick={(e) => onChangeLang(e, "en")}>
              <svg width="20" height="20" style={{marginRight:"10px", marginLeft:"10px", marginTop:"-3px"}} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.5 4H24L23.5 4.9L24 5.8V7.6L23.5 8.5L24 9.4V11.2L23.5 12.1L24 13V14.8L23.5 15.7L24 16.6V18.4L23.5 19.3L24 20.2H0L0.5 19.3L0 18.4V16.6L0.5 15.7L0 14.8V13L0.5 12.1L0 11.2L10.5 4Z" fill="#D6062F"/>
                <path d="M0 18.3999H24V20.1999H0V18.3999Z" fill="white"/>
                <path d="M0 14.8003H24V16.6003H0V14.8003Z" fill="white"/>
                <path d="M0 11.2002H24V13.0002H0V11.2002Z" fill="white"/>
                <path d="M10.5 7.59961H24V9.39961H10.5V7.59961Z" fill="white"/>
                <path d="M10.5 4H24V5.8H10.5V4Z" fill="white"/>
                <path d="M0 4H10.5V11.2H0V4Z" fill="#2956B0"/>
                <path d="M1.75844 4.42188L1.91719 4.86161H2.43094L2.01529 5.13341L2.17409 5.57315L1.75844 5.30135L1.34279 5.57315L1.50159 5.13341L1.08594 4.86161H1.59969L1.75844 4.42188Z" fill="white"/>
                <path d="M4.08559 4.42188L4.24434 4.86161H4.75808L4.34243 5.13341L4.50123 5.57315L4.08559 5.30135L3.66999 5.57315L3.82874 5.13341L3.41309 4.86161H3.92684L4.08559 4.42188Z" fill="white"/>
                <path d="M6.41371 4.42188L6.57246 4.86161H7.08621L6.67061 5.13341L6.82936 5.57315L6.41371 5.30135L5.99811 5.57315L6.15686 5.13341L5.74121 4.86161H6.25496L6.41371 4.42188Z" fill="white"/>
                <path d="M8.74085 4.42188L8.8996 4.86161H9.41335L8.99775 5.13341L9.1565 5.57315L8.74085 5.30135L8.32526 5.57315L8.48401 5.13341L8.06836 4.86161H8.5821L8.74085 4.42188Z" fill="white"/>
                <path d="M1.75844 6.15723L1.91719 6.59697H2.43094L2.01529 6.86872L2.17409 7.30846L1.75844 7.03671L1.34279 7.30846L1.50159 6.86872L1.08594 6.59697H1.59969L1.75844 6.15723Z" fill="white"/>
                <path d="M4.08559 6.15723L4.24434 6.59697H4.75809L4.34244 6.86872L4.50124 7.30846L4.08559 7.03671L3.66999 7.30846L3.82874 6.86872L3.41309 6.59697H3.92684L4.08559 6.15723Z" fill="white"/>
                <path d="M6.41371 6.15723L6.57246 6.59697H7.08621L6.67061 6.86872L6.82936 7.30846L6.41371 7.03671L5.99811 7.30846L6.15686 6.86872L5.74121 6.59697H6.25496L6.41371 6.15723Z" fill="white"/>
                <path d="M8.74085 6.15723L8.8996 6.59697H9.41335L8.99775 6.86872L9.1565 7.30846L8.74085 7.03671L8.32526 7.30846L8.48401 6.86872L8.06836 6.59697H8.5821L8.74085 6.15723Z" fill="white"/>
                <path d="M1.75844 7.89209L1.91719 8.33183H2.43094L2.01529 8.60358L2.17409 9.04332L1.75844 8.77157L1.34279 9.04332L1.50159 8.60358L1.08594 8.33183H1.59969L1.75844 7.89209Z" fill="white"/>
                <path d="M4.08559 7.89258L4.24434 8.33232H4.75809L4.34244 8.60407L4.50124 9.04381L4.08559 8.77206L3.66999 9.04381L3.82874 8.60407L3.41309 8.33232H3.92684L4.08559 7.89258Z" fill="white"/>
                <path d="M6.41371 7.89209L6.57246 8.33183H7.08621L6.67061 8.60358L6.82936 9.04332L6.41371 8.77157L5.99811 9.04332L6.15686 8.60358L5.74121 8.33183H6.25496L6.41371 7.89209Z" fill="white"/>
                <path d="M8.74085 7.89258L8.8996 8.33232H9.41335L8.99775 8.60407L9.1565 9.04381L8.74085 8.77206L8.32526 9.04381L8.48401 8.60407L8.06836 8.33232H8.5821L8.74085 7.89258Z" fill="white"/>
                <path d="M1.75844 9.62695L1.91719 10.0667H2.43094L2.01529 10.3385L2.17409 10.7782L1.75844 10.5064L1.34279 10.7782L1.50159 10.3385L1.08594 10.0667H1.59969L1.75844 9.62695Z" fill="white"/>
                <path d="M4.08559 9.62695L4.24434 10.0667H4.75809L4.34244 10.3385L4.50124 10.7782L4.08559 10.5064L3.66999 10.7782L3.82874 10.3385L3.41309 10.0667H3.92684L4.08559 9.62695Z" fill="white"/>
                <path d="M6.41371 9.62695L6.57246 10.0667H7.08621L6.67061 10.3385L6.82936 10.7782L6.41371 10.5064L5.99811 10.7782L6.15686 10.3385L5.74121 10.0667H6.25496L6.41371 9.62695Z" fill="white"/>
                <path d="M8.74085 9.62695L8.8996 10.0667H9.41335L8.99775 10.3385L9.1565 10.7782L8.74085 10.5064L8.32526 10.7782L8.48401 10.3385L8.06836 10.0667H8.5821L8.74085 9.62695Z" fill="white"/>
              </svg>
              English
            </Dropdown.Item>
            <Dropdown.Item href="#/action-2" onClick={(e) => onChangeLang(e, "ar")}>
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" style={{marginRight:"10px", marginLeft:"10px", marginTop:"-3px"}} id="flag-icons-sa" viewBox="0 0 640 480">
                <defs>
                  <clipPath id="sa-a">
                    <path fillOpacity=".7" d="M-85.3 0h682.6v512H-85.3z"/>
                  </clipPath>
                </defs>
                <g fillRule="evenodd" clipPath="url(#sa-a)" transform="translate(80) scale(.9375)">
                  <path fill="#199d00" d="M-128 0h768v512h-768z"/>
                  <path fill="#fff" d="M65.5 145.1c-.8 12-2 33 8.3 35.2 12.3 1.2 5.5-20.8 10-24.8.8-2 2.3-2 2.4.5v18.7c0 6 4 7.8 7 9 3.2-.2 5.4 0 6.6 3l1.6 32.3s7.4 2.2 7.8-18.1c.3-12-2.4-21.9-.8-24.2 0-2.3 3-2.4 5-1.3 3.2 2.2 4.6 5 9.6 4 7.6-2.2 12.2-5.9 12.3-11.7a47 47 0 0 0-3.5-16.6c.4-1-1.4-3.7-1-4.7 1.3 2.2 3.4 2 3.8 0-1.3-4.2-3.3-8.3-6.5-10-2.7-2.4-6.7-2-8 3-.8 5.7 2 12.4 6.1 18 .9 2.1 2.1 5.7 1.6 8.9-2.2 1.3-4.4.7-6.3-1.2 0 0-6-4.5-6-5.6 1.6-10.2.3-11.4-.6-14.3-.6-3.9-2.5-5.2-4-7.8-1.5-1.6-3.5-1.6-4.5 0-2.7 4.6-1.4 14.5.5 19 1.4 4.1 3.5 6.7 2.5 6.7-.8 2.3-2.5 1.7-3.8-1a66.6 66.6 0 0 1-2.1-17.4c-.5-4.6-1.1-14.4-4.2-17-1.8-2.4-4.5-1.2-5.5 1a82.4 82.4 0 0 0 .3 13.4c2 7.4 2.7 14 3.7 21.5.3 10.1-5.8 4.4-5.5-.7a45 45 0 0 0-.3-19.4c-1-2.6-2.1-3.2-4.6-2.8-1.9 0-6.8 5.3-8.2 14.3 0 0-1.2 4.6-1.7 8.7-.7 4.6-3.7 8-5.9-.6-1.8-6.3-3-21.6-6-18z"/>
                  <path fill="#fff" d="m99 194.2-32 15.4c.3-7.3 15.1-20.4 25.3-20.5 6.5.1 4.9 2.5 6.6 5.1z"/>
                  <path fill="#fff" d="M93.3 204.2c-16.8 43.5 39.5 49.6 45.8 1.8.6-2 3-3.9 3.4-.7-1.3 43.3-43.6 46.2-50.8 32.6a41.9 41.9 0 0 1-2.5-14.6c-1-8.5-5.5-5.2-6.2 3.2-.7 4.7-.5 6-.5 10.5 2.2 34.2 56.7 19.5 65.6-8.7 4.7-15.6-.8-27.1 1.7-27.1 5.4 5.8 13 .8 14.7-1.2.7-1 2.5-1.7 3.7-.4 4.2 3 11.6 1.6 13.2-3.7.9-5.3 1.6-10.7 1.8-16.2-3.5 1-6 1.7-6.3 3.2l-.7 4.6c-.3 1.5-3.2 1.5-3.4-.4-1.3-6-6.7-6.7-10 2.5-2.1 1.8-6.1 2.2-6.5-.5.5-6.2-2-7-7-4.1l-4.8-36.2c2 0 4 1.5 5.9-.9-2-6.5-6.5-19.7-9-20.7-1.1-1.4-2.1-.5-3.7-.1-2.6.8-5 3-4.2 7.4 3 18.8 5 33.1 8.1 52 .5 2.1-1.3 5-3.7 4.7-4-2.7-5-8.2-12-8-5 0-10.6 5.5-11.3 10.7-.9 4.2-1.2 8.7 0 12.3 3.5 4.2 7.7 3.8 11.4 2.9 3-1.3 5.5-4.3 6.6-3.6.7.9.1 10.9-14.3 18.5-8.7 4-15.7 4.8-19.4-2.3-2.3-4.5.2-21.4-5.6-17.5z"/>
                  <path fill="#fff" d="M165 160c3.3-1.2 19.3-19.6 19.3-19.6l-2.4-2c-.9-.7-.8-1.5 0-2.2 4-2.4 2.7-7.4.7-9.8a9.7 9.7 0 0 0-8.7.1c-2.8 2.7-3.4 7-1.2 9.6 2.1 1 4.2 3.2 2.8 4.4-6.6 7-24.5 19.1-22.4 19.5.4.6 11.5.6 11.8 0zm-97 65c-6 9.6-6.5 23.9-3.2 28.2 1.8 2 4.7 2.9 6.8 2.2 3.8-1.6 5.5-9.3 4.6-12-1.3-2-2.3-2.3-3.6-.7-2.6 5.4-3.7 1.7-4-1.3a70 70 0 0 1 .8-15.2c.7-4.2 0-3-1.4-1.2zm257.1-15.3c-5.8-12.6-13.9-25-16.4-29.7a557.6 557.6 0 0 0-24.8-36c-6.2-7.4 10.2 3.1-2-11.7l-8.9-7.5c-2-1.4-6.8-4-7.6.2-.4 3.8-.2 5.8.4 8.9.5 2 3.5 5.5 5 7.5a565 565 0 0 1 53.8 86.5c2.6-1.3 2-16.1.5-18.2z"/>
                  <path fill="#fff" d="M299.6 251.5c-1.2 1.3 2.8 6.8 8 6.8 8.6-1 16.2-5.8 23.2-18.6a33 33 0 0 0 5.3-14.2 317 317 0 0 0-5.8-72.4c-.3-2 0-4.4.2-5 .6-.7 2.5 0 3.5-1.7 1.5-1.5-4-14-7-18.7-1-2.2-1.5-3.6-3.3.2a27 27 0 0 0-3 13.6c4.1 28.5 5.4 53.4 8 81.9.3 2.8-.1 6.8-2 8.4a80.2 80.2 0 0 1-27.1 19.7zm116.5-.1c-6.2 3.6-6.2 7.7-1.2 7.8 8.6-1 18.8-1.7 25.8-12.3a41 41 0 0 0 4.2-16 303 303 0 0 0-4.7-71.4c-.2-2-1.1-6.7-.8-7.3.6-1.4 3.4.1 4.4-1.5 1.4-1.5-7.3-12.7-10.4-17.5-1-2.2-1.4-3.6-3.3.2a22.3 22.3 0 0 0-1.8 13.6c4.6 31 8 54.2 8.7 81.6-.4 2.6-.5 4-1.7 7.3-2.7 3.4-5.7 7.8-8.5 9.9-2.8 2-8.8 4-10.7 5.6z"/>
                  <path fill="#fff" d="M420.7 223.7c0-7.3.1-13.5-.1-19a34 34 0 0 0-3-13.5c-1.8-4.1-.7-7.4-1.6-11.8-.8-4.4-.6-11-1.8-16.1-.4-2-1.4-8.5-1.1-9.2.5-1.4 2.4 0 3.4-1.6 1.4-1.5-5-18-8.2-22.7-1.1-2.1-3.3-1.4-5.8 2-2.5 2.3-1.6 7.4-.6 12.3 6.1 32.3 10.8 61.6 9.8 92.3-.4 2.6 9-7.8 9-12.7zm-45.7-40c-3.9-.2-12-7.7-14.4-12a8 8 0 0 1 .4-6.5c1.5-1 3.7-2 5.4-1 0 0 1.7 2.4 1.4 2.7 2 1 3 .5 3.2-.4.1-1.5-.6-2.4-.6-4 .9-4.6 6-5.3 8-2.4 1.4 1.8 2 5.5 2.1 8 0 1.3-2-.2-3.3 0-1.1.4-1.4 1.8-1.5 3-.2 3.3-.6 8.6-.7 12.5zm-71.8 48c1-9.8-.4-27.3-.5-33.1A477 477 0 0 0 299 154c-1.2-8.4 3.4.9 2.8-4-1.5-8.3-6.1-14-11.6-21.5-1.7-2.5-1.7-3-4.4.6-3 6.7-.4 11.4.4 16.7 3.9 17.2 6.2 33 7.3 48.7a393.4 393.4 0 0 1 .4 49c3 .1 7.6-4.7 9.3-11.8z"/>
                  <path fill="#fff" d="M434 216c-6.9-11.6-17.2-24-20-28.7a658 658 0 0 0-29.2-37.8c-8.5-9 4-1.5-1.6-8.5-4.7-5.1-6-6.8-10.1-9.9-2-1.3-3.2-3.8-4 .5a83 83 0 0 0-.2 11.2c0 1.7 1.8 5 3.4 7 20.7 25.5 43.4 51.5 61.6 84.2 2.6-1.3 1.7-16 0-18z"/>
                  <path fill="#1ba400" d="M122.6 194.7c-.5.9-1.6 2-1.2 3.1.7 1 1.4 1.3 2.6 1.3 1.1 0 2.7.3 3-.3.6-.7 1-2 .6-3.3-1.2-3-4.4-1.8-5-.8z"/>
                  <path fill="#fff" d="M354.2 362.5c9.2.4 15.2.5 23.3 1.4l9.6-1c10.6-1 11 15.1 11 15.1 0 9.5-3.7 10-8.4 11-2.7.4-4-1.6-5.5-3.6a14 14 0 0 1-7 .4c-3.9-.2-7.7-.2-11.5-.5-4-.3-6.2.5-10.3.1-.8 1.3-2 3.1-4.4 2.6-2-.3-4.5-6-3.8-10.5 1.5-3.2 1-2.1 1-3.5-37.6-1-75.5-2.7-112.3-2.2-28.8.1-57.2 1.3-85.7 2.5-15.2-.2-26.8-2.6-34.8-14.3.8 0 38.8 2.1 49.9 1.4 20.5-.2 39.3-1.9 60.2-2.5 41.2.7 82.1.7 123.3 3.6-4-2.7-4-9 2-10.6.5-.4.8 3.1 1.7 3 4.9-.3 2.7 6.3 1.7 7.6zM188.6 135.3c-6.2 17.8 3.6 37.4 10.4 35.5 5 2 8-7.4 10-17.6 1.5-2.9 2.5-3.2 3.2-1.7-.2 13.6 1 16.7 4.5 20.8 7.8 6 14.3.8 14.8.3l6-6.1c1.4-1.5 3.2-1.5 5.1-.3 1.9 1.7 1.6 4.6 5.6 6.6 3.4 1.4 10.5.4 12.2-2.5 2.2-3.9 2.8-5.2 3.8-6.6 1.6-2.1 4.3-1.2 4.3-.5-.3 1.2-1.9 2.3-.8 4.5 2 1.4 2.4.5 3.5.2 4-2 7-10.6 7-10.6.1-3.2-1.7-3-2.9-2.2l-3.1 2.1c-2 .3-5.7 1.6-7.6-1.3-1.9-3.4-1.9-8.3-3.3-11.8 0-.2-2.6-5.5-.2-5.8 1.2.2 3.7.9 4.1-1.2 1.2-2.1-2.6-8-5.3-11-2.3-2.5-5.5-2.8-8.6-.2-2.2 2-1.9 4.2-2.3 6.3a9.8 9.8 0 0 0 2 8.7c2.2 4.2 6.1 9.7 4.8 17.5 0 0-2.3 3.6-6.3 3.1-1.7-.3-4.4-1-5.8-11.8-1.1-8 .2-19.4-3.2-24.7-1.3-3.3-2.2-6.4-5.2-.9-.8 2.2-4.3 5.5-1.8 12.2a36 36 0 0 1 2 19c-1.5 2.2-1.8 2.9-3.7 5-2.6 3-5.5 2.2-7.7 1.1-2-1.3-3.6-2-4.6-6.5.2-7 .6-18.5-.7-20.9-1.9-3.8-5-2.4-6.3-1.2a47.7 47.7 0 0 0-11.5 23.5c-1.8 5.8-3.7 4.1-5 1.8-3.2-3-3.5-26.7-7.4-22.8z"/>
                  <path fill="#fff" d="M207.4 174.1c2.9-2 1.6-3.4 5.8.8a72 72 0 0 1 9.2 31.3c-.2 2.6 1.6 4.2 2.5 3.6.4-6 15.1-14.4 28.6-15.6 2-.5 1-4.4 1.3-6.4-.8-7.5 4.2-14.3 11.2-14.8 9.6 1.4 12.8 6.5 13 14.2-1.1 15-16.7 17.5-25.4 18.7-1.3.5-1.9 1.1 0 1.8l36.6.2 1.9 1c.2 1-.6.2-2 2.6a29.5 29.5 0 0 0-3.7 11.5c-10.9 3.6-22.2 5-33.6 6.5-4 2-6 4.7-5.2 7.7 1.4 3.3 10.2 6.7 10.2 6.8 1.7 1 3.6 3.5-.5 8.6-17.8-.8-31.7-8.4-36.5-19.1-1.4-1.1-3 0-4 1.4-7 9-13.8 17-25.7 21.4-7 1.8-14.3-1.1-17.7-5.7-2.3-2.7-2.2-5.6-3-6.2-3.9 1.7-36.9 15.7-32.7 9.1 8-8.5 22-14.9 34.2-23.3.9-2.9 2.5-12.5 7.3-15.6.3 0-.7 5.6-.6 8 0 2-.2 2.7.2 2.2.9-.5 15.7-12.2 17-15.8 1.4-2 .3-7.2.3-7.4-2.8-7.2-6.7-7.8-8.1-11.4-1.3-4.7-.7-10.1 2-11.7 2.4-2.1 5.2-1.9 7.9.5 3 2.7 5.6 8 6.4 11.9-.5 1.5-4-1-5-.3a16 16 0 0 1 3.7 7.8c2 8.2 1.4 11.4-.6 16.7-6.6 13.9-15 18-22.4 23.2-.2 0-.3 3.5 2.4 5.4 1 1 4.9 1.5 9.4 0a54.5 54.5 0 0 0 22.3-23.3 51 51 0 0 0-2.4-22.2c-2.9-6.7-6.3-16.2-6.3-16.4-.1-4.2.2-5.6 2-7.7zm-95.8-38.6c4.2 2 12.2 1.1 11.8-5.7l-.2-3.1c-.8-2-3.2-1.5-3.7.5-.2.7.3 1.8-.3 2.1-.4.4-1.7.2-1.7-1.7 0-.6-.4-1.2-.7-1.6-.2-.2-.4-.2-.9-.2-.6 0-.6.1-.9.6-.1.5-.3 1-.3 1.6 0 .7-.4.9-.8 1-.6 0-.5 0-1-.2-.2-.3-.5-.4-.5-1l-.3-1.6c-.2-.3-.6-.5-1-.6-2.3 0-2.5 2.7-2.3 3.7-.2.2-.3 4.9 2.8 6.2z"/>
                  <path fill="#fff" d="M235.1 187.7c4.2 2 14.3.9 11.8-5.6l-.2-3.2c-.9-2-3.2-1.5-3.7.6-.2.6.3 1.7-.4 2-.3.4-1.7.2-1.6-1.6 0-.6-.4-1.3-.7-1.7-.3-.1-.4-.2-1-.2-.5 0-.5.2-.8.7-.2.5-.3 1-.3 1.6-.1.6-.4.8-.9 1-.5 0-.4 0-.8-.3-.3-.3-.6-.4-.6-.9l-.3-1.6c-.2-.3-.6-.5-1-.6-2.3 0-2.5 2.6-2.4 3.6-.1.2-.2 5 3 6.2zm72-21.6c4.2 2 12.1 1.1 11.8-5.6l-.2-3.2c-.9-2-3.2-1.5-3.7.5-.2.7.3 1.8-.4 2.2-.3.3-1.7.1-1.6-1.8 0-.6-.4-1.2-.7-1.6-.3-.2-.4-.2-1-.2-.5 0-.5.2-.8.7l-.3 1.5c-.1.7-.4 1-.9 1s-.4 0-.8-.2c-.3-.3-.6-.4-.6-.9s-.1-1.3-.3-1.7c-.2-.3-.6-.4-1-.5-2.3 0-2.5 2.6-2.4 3.6-.1.2-.2 4.9 3 6.2zm37.3 54.3c-7.3 8.3-4.1 22-2.4 25 2.4 4.8 4.3 7.9 9 10.3 4.3 3.1 7.7 1.2 9.5-1 4.3-4.5 4.4-16 6.4-18.2 1.4-4.2 5-3.5 6.7-1.6a16.5 16.5 0 0 0 6.2 5.3c4 3.5 8.8 4.2 13.6 1 3.2-1.9 5.3-4.2 7.2-8.9 2-5.6 1-31.6.5-47l-4.2-21.5c0-.2-.5-10.2-1-12.5 0-1-.3-1.3.7-1.2 1.1 1 1.2 1 2 1.3 1 .2 2-1.7 1.3-3.3l-10-18.6c-.8-.8-1.9-1.6-3.2.2a7.3 7.3 0 0 0-2.4 5.5c.3 4.4 1 8.9 1.3 13.3l4 22.6c1.3 16 1.6 29.2 2.9 45.3-.2 6.8-2.3 12.7-4.3 13.6 0 0-3 1.7-5-.2-1.5-.6-7.4-9.9-7.4-9.9-3-2.7-5-2-7.1 0-6 5.8-8.6 16.4-12.7 23.8-1 1.7-4 3-7.2-.1-8.2-11.3-3.4-27.3-4.4-23.2zM309 126.7c3.8 1.5 6.4 9.2 5.6 13-.8 4.5-2.8 9.5-4.2 8.9-1.6-.6 1-4.6-.5-8.8-.8-2.8-6-7.8-5.4-9.2-1-3.1 2.2-4.5 4.5-4z"/>
                  <path fill="#fff" d="M356.6 225c.7-9.2-.6-14.8-.8-20.2s-6.1-46.6-7.3-50.6c-1.5-7.8 5.7-1 4.9-5.6-2.5-5.6-8.6-13.9-10.5-18.8-1.2-2-.7-4-3.3-.5a42.3 42.3 0 0 0-2.3 19.2c6.2 32.3 12.5 59.1 11.5 89.8 3 0 6.3-6.7 7.8-13.3zm64.4-85.3c3.5 1.7 5.5 11.3 5.1 14-.7 5-2.5 10.4-3.8 9.7-1.5-.6.3-7.4-.4-9.5-.8-3-5.5-8.4-5-10-1-3.4 2-4.8 4.1-4.2zm-255.7 67.9c3.3 1.3 5.3 8.3 5 10.3-.8 3.7-2.5 7.7-3.8 7.1-1.3-.4.3-5.4-.3-7-.3-3.7-4.9-5.7-4.8-7.3-.8-3 2-3.5 4-3.1z"/>
                  <path fill="#1b9d00" d="M244.9 218.2c4.2.2 6.3 3.6 2.4 5-4 1.3-7.7 2.4-7.8 8 1.5 8-2 5.2-4 4.2-2.4-1.8-9.2-6-10.2-15-.1-2.1 1.6-4 4.3-4 4 1.1 10 1.2 15.3 1.8z"/>
                  <path fill="#fff" d="M77.4 124.4c4.8 1.4 5.1 8.6 4.8 10.7-.7 3.8-2.4 7.9-3.6 7.4-1.4-.5 0-5.7-.7-7.3-.7-2.2-4.8-6.4-4.4-7.6-.9-2.5 2-3.7 3.9-3.2zm95.9 33.6c-3.8 2-5.2 8-2.9 11.6 2.2 3 5.6 1.9 6 1.9 3.7.4 5.9-6.9 5.9-6.9s.1-2-4.2 1.9c-1.9.3-2-.4-2.5-1.4a9 9 0 0 1 .5-5.7c.7-1.8-.7-2.6-2.8-1.4zm28-36.4c-2 1.3-5.7 5.2-5.8 9.6-.1 2.5-.6 2.5 1 4 1.3 1.8 2.4 1.7 4.8.4a5.1 5.1 0 0 0 2.3-3.4c.6-2.8-3 1.4-3.4-1.8-.8-3 1.5-4.2 3.7-7 0-2 0-3.3-2.7-1.8zm22.4 4a59.5 59.5 0 0 0-1.6 11.1c-.6 2.8 3 4 4.5.4 2.4-6.5 2.4-9.3 2.6-12-.7-4.3-3.6-4.2-5.5.5zm142 72.3c.4-.5 20-14.4 20-14.4 2-.7 1.5 7.2.6 7.1a77.8 77.8 0 0 1-20.7 14.3c-1 .7-1.9-5.3 0-7zm17.7-.2c3.5 1.7 4.9 11.8 4.5 14.5 0 5.4-3.3 9.6-4.7 9-1.4-.7.2-6.7-.5-8.8-.8-3-3.7-8.5-3.2-10.1-1-3.4 1.8-5.2 4-4.6zm-116 43.4a26 26 0 0 1 5.6-4.9c2-1 3.8.8 3.7.7.3 2-1.2 3.7-.7 6.3.4 1 .7 2.2 2.6 1.8 3.1-2.5 6-2.7 9-2.8 2.5.1 2.6 4.2 1 4.2-5.7 1.2-8.2 2.8-12.3 4.3-2 1.2-3.6-.3-3.6-.4s-1.1-1.1-.4-3.7c.2-2-.6-3.2-2.4-3-1.2.8-2.4 1.2-3-.3-.3-1-.4-1.6.5-2.2zm136.6 5.4c.8 1 1.4 2-.1 3.8l-3.7 3.2c-.6 1-1 2.8 1 3.3 3.6 1 12-4.5 12-4.6 1.4-1 1-3 .8-3-.8-.9-2.6-.3-3.8-.5-.6 0-2.5-.2-1.6-2a11.4 11.4 0 0 0 1.6-2.9c.5-1.2 0-2-2-2.7-2.1-.4-3-.2-5.3 0-1.2.2-1.6.8-1.9 2.3.1 2.3 1.5 2.2 3 3z"/>
                  <path fill="#259f00" d="M268.1 189.7c-.5 1-2.3 1-4 0s-2.7-2.6-2.1-3.5 2.3-.9 4 0 2.6 2.6 2.1 3.5zm-89-53.6c-1 .3-2.4-.6-3-2s-.3-2.6.7-2.9 2.3.7 3 2 .3 2.7-.8 3z"/>
                  <path fill="#209000" d="M355.2 375c9.4.4 18.2 0 27.5.5 1.7 1.5.5 5-.6 4.8l-7.8-.3c-.1-3-7.7-2.5-7.5.1-4.1.5-7.8-.1-12-.3-1.2-1.5-1-4.2.4-4.8z"/>
                </g>
              </svg>
              Arabic
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

      </div>
    </div>
    <Navbar expand="lg">
      <Container>
        <Navbar.Brand href="/">
          {/* <img alt="" src="assets/images/logo.svg" /> */}
          <img alt="" src={logo}/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/" className={`${_urlPath === "" ? 'active' : ""}`}>
              <FormattedMessage 
                id="home.text"
                defaultMessage="Home"
                description="Home"
              />
            </Nav.Link>
            {me && me.role.en === "Lessor" ? (
              <Nav.Link href="/lessor-leases" className={`${_urlPath === "lessor-leases" ? 'active' : ""}`}>
                <FormattedMessage 
                  id="mybookings.text"
                  defaultMessage="My Bookings"
                  description="My Bookings"
                />
              </Nav.Link>
            ) : (
              <Nav.Link href="/leases" className={`${_urlPath === "leases" ? 'active' : ""}`}>
                <FormattedMessage 
                  id="leases.text"
                  defaultMessage="Leases"
                  description="Leases"
                />
              </Nav.Link>
            )}
            {/* <Nav.Link href="/partner" className={`${_urlPath === "partner" ? 'active' : ""}`}>
              <FormattedMessage 
                id="becomepartner.text"
                defaultMessage="Become A Partner"
                description="Become A Partner"
              />
            </Nav.Link> */}
            <Nav.Link href="/support" className={`${_urlPath === "support" ? 'active' : ""}`}>
              <FormattedMessage 
                id="support.text"
                defaultMessage="Support"
                description="Support"
              />
            </Nav.Link>
          </Nav>

          {me ? (
            // if user is login
            <div className="d-flex">
              <div className="d-flex mt-1">
                <div className="div-head-circle me-2" onClick={onClickNotification}>
                  <svg width="18" height="20" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.9995 0.199951C9.45339 0.199951 7.01159 1.21138 5.21124 3.01173C3.41089 4.81208 2.39946 7.25387 2.39946 9.79995V15.5376L1.26826 16.6688C1.04457 16.8925 0.892238 17.1776 0.83053 17.4879C0.768822 17.7982 0.800507 18.1199 0.921579 18.4122C1.04265 18.7045 1.24767 18.9544 1.51073 19.1302C1.77379 19.306 2.08306 19.3999 2.39946 19.4H21.5995C21.9159 19.3999 22.2251 19.306 22.4882 19.1302C22.7513 18.9544 22.9563 18.7045 23.0773 18.4122C23.1984 18.1199 23.2301 17.7982 23.1684 17.4879C23.1067 17.1776 22.9544 16.8925 22.7307 16.6688L21.5995 15.5376V9.79995C21.5995 7.25387 20.588 4.81208 18.7877 3.01173C16.9873 1.21138 14.5455 0.199951 11.9995 0.199951ZM11.9995 25.7999C10.7264 25.7999 9.50553 25.2942 8.60535 24.3941C7.70518 23.4939 7.19946 22.273 7.19946 21H16.7995C16.7995 22.273 16.2938 23.4939 15.3936 24.3941C14.4934 25.2942 13.2725 25.7999 11.9995 25.7999Z" fill="#242424"/>
                  </svg>
                </div>
                <div className="div-head-circle me-2" onClick={onClickChat}>
                  <svg width="22" height="20" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M25.8002 12C25.8002 18.1856 20.069 23.2 13.0002 23.2C10.7283 23.2096 8.48748 22.6718 6.46739 21.632L0.200195 23.2L2.341 18.2032C0.988995 16.4272 0.200195 14.2944 0.200195 12C0.200195 5.81445 5.93139 0.800049 13.0002 0.800049C20.069 0.800049 25.8002 5.81445 25.8002 12ZM8.20019 10.4H5.0002V13.6H8.20019V10.4ZM21.0002 10.4H17.8002V13.6H21.0002V10.4ZM11.4002 10.4H14.6002V13.6H11.4002V10.4Z" fill="#242424"/>
                  </svg>
                </div>
                <div className="div-head-profpic">
                  <img alt="" src={me && me.avatar ? me.avatar : defaultAvatar} className="imgenclose"/>
                </div>
                <Dropdown>
                  <Dropdown.Toggle className="dropdown-prof">
                    {me && me.name}
                  </Dropdown.Toggle>
  
                  <Dropdown.Menu>
                    {me && me.role.en === "Lessor" ? (
                      <Dropdown.Item onClick={onClickMyPost}>
                        <FormattedMessage 
                          id="mypost.text"
                          defaultMessage="My Post"
                          description="My Post"
                        />
                      </Dropdown.Item>
                    ) : ""}
                    {me && me.role.en === "Lessee" ? (
                      <Dropdown.Item onClick={onClickBookings}>
                        <FormattedMessage 
                          id="mybookings.text"
                          defaultMessage="My Bookings"
                          description="My Bookings"
                        />
                      </Dropdown.Item>
                    ) : ""}
                    <Dropdown.Item onClick={onClickSettings}>
                      <FormattedMessage 
                        id="settings.text"
                        defaultMessage="Settings"
                        description="Settings"
                      />
                    </Dropdown.Item>
                    <Dropdown.Item onClick={LogOutUser}>
                      <FormattedMessage 
                        id="logout.text"
                        defaultMessage="Logout"
                        description="Logout"
                      />
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            // if user is login
          ) : (
            <div className="d-flex">
              <div className="d-flex mt-4">
                <div className="text-center me-3 ms-3">
                    <a className="w-100 cred fs16 fw700 cur-pointer text-uppercase" onClick={onClickLoginModal}>
                      <FormattedMessage 
                        id="login.text"
                        defaultMessage="Login"
                        description="Login"
                      />
                    </a>
                </div>
                <div className="text-center">
                    <a className="btn btn-getstarted" onClick={() => setShowModalRegister(true)}>
                      <FormattedMessage 
                        id="getstarted.text"
                        defaultMessage="GET STARTED"
                        description="GET STARTED"
                      />
                    </a>
                </div>
              </div>
            </div>
          )}
          

          <Modal className="modalChat" show={showModalLogin} onHide={handleCloseModalLogin}>
            <Modal.Body>
              <a href="/login-lessor" className="btn btn-payment w-100 mt-3 mb-4 fw700">
                <FormattedMessage 
                  id="lessor.text"
                  defaultMessage="Lessor"
                  description="Lessor"
                />
              </a>
              <a href="/login" className="btn btn-payment w-100 mt-3 mb-4 fw700">
                <FormattedMessage 
                  id="lessee.text"
                  defaultMessage="Lessee"
                  description="Lessee"
                />
              </a>
            </Modal.Body>
          </Modal>


          <Modal className="modalChat" show={showModalRegister} onHide={handleCloseModalRegister}>
            <Modal.Body>
              <a href="/step1-lessor" className="btn btn-payment w-100 mt-3 mb-4 fw700">
                <FormattedMessage 
                  id="createlessor.text"
                  defaultMessage="Create Lessor Account"
                  description="Create Lessor Account"
                />
              </a>
              <a href="/step1" className="btn btn-payment w-100 mt-3 mb-4 fw700">
                <FormattedMessage 
                  id="createlessee.text"
                  defaultMessage="Create Lessee Account"
                  description="Create Lessee Account"
                />
              </a>
            </Modal.Body>
          </Modal>

        </Navbar.Collapse>
      </Container>
    </Navbar>
  </div>
  )
}

export default Header
