import React, { useState, useEffect, useContext, useRef } from "react";
import { generatePath, useHistory } from "react-router-dom";
import { Tab, Tabs, Dropdown  } from 'react-bootstrap';
import {FormattedMessage, useIntl} from 'react-intl';
import { RouteContext } from "../../contexts/route.context";
import MyLeaseSingle from "./myleasesingle";
import Pagination from "./pagination";
import { scrollToTop, translateTerm } from "../../util/helper";
import { useIntersection } from "../../util/useIntersection";

const MyLeases = () => {
  const history = useHistory();
  const intl = useIntl();
  const { getActiveBookings, activeBookings, activeBookingLoading, setActiveBookingLoading,
    getHistoryBookings, historyBookings, historyBookingLoading, setHistoryBookingLoading, } = useContext(RouteContext);
  const [key, setKey] = useState('active');
  const [numLease, setNumLease] = useState(12);
  const [pageLease, setpageLease] = useState(1);
  const [chosenPost, setChosenPost] = useState();
  const [products, setProducts] =useState();
  const [historyProducts, setHistoryProducts] = useState();
  const [moreLoading, setMoreLoading] = useState(false);
  const [noMorePosts, setNoMorePosts] = useState(false);

  const elementRef = useRef(null);
  const isVisible = useIntersection(elementRef, "0px");

  useEffect(() => {
    if (isVisible && products) {
    // Trigger a function when the div is visible on view port
    triggerFunc();
    }
  }, [isVisible]);

  const triggerFunc = () => {
    if(key === 'active') {
      let lesseeBookings = `{user_role: lessee}`;
      if(products){
          if(products.paginatorInfo.hasMorePages){
            scrollPosts(lesseeBookings);
            }
            else{
              setNoMorePosts(true);
            }
        }
    }
    else{
      let lesseeBookings = `{user_role: lessee}`;
      if(historyProducts){
        if(historyProducts.paginatorInfo.hasMorePages){
          scrollPosts(lesseeBookings);
          }
          else{
            setNoMorePosts(true);
          }
      }
    }
   
   }

   const scrollPosts = async (lesseeBookings) => {
    if(key === 'active') {
      if(moreLoading == false){
        setMoreLoading(true);
        let result = await getActiveBookings(numLease, products.paginatorInfo.currentPage+1, lesseeBookings);
        if(products.paginatorInfo.hasMorePages){
            result.data.map((dat)=>{
              products.data.push(dat);
            })
            products.paginatorInfo = result.paginatorInfo;
        }
        setMoreLoading(false);
      }
    }
    else{
      if(moreLoading == false){
        setMoreLoading(true);
        let result = await getHistoryBookings(numLease, historyProducts.paginatorInfo.currentPage+1, lesseeBookings);
        if(historyProducts.paginatorInfo.hasMorePages){
            result.data.map((dat)=>{
              historyProducts.data.push(dat);
            })
            historyProducts.paginatorInfo = result.paginatorInfo;
        }
        setMoreLoading(false);
      }
    }
  }
  
  const getHistoryProducts = async (lesseeBookings) => {
    let x = await getHistoryBookings(numLease, pageLease, lesseeBookings);
    setHistoryProducts(x);
  }

  const getProducts = async (lesseeBookings) => {
    let x = await getActiveBookings(numLease, pageLease, lesseeBookings);
    console.log("AHHAHAH",x)
    setProducts(x);
  }

  useEffect(() => {
    setActiveBookingLoading(true);
    let lesseeBookings = `{user_role: lessee}`;
    getActiveBookings(numLease, pageLease, lesseeBookings);
    setHistoryBookingLoading(true);
    getHistoryBookings(numLease, pageLease, lesseeBookings)
    getProducts(lesseeBookings);
    getHistoryProducts(lesseeBookings)
  }, []);

  const onClickPost = (pt, from) => {
    // console.log("Click Post", pt, from);
    // scrollToTop(0);
    //     pt.id && 
    let id = pt.id
    if(key === "active")
    {
      history.push(generatePath("/my-lease/active/:id", { id }));
    }
    else{
      history.push(generatePath("/my-lease/history/:id", { id }));
    }

    // pt.from = from;
    // setChosenPost(pt);
    // scrollToTop(0);
  }

  return (
  <>
  {chosenPost ? (
    <MyLeaseSingle post={chosenPost} from={"myleases"} />
  ) : (
    <div>
      <div className="container-fluid leases-div">
        <div className="container main-wrap">
          <div className="row">
            <div className="col-12">
              <h1 className="fw800 cwhite mb-4">
                <FormattedMessage 
                  id="rentalseasy.text"
                  defaultMessage="Rentals made easy!"
                  description="Rentals made easy!"
                />
              </h1>
              <a className="btn btn-orang-def">
                <FormattedMessage 
                  id="rentnow.text"
                  defaultMessage="Rent Now"
                  description="Rent Now"
                />
              </a>
            </div>
          </div>
        </div>
      </div>


      <div className="container main-wrap product-list-div">
        <div className="row">
          <div className="col-12">
            
            {/* <div className="filter-div mt-4">
              <div className="sort-div">
                <p className="mb-0 cblack fs16">Sort by:</p>
                <Dropdown className="ms-4">
                  <Dropdown.Toggle variant="success" className="sort-dropdown">
                    Category
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown className="ms-4">
                  <Dropdown.Toggle variant="success" className="sort-dropdown">
                    Lease Term
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown className="ms-4">
                  <Dropdown.Toggle variant="success" className="sort-dropdown">
                    Location
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="search-div">
                <input type="text" className="form-control" />
                <svg width="18" height="18" style={{position:"absolute", zIndex:"9", top:"11px", right:"14px"}} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M9 3.00115C7.4087 3.00115 5.88258 3.63329 4.75736 4.75851C3.63214 5.88373 3 7.40985 3 9.00115C3 10.5924 3.63214 12.1186 4.75736 13.2438C5.88258 14.369 7.4087 15.0012 9 15.0012C10.5913 15.0012 12.1174 14.369 13.2426 13.2438C14.3679 12.1186 15 10.5924 15 9.00115C15 7.40985 14.3679 5.88373 13.2426 4.75851C12.1174 3.63329 10.5913 3.00115 9 3.00115ZM1.70192e-07 9.00115C-0.000180585 7.58471 0.333964 6.18822 0.975257 4.92526C1.61655 3.66231 2.54688 2.56855 3.69059 1.73294C4.8343 0.897329 6.15909 0.343468 7.55722 0.116399C8.95534 -0.11067 10.3873 -0.00453374 11.7367 0.426175C13.0861 0.856883 14.3147 1.6 15.3227 2.59509C16.3308 3.59018 17.0897 4.80915 17.5378 6.15285C17.9859 7.49655 18.1105 8.92704 17.9015 10.328C17.6925 11.7289 17.1558 13.0607 16.335 14.2151L23.5605 21.4406C23.8337 21.7236 23.9849 22.1025 23.9815 22.4958C23.9781 22.889 23.8203 23.2653 23.5422 23.5434C23.2641 23.8215 22.8879 23.9792 22.4946 23.9827C22.1013 23.9861 21.7224 23.8349 21.4395 23.5617L14.2155 16.3377C12.869 17.2951 11.285 17.8634 9.63701 17.9803C7.98901 18.0973 6.34061 17.7583 4.87245 17.0006C3.40429 16.2429 2.17303 15.0957 1.31359 13.6847C0.45414 12.2737 -0.000321076 10.6533 1.70192e-07 9.00115Z" fill="#242424"/>
                </svg>
              </div>
            </div> */}


            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => {setKey(k)
                console.log(k)
                console.log(historyProducts)
              }}
              className="mb-5 product-categories justify-content-start"
            >
              <Tab eventKey="active" title={intl.formatMessage({
                id: 'active.text',
                defaultMessage: 'Active',
                description: 'Active',
              })}>

                {!activeBookingLoading && activeBookings ? 
                  activeBookings.data.length === 0 ? (
                    // when no active lease
                    <div className="row mt-5">
                      <div className="col-12">
                        <div className="text-center">
                          <img src="assets/images/no-lease-img.png" className="img-fluid pt-5 mb-4" />
                          <p className="fs30 fw700 cgray">
                            <FormattedMessage 
                              id="havenoactivelease.text"
                              defaultMessage="You have no active lease"
                              description="You have no active lease"
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="row">
                      {products?.data.map((post, index) => {
                      
                      // let pricePerTerm = post.post.post_lease_terms.filter((ls) => ls.lease_term === post.lease_term);

                      return (
                        <div key={index} className="col-12 col-lg-4 cur-pointer" onClick={() => onClickPost(post, 'active')}>
                          <div className="prod-list-single-div">
                            <div className="prod-img-div position-relative">
                              <img src={post.post.post_images.length !== 0 ? post.post.post_images[0].image : "assets/images/prod1.png"} className="imgenclose" />

                              <span className="chat-icon-div in-img">
                                <svg width="22" height="20" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path fillRule="evenodd" clipRule="evenodd" d="M25.8 12C25.8 18.1856 20.0688 23.2 13 23.2C10.7281 23.2096 8.4873 22.6718 6.46721 21.632L0.200012 23.2L2.34081 18.2032C0.988812 16.4272 0.200012 14.2944 0.200012 12C0.200012 5.81445 5.93121 0.800049 13 0.800049C20.0688 0.800049 25.8 5.81445 25.8 12ZM8.20001 10.4H5.00001V13.6H8.20001V10.4ZM21 10.4H17.8V13.6H21V10.4ZM11.4 10.4H14.6V13.6H11.4V10.4Z" fill="white"/>
                                </svg>
                              </span>
                            </div>
                            <div className="prod-upper-text-div">
                              {/* <p className="price fw800 cveryblack">SR {pricePerTerm[0].price} <span className="fw500">/{translateTerm(post.lease_term)}</span></p> */}
                              <div>
                                <p className="fw500 cblack mb-0 fs12">
                                  <svg width="18" height="17" style={{marginRight:"8px", marginTop:"-4px"}} viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.0491 0.927078C9.3491 0.00607812 10.6521 0.00607812 10.9511 0.927078L12.4701 5.60108C12.5355 5.80157 12.6625 5.97626 12.8332 6.10018C13.0038 6.22411 13.2092 6.29092 13.4201 6.29108H18.3351C19.3041 6.29108 19.7061 7.53108 18.9231 8.10108L14.9471 10.9891C14.7763 11.1132 14.6492 11.2883 14.584 11.4891C14.5188 11.6899 14.5188 11.9063 14.5841 12.1071L16.1021 16.7811C16.4021 17.7031 15.3471 18.4691 14.5641 17.8991L10.5881 15.0111C10.4172 14.8869 10.2114 14.8199 10.0001 14.8199C9.78883 14.8199 9.58298 14.8869 9.4121 15.0111L5.4361 17.8991C4.6531 18.4691 3.5981 17.7021 3.8981 16.7811L5.4161 12.1071C5.48137 11.9063 5.48141 11.6899 5.4162 11.4891C5.351 11.2883 5.22389 11.1132 5.0531 10.9891L1.0771 8.10108C0.293095 7.53108 0.697095 6.29108 1.6651 6.29108H6.5791C6.79014 6.29113 6.9958 6.22442 7.16662 6.10048C7.33744 5.97654 7.46467 5.80173 7.5301 5.60108L9.0491 0.927078Z" fill="#FCE412"/>
                                  </svg>
                                  {post.post.review_average}
                                </p>
                                <p className="cgray fs12 mb-0">({post.post.review_count} <FormattedMessage 
                                  id="reviews.text"
                                  defaultMessage="Reviews"
                                  description="Reviews"
                                />)</p>
                              </div>
                            </div>

                            <p className="cblack fs18 mb-2 d-flex justify-content-between">
                              <a className="cblack">{post.post.title}</a>
                              {/* <span className="span-tag pending">
                                Pending
                              </span> */}
                            </p>
                            <p className="cgray fs14">
                              <svg width="18" height="21" style={{marginRight:"10px", marginLeft:"10px"}} viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M3.06022 2.85984C4.6356 1.28446 6.77228 0.399414 9.00021 0.399414C11.2281 0.399414 13.3648 1.28446 14.9402 2.85984C16.5156 4.43523 17.4006 6.57191 17.4006 8.79984C17.4006 11.0278 16.5156 13.1645 14.9402 14.7398L9.00021 20.6798L3.06022 14.7398C2.28011 13.9598 1.6613 13.0338 1.2391 12.0146C0.81691 10.9954 0.599609 9.90302 0.599609 8.79984C0.599609 7.69667 0.81691 6.6043 1.2391 5.5851C1.6613 4.56591 2.28011 3.63987 3.06022 2.85984ZM9.00021 11.1998C9.63673 11.1998 10.2472 10.947 10.6973 10.4969C11.1474 10.0468 11.4002 9.43636 11.4002 8.79984C11.4002 8.16332 11.1474 7.55287 10.6973 7.10279C10.2472 6.6527 9.63673 6.39984 9.00021 6.39984C8.3637 6.39984 7.75325 6.6527 7.30316 7.10279C6.85307 7.55287 6.60021 8.16332 6.60021 8.79984C6.60021 9.43636 6.85307 10.0468 7.30316 10.4969C7.75325 10.947 8.3637 11.1998 9.00021 11.1998Z" fill="#AAAAAA"/>
                              </svg>
                              {post.post.location}
                            </p>
                          </div>
                        </div>
                      )})}
                        {moreLoading &&
                          <div className="loader"></div>
                        }
                        {noMorePosts && products && 
                          <div className="nomoreposts d-flex justify-content-center">
                              <span className="cgray fs12">
                                  <FormattedMessage 
                                      id="nomoreposts.text"
                                      defaultMessage="No more available posts"
                                      description="No more available posts"
                                  />
                              </span>
                          </div>
                          }
                      {/* <Pagination useFunction="getActiveBookings" posts={activeBookings} setPostsLoading={setActiveBookingLoading} numLease={numLease} /> */}
                    </div>
                  )
                : (
                  <div className="loader"></div>
                )}

              </Tab>
              <Tab eventKey="history" title={intl.formatMessage({
                id: 'history.text',
                defaultMessage: 'History',
                description: 'History',
              })}>
                
                {!historyBookingLoading && historyBookings ? 
                  historyBookings.data.length === 0 ? (
                    // when no active lease
                    <div className="row mt-5">
                      <div className="col-12">
                        <div className="text-center">
                          <img src="assets/images/no-lease-img.png" className="img-fluid pt-5 mb-4" />
                          <p className="fs30 fw700 cgray">
                            <FormattedMessage 
                              id="havenoactivelease.text"
                              defaultMessage="You have no active lease"
                              description="You have no active lease"
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="row">
                      {historyProducts?.data.map((post, index) => {
                      
                      let pricePerTerm = post.post.post_lease_terms.filter((ls) => ls.lease_term === post.lease_term); 
                      let dtToday = new Date();
                      let endDate = new Date(post.end_date);

                      return (
                        <div key={index} className="col-12 col-lg-4 cur-pointer" onClick={() => onClickPost(post, 'history')}>
                          <div className="prod-list-single-div">
                            <div className="prod-img-div position-relative">
                              
                            <div className="col3 position-absolute top-0 start-0">
                              <div className="m-1"> 
                                {dtToday > endDate ? 
                                  post.cancel_status === null && post.deleted_at ? (
                                    <span className="span-tag d-inline-block mt-3 pending">
                                      <FormattedMessage 
                                        id="pendingcancel.text"
                                        defaultMessage="Pending cancel"
                                        description="Pending cancel"
                                      />
                                    </span>
                                  ) : post.cancel_status === "1" && post.deleted_at ? (
                                    <span className="span-tag d-inline-block mt-3 cancel">
                                      <FormattedMessage 
                                        id="cancelled.text"
                                        defaultMessage="Cancelled"
                                        description="Cancelled"
                                      />
                                    </span>
                                  ) : (
                                    <span className="span-tag d-inline-block mt-3 complete">
                                      <FormattedMessage 
                                        id="complete.text"
                                        defaultMessage="Complete"
                                        description="Complete"
                                      />
                                    </span>
                                  )
                                :
                                  post.cancel_status === null && post.deleted_at ? (
                                    <span className="span-tag d-inline-block mt-3 pending">
                                      <FormattedMessage 
                                        id="pendingcancel.text"
                                        defaultMessage="Pending cancel"
                                        description="Pending cancel"
                                      />
                                    </span>
                                  ) : post.cancel_status === "1" && post.deleted_at ? (
                                    <span className="span-tag d-inline-block mt-3 cancel">
                                      <FormattedMessage 
                                        id="cancelled.text"
                                        defaultMessage="Cancelled"
                                        description="Cancelled"
                                      />
                                    </span>
                                  ) : (
                                    <span className="span-tag d-inline-block mt-3 active">
                                      <FormattedMessage 
                                        id="active.text"
                                        defaultMessage="Active"
                                        description="Active"
                                      />
                                    </span>
                                  )
                                }
                                </div>
                              </div>
                              <img src={post.post.post_images.length !== 0 ? post.post.post_images[0].image : "assets/images/prod1.png"} className="imgenclose" />

                              <span className="chat-icon-div in-img">
                                <svg width="22" height="20" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path fillRule="evenodd" clipRule="evenodd" d="M25.8 12C25.8 18.1856 20.0688 23.2 13 23.2C10.7281 23.2096 8.4873 22.6718 6.46721 21.632L0.200012 23.2L2.34081 18.2032C0.988812 16.4272 0.200012 14.2944 0.200012 12C0.200012 5.81445 5.93121 0.800049 13 0.800049C20.0688 0.800049 25.8 5.81445 25.8 12ZM8.20001 10.4H5.00001V13.6H8.20001V10.4ZM21 10.4H17.8V13.6H21V10.4ZM11.4 10.4H14.6V13.6H11.4V10.4Z" fill="white"/>
                                </svg>
                              </span>
                            </div>
                            <div className="prod-upper-text-div">
                              <p className="price fw800 cveryblack">SR {pricePerTerm[0].price} <span className="fw500">/{translateTerm(post.lease_term)}</span></p>
                              <div>
                                <p className="fw500 cblack mb-0 fs12">
                                  <svg width="18" height="17" style={{marginRight:"8px", marginTop:"-4px"}} viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.0491 0.927078C9.3491 0.00607812 10.6521 0.00607812 10.9511 0.927078L12.4701 5.60108C12.5355 5.80157 12.6625 5.97626 12.8332 6.10018C13.0038 6.22411 13.2092 6.29092 13.4201 6.29108H18.3351C19.3041 6.29108 19.7061 7.53108 18.9231 8.10108L14.9471 10.9891C14.7763 11.1132 14.6492 11.2883 14.584 11.4891C14.5188 11.6899 14.5188 11.9063 14.5841 12.1071L16.1021 16.7811C16.4021 17.7031 15.3471 18.4691 14.5641 17.8991L10.5881 15.0111C10.4172 14.8869 10.2114 14.8199 10.0001 14.8199C9.78883 14.8199 9.58298 14.8869 9.4121 15.0111L5.4361 17.8991C4.6531 18.4691 3.5981 17.7021 3.8981 16.7811L5.4161 12.1071C5.48137 11.9063 5.48141 11.6899 5.4162 11.4891C5.351 11.2883 5.22389 11.1132 5.0531 10.9891L1.0771 8.10108C0.293095 7.53108 0.697095 6.29108 1.6651 6.29108H6.5791C6.79014 6.29113 6.9958 6.22442 7.16662 6.10048C7.33744 5.97654 7.46467 5.80173 7.5301 5.60108L9.0491 0.927078Z" fill="#FCE412"/>
                                  </svg>
                                  {post.post.review_average}
                                </p>
                                <p className="cgray fs12 mb-0">({post.post.review_count} <FormattedMessage 
                                  id="reviews.text"
                                  defaultMessage="Reviews"
                                  description="Reviews"
                                />)</p>
                              </div>
                            </div>

                            <p className="cblack fs18 mb-2 d-flex justify-content-between">
                              <a className="cblack">{post.post.title}</a>
                              {/* <span className="span-tag complete">
                                Complete
                              </span> */}
                            </p>
                            <p className="cgray fs14">
                              <svg width="18" height="21" style={{marginRight:"10px", marginLeft:"10px"}} viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M3.06022 2.85984C4.6356 1.28446 6.77228 0.399414 9.00021 0.399414C11.2281 0.399414 13.3648 1.28446 14.9402 2.85984C16.5156 4.43523 17.4006 6.57191 17.4006 8.79984C17.4006 11.0278 16.5156 13.1645 14.9402 14.7398L9.00021 20.6798L3.06022 14.7398C2.28011 13.9598 1.6613 13.0338 1.2391 12.0146C0.81691 10.9954 0.599609 9.90302 0.599609 8.79984C0.599609 7.69667 0.81691 6.6043 1.2391 5.5851C1.6613 4.56591 2.28011 3.63987 3.06022 2.85984ZM9.00021 11.1998C9.63673 11.1998 10.2472 10.947 10.6973 10.4969C11.1474 10.0468 11.4002 9.43636 11.4002 8.79984C11.4002 8.16332 11.1474 7.55287 10.6973 7.10279C10.2472 6.6527 9.63673 6.39984 9.00021 6.39984C8.3637 6.39984 7.75325 6.6527 7.30316 7.10279C6.85307 7.55287 6.60021 8.16332 6.60021 8.79984C6.60021 9.43636 6.85307 10.0468 7.30316 10.4969C7.75325 10.947 8.3637 11.1998 9.00021 11.1998Z" fill="#AAAAAA"/>
                              </svg>
                              {post.post.location}
                            </p>
                            
                          </div>
                        </div>
                      )})}
                      {/* <Pagination useFunction="getHistoryBookings" posts={historyBookings} setPostsLoading={setHistoryBookingLoading} numLease={numLease} /> */}
                    </div>
                  )
                : (
                  <div className="loader"></div>
                )}

              </Tab>
            </Tabs>


          </div>
        </div>
      </div>
    </div>
  )}
  <div ref={elementRef}></div>
  </>
  )
}

export default MyLeases;
