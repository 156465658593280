import React, { useState, useEffect, useContext, useRef } from "react";
import { Modal, } from 'react-bootstrap';
import Cookies from 'universal-cookie';
import {FormattedMessage, useIntl} from 'react-intl';
import { RouteContext } from "../../contexts/route.context";


const LessorPayment = () => {
  const intl = useIntl();
  const cookies = new Cookies();
  let featured_post_id = cookies.get('featured_post_id');
  const { getAllSettings, allSettings, baseURLPayment, getMe, me, } = useContext(RouteContext);
  const [paymentMethod, setPaymentMethod] = useState();
  const [showModalPayment, setShowModalPayment] = useState(false);
  const [vatVal, setVatVal] = useState(0);
  const [totalVal, setTotalVal] = useState(0);


  useEffect(() => {
    getAllSettings();
    getMe();
  }, []);


  useEffect(() => {
    if(allSettings) {
      let vat = (parseFloat(allSettings.tax) / 100) * parseFloat(allSettings.feature_post_cost);
      let total = parseFloat(vat) + parseFloat(allSettings.feature_post_cost);

      setVatVal(vat);
      setTotalVal(total);
    }
  }, [allSettings]);

  const handleCloseModalPayment = () => setShowModalPayment(false);


  let iframePayment = {
    payment:
      `<iframe width="100%" height="500" scrolling="no" frameborder="no" allow="autoplay" src=${baseURLPayment}/admin/charge-card?token=4ZNJXwUW6m10CrNxONI&user_id=${me ? me.id : ""}&payment_for=featured_post&brand=VISA&app_used=web&post_id=${featured_post_id}></iframe>`
  };
  let iframePaymentApplePay = {
    payment:
      `<iframe width="100%" height="500" scrolling="no" frameborder="no" allow="autoplay" src=${baseURLPayment}/admin/charge-card?token=4ZNJXwUW6m10CrNxONI&user_id=${me ? me.id : ""}&payment_for=featured_post&brand=APPLEPAY&app_used=web&post_id=${featured_post_id}></iframe>`      
  };
  let iframePaymentMada = {
    payment:
    `<iframe width="100%" height="500" scrolling="no" frameborder="no" allow="autoplay" src=${baseURLPayment}/admin/charge-card?token=4ZNJXwUW6m10CrNxONI&user_id=${me ? me.id : ""}&payment_for=featured_post&brand=MADA&app_used=web&post_id=${featured_post_id}></iframe>` 
  };
  let iframePaymentMaster = {
    payment:
    `<iframe width="100%" height="500" scrolling="no" frameborder="no" allow="autoplay" src=${baseURLPayment}/admin/charge-card?token=4ZNJXwUW6m10CrNxONI&user_id=${me ? me.id : ""}&payment_for=featured_post&brand=MASTER&app_used=web&post_id=${featured_post_id}></iframe>` 
  };

  const onClickPayment = () => {

    // if(paymentMethod === "applePay") {
    //   window.location.href = `${baseURLPayment}/admin/charge-card?token=4ZNJXwUW6m10CrNxONI&user_id=${me ? me.id : ""}&payment_for=featured_post&brand=APPLEPAY&app_used=web&post_id=${featured_post_id}`;
    // }
    // else {
    //   setShowModalPayment(true);
    // }

    setShowModalPayment(true);
    // window.location.href = `${baseURLPayment}/admin/charge-card?token=4ZNJXwUW6m10CrNxONI&user_id=${me ? me.id : ""}&payment_for=featured_post&brand=VISA&app_used=web&post_id=${featured_post_id}`;
  }

  function Iframe(props) {
    return (
      <div
        dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
      />
    );
  }

  const onClickBack = () => {
    window.location.href = "/";
  }

  return (
    <div>
      <div className="container-fluid leases-payment-wrapper">
        <div className="container main-wrap">
          <div className="row">
            <div className="col-12">
              <h2 className="fw700 cblue mb-5">
                <FormattedMessage 
                  id="leases.text"
                  defaultMessage="Leases"
                  description="Leases"
                />
              </h2>
            </div>

            <div className="col-12 col-lg-7">
              <div className="pm-outer-div mb-4">
                <div className="pm-inner-div">
                  <div className="d-flex justify-content-between mb-3">
                    <p className="fw700 cblack fs18 mb-0">
                      <FormattedMessage 
                        id="featuredpost.text"
                        defaultMessage="Featured Post"
                        description="Featured Post"
                      />
                    </p>
                    <p className="fw700 cblack fs18 mb-0">
                      <FormattedMessage 
                        id="price.text"
                        defaultMessage="Price"
                        description="Price"
                      />
                    </p>
                  </div>

                  {/* <div className="d-flex justify-content-between mb-3">
                    <p className="cblack fs14 mb-0">Bi-Annually</p>
                    <p className="fw700 cblack fs14 mb-0">SAR 10,000</p>
                  </div> */}

                  <div className="d-flex justify-content-between mb-3">
                    <p className="fw700 cblack fs16 mb-0">
                      <FormattedMessage 
                        id="subtotal.text"
                        defaultMessage="Sub Total"
                        description="Sub Total"
                      />
                    </p>
                    <p className="fw700 cblack fs16 mb-0">SAR {allSettings && allSettings.feature_post_cost}</p>
                  </div>

                  <div className="d-flex justify-content-between mb-3">
                    <p className="fw700 cblack fs16 mb-0">
                      <FormattedMessage 
                        id="discount.text"
                        defaultMessage="Discount"
                        description="Discount"
                      />
                    </p>
                    <p className="fw700 cblack fs16 mb-0">---</p>
                  </div>

                  <div className="d-flex justify-content-between mb-3">
                    <p className="fw700 cblack fs16 mb-0">
                    <FormattedMessage 
                      id="vat.text"
                      defaultMessage="VAT"
                      description="VAT"
                    /> ({allSettings && allSettings.tax}%)</p>
                    <p className="fw700 cblack fs16 mb-0">SR {vatVal}</p>
                  </div>

                  <div className="divider-dashed mb-4"></div>

                  <div className="d-flex justify-content-between">
                    <p className="fw700 cblack fs20 mb-0">
                      <FormattedMessage 
                        id="total.text"
                        defaultMessage="Total"
                        description="Total"
                      />
                    </p>
                    <p className="fw700 cblack fs20 mb-0">SR {totalVal}</p>
                  </div>
                </div>

                <div className="discount-div mt-4">
                  <input type="text" className="form-control" placeholder={intl.formatMessage({
                    id: 'enterdiscode.text',
                    defaultMessage: "Enter discount code",
                    description: "Enter discount code",
                  })} />
                  <button className="btn">
                    <FormattedMessage 
                      id="apply.text"
                      defaultMessage="Apply"
                      description="Apply"
                    />
                  </button>
                </div>

              </div>
            </div>
            <div className="col-12 col-lg-5">
              <div className="pm-right-div">
                <p className="fw700 fs18 cblack">
                  <FormattedMessage 
                    id="paymentmethod.text"
                    defaultMessage="Payment Method"
                    description="Payment Method"
                  />
                </p>

                <div className="form-check px-5 mb-3">
                  <input className="form-check-input" type="radio" name="payment_method" id="apple-pay" value="applePay"
                    checked={paymentMethod === "applePay"}
                    onChange={(e) => setPaymentMethod(e.target.value)}
                  />
                  <label className="form-check-label cblack" htmlFor="apple-pay">
                    <FormattedMessage 
                      id="applepay.text"
                      defaultMessage="Apple Pay"
                      description="Apple Pay"
                    />
                  </label>
                </div>
                <div className="form-check px-5 mb-3">
                  <input className="form-check-input" type="radio" name="payment_method" id="credit-card" value="masterCard"
                    checked={paymentMethod === "masterCard"}
                    onChange={(e) => setPaymentMethod(e.target.value)}
                  />
                  <label className="form-check-label cblack" htmlFor="credit-card">
                    <FormattedMessage 
                      id="creditcard.text"
                      defaultMessage="Master card"
                      description="Master card"
                    />
                  </label>
                </div>
                <div className="form-check px-5 mb-3">
                  <input className="form-check-input" type="radio" name="payment_method" id="master-card" value="visaCard"
                    checked={paymentMethod === "visaCard"}
                    onChange={(e) => setPaymentMethod(e.target.value)}
                  />
                  <label className="form-check-label cblack" htmlFor="master-card">
                    <FormattedMessage 
                      id="visacard.text"
                      defaultMessage="Visa Card"
                      description="Visa Card"
                    />
                  </label>
                </div>
                <div className="form-check px-5">
                  <input className="form-check-input" type="radio" name="payment_method" id="mada-card" value="madaCard"
                    checked={paymentMethod === "madaCard"}
                    onChange={(e) => setPaymentMethod(e.target.value)}
                  />
                  <label className="form-check-label cblack" htmlFor="mada-card">
                    <FormattedMessage 
                      id="madacard.text"
                      defaultMessage="Mada"
                      description="Mada"
                    />
                  </label>
                </div>
              </div>


              <button className="btn btn-payment fw700" disabled={paymentMethod ? false : true} onClick={onClickPayment}>
                <FormattedMessage 
                  id="proceedpayment.text"
                  defaultMessage="Proceed to Payment"
                  description="Proceed to Payment"
                />
              </button>
              <button className="btn btn-back w-100 mt-3" onClick={onClickBack}>
                <FormattedMessage 
                  id="backtohome.text"
                  defaultMessage="Back to Home"
                  description="Back to Home"
                />
              </button>


              {/* Modal */}
              <Modal className="modalChat" show={showModalPayment} onHide={handleCloseModalPayment}>
                <Modal.Body className="p-0">
                  {paymentMethod === "applePay" ? (
                    <Iframe iframe={iframePaymentApplePay["payment"]} allow="autoplay" />
                  ) :
                  paymentMethod === "madaCard" ? (
                    <Iframe iframe={iframePaymentMada["payment"]} allow="autoplay" />
                  ) :
                  paymentMethod === "masterCard" ? (
                    <Iframe iframe={iframePaymentMaster["payment"]} allow="autoplay" />
                  ) : (
                    <Iframe iframe={iframePayment["payment"]} allow="autoplay" />
                  )}
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LessorPayment;
