import React, { useState, useEffect, useContext, useRef } from "react";
import { RouteContext } from "../../contexts/route.context";
import { scrollToTop } from "../../util/helper";
import { useIntl } from 'react-intl';


const Pagination = (props) => {
  const intl = useIntl();
  const { getUserPosts, getInvoiceActive, getInvoiceHistory, getPostReviews, getActiveBookings, getHistoryBookings } = useContext(RouteContext);
  let posts = props.posts;
  let setUserPostsLoading = props.setUserPostsLoading;
  let numLease = props.numLease;
  let category = props.category;
  let useFunction = props.useFunction;
  

  // pagination
  function paginationNumberComponent() {
    let num = [];
    for (let index = 1; index <= posts.paginatorInfo.lastPage; index++) {
       num.push(
        <li key={index} className={`${posts.paginatorInfo.currentPage === index ? 'active' : ''}`}>
          <a href="#" onClick={(e) => clickPage(e,index)}>{index}</a>
        </li>
      )
    }
    return num;
  }

  const clickPage = (e,num) => {
    e.preventDefault();
    setUserPostsLoading(true);

    switch(useFunction) {
      case "getUserPosts":
        if(category) {
          let inp = `{category_id: "${category.id}"}`;
          getUserPosts(numLease, num, inp);
        }
        else {
          getUserPosts(numLease, num);
        }
        scrollToTop(300);
        break;
      case "getInvoiceActive":
        let lessorRole = `lessor`;
        getInvoiceActive(numLease, num, lessorRole);
        scrollToTop(300);
        break;
      case "getInvoiceHistory":
        let lessorRole2 = `lessor`;
        getInvoiceHistory(numLease, num, lessorRole2);
        scrollToTop(300);
        break;
      case "getPostReviews":
        let lessor_id = props.lessor_id;
        getPostReviews(numLease, num, lessor_id);
        scrollToTop(300);
        break;
      case "getActiveBookings":
        let inp = `{user_role: lessor}`;
        getActiveBookings(numLease, num, inp);
        scrollToTop(300);
        break;
      case "getHistoryBookings":
        let inp2= `{user_role: lessor}`;
        getHistoryBookings(numLease, num, inp2);
        scrollToTop(300);
        break;
      default:
        break;
    }
  }

  const onClickPrev = (e) => {
    e.preventDefault();

    if(posts.paginatorInfo.currentPage > 1) {
      setUserPostsLoading(true);
      let prevPage = parseInt(posts.paginatorInfo.currentPage - 1);

      switch(useFunction) {
        case "getUserPosts":
          if(category) {
            let inp = `{category_id: "${category.id}"}`;
            getUserPosts(numLease, prevPage, inp);
          }
          else {
            getUserPosts(numLease, prevPage);
          }
          scrollToTop(300);
          break;
        case "getInvoiceActive":
          let lessorRole = `lessor`;
          getInvoiceActive(numLease, prevPage, lessorRole);
          scrollToTop(300);
          break;
        case "getInvoiceHistory":
          let lessorRole2 = `lessor`;
          getInvoiceHistory(numLease, prevPage, lessorRole2);
          scrollToTop(300);
          break;
        case "getPostReviews":
          let lessor_id = props.lessor_id;
          getPostReviews(numLease, prevPage, lessor_id);
          scrollToTop(300);
          break;
        case "getActiveBookings":
          let inp = `{user_role: lessor}`;
          getActiveBookings(numLease, prevPage, inp);
          scrollToTop(300);
          break;
        case "getHistoryBookings":
          let inp2= `{user_role: lessor}`;
          getHistoryBookings(numLease, prevPage, inp2);
          scrollToTop(300);
          break;
        default:
          break;
      }
    }
  }

  const onClickNext = (e) => {
    e.preventDefault();
    
    if(posts.paginatorInfo.currentPage < posts.paginatorInfo.lastPage) {
      setUserPostsLoading(true);
      let nextPage = parseInt(posts.paginatorInfo.currentPage + 1);

      switch(useFunction) {
        case "getUserPosts":
          if(category) {
            let inp = `{category_id: "${category.id}"}`;
            getUserPosts(numLease, nextPage, inp);
          }
          else {
            getUserPosts(numLease, nextPage);
          }
          scrollToTop(300);
          break;
        case "getInvoiceActive":
          let lessorRole = `lessor`;
          getInvoiceActive(numLease, nextPage, lessorRole);
          scrollToTop(300);
          break;
        case "getInvoiceHistory":
          let lessorRole2 = `lessor`;
          getInvoiceHistory(numLease, nextPage, lessorRole2);
          scrollToTop(300);
          break;
        case "getPostReviews":
          let lessor_id = props.lessor_id;
          getPostReviews(numLease, nextPage, lessor_id);
          scrollToTop(300);
          break;
        case "getActiveBookings":
          let inp = `{user_role: lessor}`;
          getActiveBookings(numLease, nextPage, inp);
          scrollToTop(300);
          break;
        case "getHistoryBookings":
          let inp2= `{user_role: lessor}`;
          getHistoryBookings(numLease, nextPage, inp2);
          scrollToTop(300);
          break;
        default:
          break;
      }
    }
  }

  


  return (
    <div className="col-12">
      <ul className="ul-pagination mt-5">
        {posts.paginatorInfo.lastPage !== 1 && (
          <li className="first">
            <a href="#" onClick={(e) => onClickPrev(e)}>
              
              {intl.locale === "en" ? (
                <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M5.43591 0.763508C5.60464 0.932283 5.69942 1.16116 5.69942 1.39981C5.69942 1.63846 5.60464 1.86733 5.43591 2.03611L2.47221 4.99981L5.43591 7.96351C5.59985 8.13325 5.69057 8.36059 5.68852 8.59657C5.68647 8.83255 5.59182 9.05828 5.42495 9.22514C5.25808 9.39201 5.03235 9.48666 4.79637 9.48871C4.5604 9.49076 4.33305 9.40005 4.16331 9.23611L0.563313 5.63611C0.394589 5.46733 0.299805 5.23846 0.299805 4.99981C0.299805 4.76116 0.394589 4.53228 0.563313 4.36351L4.16331 0.763508C4.33209 0.594784 4.56096 0.5 4.79961 0.5C5.03826 0.5 5.26714 0.594784 5.43591 0.763508Z" fill="#FF5833"/>
                </svg>
              ) : (
                <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M0.563313 9.23598C0.394589 9.06721 0.299805 8.83833 0.299805 8.59968C0.299805 8.36104 0.394589 8.13216 0.563313 7.96338L3.52701 4.99968L0.563313 2.03598C0.39937 1.86624 0.308655 1.6389 0.310706 1.40292C0.312756 1.16694 0.407408 0.941214 0.574276 0.774346C0.741143 0.607479 0.966875 0.512827 1.20285 0.510776C1.43883 0.508726 1.66617 0.599441 1.83591 0.763383L5.43591 4.36338C5.60464 4.53216 5.69942 4.76104 5.69942 4.99968C5.69942 5.23833 5.60464 5.46721 5.43591 5.63598L1.83591 9.23598C1.66714 9.40471 1.43826 9.49949 1.19961 9.49949C0.960965 9.49949 0.732088 9.40471 0.563313 9.23598Z" fill="#FF5833"/>
                </svg>
              )}

            </a>
          </li>
        )}

        {paginationNumberComponent()}

        {posts.paginatorInfo.lastPage !== 1 && (
          <li className="last">
            <a href="#" onClick={(e) => onClickNext(e)}>
              
              {intl.locale === "en" ? (
                <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M0.563313 9.23598C0.394589 9.06721 0.299805 8.83833 0.299805 8.59968C0.299805 8.36104 0.394589 8.13216 0.563313 7.96338L3.52701 4.99968L0.563313 2.03598C0.39937 1.86624 0.308655 1.6389 0.310706 1.40292C0.312756 1.16694 0.407408 0.941214 0.574276 0.774346C0.741143 0.607479 0.966875 0.512827 1.20285 0.510776C1.43883 0.508726 1.66617 0.599441 1.83591 0.763383L5.43591 4.36338C5.60464 4.53216 5.69942 4.76104 5.69942 4.99968C5.69942 5.23833 5.60464 5.46721 5.43591 5.63598L1.83591 9.23598C1.66714 9.40471 1.43826 9.49949 1.19961 9.49949C0.960965 9.49949 0.732088 9.40471 0.563313 9.23598Z" fill="#FF5833"/>
                </svg>
              ) : (
                <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M5.43591 0.763508C5.60464 0.932283 5.69942 1.16116 5.69942 1.39981C5.69942 1.63846 5.60464 1.86733 5.43591 2.03611L2.47221 4.99981L5.43591 7.96351C5.59985 8.13325 5.69057 8.36059 5.68852 8.59657C5.68647 8.83255 5.59182 9.05828 5.42495 9.22514C5.25808 9.39201 5.03235 9.48666 4.79637 9.48871C4.5604 9.49076 4.33305 9.40005 4.16331 9.23611L0.563313 5.63611C0.394589 5.46733 0.299805 5.23846 0.299805 4.99981C0.299805 4.76116 0.394589 4.53228 0.563313 4.36351L4.16331 0.763508C4.33209 0.594784 4.56096 0.5 4.79961 0.5C5.03826 0.5 5.26714 0.594784 5.43591 0.763508Z" fill="#FF5833"/>
                </svg>
              )}

            </a>
          </li>
        )}
      </ul>
    </div>
  )
}

export default Pagination;