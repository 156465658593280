import React, { useState, useEffect, useContext, useRef } from "react";
import {FormattedMessage} from 'react-intl';


const LessorPaymentSuccess = () => {
  
  const onClickHome = () => {
    window.location.href = "/";
  }

  const onClickNoti = () => {
    window.location.href = "/notification";
  }

  return (
    <div>
      <div className="container-fluid leases-payment-wrapper">
        <div className="container main-wrap">
          <div className="row">
            <div className="col-12">

              <div className="d-flex justify-content-center py-5">
                <svg width="126" height="108" viewBox="0 0 176 158" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M68.1989 157.854C105.695 157.854 136.091 127.458 136.091 89.9616C136.091 52.4658 105.695 22.0693 68.1989 22.0693C30.7031 22.0693 0.306641 52.4658 0.306641 89.9616C0.306641 127.458 30.7031 157.854 68.1989 157.854Z" fill="#DFE4FE"/>
                  <path d="M68.9656 142.437L68.1044 140.97C54.9506 118.558 19.996 70.9977 19.6429 70.52L19.1387 69.835L31.0475 58.0657L68.7444 84.3884C92.4794 53.5888 114.623 32.4342 129.066 20.0807C144.867 6.56726 155.152 0.346101 155.256 0.286485L155.489 0.146484H175.69L173.761 1.86495C124.134 46.0673 70.3441 140.015 69.8083 140.959L68.9656 142.437Z" fill="#1036CE"/>
                </svg>
              </div>

              <h3 className="fw700 fs30 text-center cblue mb-4">
                <FormattedMessage 
                  id="success.text"
                  defaultMessage="Success!"
                  description="Success!"
                />
              </h3>
              <p className="fs16 px-2 text-center cblack">
                <FormattedMessage 
                  id="sucsubpay.text"
                  defaultMessage="You have successfully submitted your payment."
                  description="You have successfully submitted your payment."
                />
              </p>
              

              <button className="btn btn-payment w-100 mt-3" onClick={onClickHome}>
                <FormattedMessage 
                  id="gotohome.text"
                  defaultMessage="Go to Home"
                  description="Go to Home"
                />
              </button>
              <button className="btn btn-back w-100 mt-3 mb-4" onClick={onClickNoti}>
                <FormattedMessage 
                  id="checknoti.text"
                  defaultMessage="Check Notifications"
                  description="Check Notifications"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LessorPaymentSuccess;
