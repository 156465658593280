import React, { Component, useState } from 'react';
import { Map, GoogleApiWrapper, InfoWindow, Marker } from 'google-maps-react';


const MapContainer = (props) => {

  const mapStyles = {
    width: '100%',
    height: '100%',
  };
  
  
  const onDragMarker = (coord) => {
    let { latLng } = coord;
    let lat = latLng.lat();
    let lng = latLng.lng();

    props.setAddressLatLng({
      latVal: lat,
      lngVal: lng,
    });
  }



  return (
      <Map
        google={props.google}
        zoom={8}
        style={mapStyles}
        initialCenter={{ lat: 24.6945876, lng: 46.4332919}}
      >
        <Marker 
          google={props.google}
          draggable={true}
          onDragend={(t, map, coord) => onDragMarker(coord)}
        />
      </Map>
  );

}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDJCS46EQMFDDPG0MFD34yO651MzTCtpZ4'
})(MapContainer);